import React from "react";
import { useLocation } from "react-router-dom";
import { db } from "./firebase";
import { useState, useEffect } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { langs } from "./Common";

import {
  onSnapshot,
  doc,
  updateDoc,
} from "firebase/firestore";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";
import ImageSelect from "./ImageSelect";

function EditCondition() {
 let location = useLocation();
 const gId = location.state ? location.state.gId : "";
 const [condition, setCondition] = useState();
 const [lang, setLang] = useState(langs[0].id);
  

  function conditionData() {
    const docRef = doc(db, "conditions", gId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      setCondition(doc.data());
    });
    return unsubscribe;
  }
  useEffect(() => conditionData(), []);


  function handleChangeField(event, eId) {
    var newValue = event.target.value;
    var targetId = event.target.id;

    if (targetId === "name") {
      var tempNameArray = [...condition.names];
      var tempNameIndex = tempNameArray.findIndex((t) => {
        return t.id === eId;
      });
      if (tempNameIndex !== -1) {
        tempNameArray[tempNameIndex].name = newValue;
        tempNameArray[tempNameIndex].id = eId;
      } else {
        tempNameArray.push({ id: eId, name: newValue });
      }
      setCondition({ ...condition, names: tempNameArray });
    }

    if (targetId === "desc") {
      var tempDescArray = condition.hasOwnProperty("descs")
        ? [...condition.descs]
        : [];
      var tempDescIndex = tempDescArray.findIndex((t) => {
        return t.id === eId;
      });
      if (tempDescIndex !== -1) {
        tempDescArray[tempDescIndex].desc = newValue;
        tempDescArray[tempDescIndex].id = eId;
      } else {
        tempDescArray.push({ id: eId, desc: newValue });
      }
      setCondition({ ...condition, descs: tempDescArray });
    }
  }

  function handleUpdateCondition(event) {
    event.preventDefault();
    const docRef = doc(db, "conditions", gId);
    updateDoc(docRef, {
      ...condition
    })
      .then((docRef) => {
        console.log("Document field updated");
      })
      .catch((error) => {
        console.log(error);
      });
  }



  return (
    <div className="EditCondition">
      {condition && (
        <>
          <Container>
            <Form>
              <Form.Group as={Row} className="pb-4 pt-4">
                <Form.Label column sm={2}>
                  Fizetési feltétel neve
                </Form.Label>
                <Col sm={4}>
                  {langs.map((l) => (
                    <>
                      {l.name}
                      <Form.Control
                        key={l.id}
                        id="name"
                        value={
                          condition.hasOwnProperty("names") &&
                          condition.names.find((b) => b.id === l.id) &&
                          condition.names
                            .find((b) => b.id === l.id)
                            .hasOwnProperty("name")
                            ? condition.names.find((b) => b.id === l.id).name
                            : ""
                        }
                        onChange={(event) => handleChangeField(event, l.id)}
                      />
                    </>
                  ))}
                </Col>
                <Form.Group as={Row} className="mb-4" controlId="desc">
                  <Form.Label column sm={2}>
                    Leírás
                  </Form.Label>
                  <Col sm={4}>
                    {langs.map((l) => (
                      <>
                        {l.name}
                        <Form.Control
                          key={l.id}
                          as="textarea"
                          rows={3}
                          value={
                            condition.hasOwnProperty("descs") &&
                            condition.descs.find((b) => b.id === l.id) &&
                            condition.descs
                              .find((b) => b.id === l.id)
                              .hasOwnProperty("desc")
                              ? condition.descs.find((b) => b.id === l.id).desc
                              : ""
                          }
                          onChange={(event) => handleChangeField(event, l.id)}
                        />
                      </>
                    ))}
                  </Col>
                </Form.Group>
                <Row>
                  <Col sm={2}>
                    <Button
                      id="saveButton"
                      onClick={handleUpdateCondition}
                      variant="primary"
                      type="submit"
                    >
                      Mentés
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
           
            </Form>
          </Container>
        </>
      )}
    </div>
  );
}

export default EditCondition;