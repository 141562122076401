import React from "react";
import { useLocation } from "react-router-dom";
import { db } from "./firebase";
import { useState, useEffect } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { langs } from "./Common";

import {
  collection,
  onSnapshot,
  query,
  doc,
  updateDoc,
  getDoc
} from "firebase/firestore";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  Image
} from "react-bootstrap";
import ImageSelect from "./ImageSelect";

function EditActGroup(props) {
  let location = useLocation();
  // dev mode-ban berak egy alap variációcsoportot, hogy működjön
  const gId = location.state ? location.state.gId : "PeGoNhnpgv8966n2Lu8y";
  const [actgroup, setActgroup] = useState();
  const [acts, setActs] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [lang, setLang] = useState(langs[0].id);
  const [images, setImages] = useState([]);
  const [showAddImage, setShowAddImage] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  

  function actgroupData() {
    const docRef = doc(db, "actgroups", gId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      console.log("docdata", doc.data());
      setActgroup(doc.data());
      doc.data() &&
        doc.data().hasOwnProperty("images") &&
        setSelectedImages(doc.data().images);
    });
    return unsubscribe;
  }
  useEffect(() => actgroupData(), []);

  function actsData() {
    const q = query(collection(db, "activities"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      var sortedArray = [...newData].sort(function (a, b) {
        const nameA = a.names.find((x) => x.id === lang).name.toUpperCase();
        const nameB = b.names.find((x) => x.id === lang).name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }); // names must be equal
      setActs([...sortedArray]);
    });
    return unsubscribe;
  }
  useEffect(() => actsData(), []);

  function currencyData() {
    const docRef = doc(db, "other", "currencies");
    getDoc(docRef).then((doc) => {
      setCurrencies(Object.values(doc.data()));
    });
  }
  useEffect(() => currencyData(), [acts]);

  function imagesData() {
    const q = query(collection(db, "images"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      setImages(newData);
    });
    return unsubscribe;
  }

  useEffect(() => imagesData(), []);


  function handleChangeField(event, eId) {
    var newValue = event.target.value;
    var targetId = event.target.id;

    if (targetId === "name") {
      var tempNameArray = [...actgroup.names];
      var tempNameIndex = tempNameArray.findIndex((t) => {
        return t.id === eId;
      });
      if (tempNameIndex !== -1) {
        tempNameArray[tempNameIndex].name = newValue;
        tempNameArray[tempNameIndex].id = eId;
      } else {
        tempNameArray.push({ id: eId, name: newValue });
      }
      setActgroup({ ...actgroup, names: tempNameArray });
    }

    if (targetId === "desc") {
      var tempDescArray = actgroup.hasOwnProperty("descs")
        ? [...actgroup.descs]
        : [];
      var tempDescIndex = tempDescArray.findIndex((t) => {
        return t.id === eId;
      });
      if (tempDescIndex !== -1) {
        tempDescArray[tempDescIndex].desc = newValue;
        tempDescArray[tempDescIndex].id = eId;
      } else {
        tempDescArray.push({ id: eId, desc: newValue });
      }
      setActgroup({ ...actgroup, descs: tempDescArray });
    }
  }

  function handleUpdateVargroup(event) {
    event.preventDefault();
    const docRef = doc(db, "actgroups", gId);
    updateDoc(docRef, {
      ...actgroup
    })
      .then((docRef) => {
        console.log("Document field updated");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleChangeActList(event) {
    var tempActId = event.target.value;
    var tempArray = [];
    if (actgroup) {
      if (!actgroup.hasOwnProperty("activities")) actgroup.activities = [];
      tempArray = actgroup.activities;
    }

    if (tempArray.indexOf(tempActId) === -1 && event.target.checked) {
      tempArray.push(tempActId);
    } else {
      tempArray = tempArray.filter((e) => e !== tempActId);
    }
    setActgroup({ ...actgroup, activities: tempArray });
  }


  function handleShowAddImage(event) {
    event.preventDefault();
    setShowAddImage(true);
  }

  return (
    <div className="EditActGroup">
      {actgroup && (
        <>
          <Container>
            <Form>
              <Form.Group as={Row} className="pb-4 pt-4">
                <Form.Label column sm={2}>
                  Variáció csoport neve
                </Form.Label>
                <Col sm={4}>
                  {langs.map((l) => (
                    <>
                      {l.name}
                      <Form.Control
                        key={l.id}
                        id="name"
                        value={
                          actgroup.hasOwnProperty("names") &&
                          actgroup.names.find((b) => b.id === l.id) &&
                          actgroup.names
                            .find((b) => b.id === l.id)
                            .hasOwnProperty("name")
                            ? actgroup.names.find((b) => b.id === l.id).name
                            : ""
                        }
                        onChange={(event) => handleChangeField(event, l.id)}
                      />
                    </>
                  ))}
                </Col>
                <Form.Group as={Row} className="mb-4" controlId="desc">
                  <Form.Label column sm={2}>
                    Leírás
                  </Form.Label>
                  <Col sm={4}>
                    {langs.map((l) => (
                      <>
                        {l.name}
                        <Form.Control
                          key={l.id}
                          as="textarea"
                          rows={3}
                          value={
                            actgroup.hasOwnProperty("descs") &&
                            actgroup.descs.find((b) => b.id === l.id) &&
                            actgroup.descs
                              .find((b) => b.id === l.id)
                              .hasOwnProperty("desc")
                              ? actgroup.descs.find((b) => b.id === l.id).desc
                              : ""
                          }
                          onChange={(event) => handleChangeField(event, l.id)}
                        />
                      </>
                    ))}
                  </Col>
                </Form.Group>
                <Row>
                  <Col sm={2}>
                    <Button
                      id="saveButton"
                      onClick={handleUpdateVargroup}
                      variant="primary"
                      type="submit"
                    >
                      Mentés
                    </Button>
                  </Col>
                  <Col sm={2}>
                    <Button
                      id="addSelectedImage"
                      onClick={handleShowAddImage}
                      variant="primary"
                      type="submit"
                    >
                      Képek
                    </Button>
                    <ImageSelect
                      images={images}
                      setImages={setImages}
                      target={actgroup}
                      setTarget={setActgroup}
                      selectedImages={selectedImages}
                      setSelectedImages={setSelectedImages}
                      showAddImage={showAddImage}
                      setShowAddImage={setShowAddImage}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group as={Row} className="pb-4 pt-4">
                <Col className="text-start">
                  {acts.map((a, i) => (
                    <>
                      <Form.Check
                        key={i}
                        type="checkbox"
                        id="listActs"
                        label={
                          a.hasOwnProperty("names") &&
                          a.names.find((l) => l.id === lang) &&
                          a.names
                            .find((l) => l.id === lang)
                            .hasOwnProperty("name")
                            ? a.names.find((l) => l.id === lang).name
                            : ""
                        }
                        onChange={handleChangeActList}
                        checked={
                          actgroup.hasOwnProperty("activities") &&
                          actgroup.activities.indexOf(a.id) !== -1
                        }
                        value={a.id}
                      />
                    </>
                  ))}
                </Col>
              </Form.Group>
            </Form>
          </Container>
        </>
      )}
    </div>
  );
}

export default EditActGroup;
