import { db } from "./firebase";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, onSnapshot, query, addDoc, doc, deleteDoc } from "firebase/firestore";
import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { langs } from "./Common";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase";

function Users() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [users, setUsers] = useState([]);
  const [lang, setLang] = useState(langs[0].id);

  function usersData() {
    const q = query(collection(db, "users"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      
      setUsers([...newData]);
    });
    return unsubscribe;
  }
  useEffect(() => usersData(), []);

const handleAddUser = async (event) => {
    event.preventDefault();
    await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
          const dbRef = collection(db, "users");
            addDoc(dbRef, {
                email: user.email,
                uId: user.uid
            })
            .then((docRef) => {
                console.log(docRef.id); //p4eZcO5QV43IYnigxALJ
            })
            .catch((error) => {
                console.log(error);
            });
          setEmail("");
          setPassword("");
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        // ..
      });
  };  
    
 /*  function handleAddUser(event) {
    event.preventDefault();
    const dbRef = collection(db, "users");
    addDoc(dbRef, {
      email: email,
    })
      .then((docRef) => {
        console.log(docRef.id); //p4eZcO5QV43IYnigxALJ
      })
      .catch((error) => {
        console.log(error);
      });
    setName("");
  }
 */
  function handleDeleteUser(event, userId) {
    event.preventDefault();
    if (window.confirm("Biztosan törlöd a felhasználót?")) {
      const docRef = doc(db, "users", userId);
      deleteDoc(docRef)
        .then((docRef) => {
          console.log("Document deleted");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <div className="Users">
      <Container className="pt-4">
        <Form>
          <Row>
            <Col className="text-start align-middle">
              <Form.Label>Új felhasználó létrehozása</Form.Label>
            </Col>
                  </Row>
                  <Row>
            <Col className="text-start align-middle">
              <Form.Label>E-mail cím</Form.Label>
            </Col>
          </Row>
          <Row className="pt-2 pb-2">
            <Col className="text-start align-middle" sm={6}>
              <Form.Control id="email" onChange={(e) => setEmail(e.target.value)} />
                      </Col>
                  </Row>
                  <Row>
            <Col className="text-start align-middle">
              <Form.Label>Jelszó</Form.Label>
            </Col>
          </Row>
        <Row className="pt-2 pb-2">
            <Col className="text-start align-middle">
              <Form.Control id="password" type="password" onChange={(e) => setPassword(e.target.value)} />
            </Col>
            <Col>
              <Button onClick={handleAddUser} variant="primary" type="submit">
                Hozzáad
              </Button>
            </Col>
          </Row>
        </Form>
        {users &&
          users.map((u, i) => (
            <Row key={i} className="pt-2 pb-2 border-secondary border-bottom">
              <Col className="d-flex justify-content-start align-items-center">
                <Link to="/user" state={{ uId: u.id }} style={{ textDecoration: "none", color: "#000" }}>
                          {u.hasOwnProperty("email") && u.email}
                </Link>
              </Col>
              <Col>
                <Button onClick={(event) => handleDeleteUser(event, u.id)} variant="primary" type="submit">
                  Törlés
                </Button>
              </Col>
            </Row>
          ))}
      </Container>
    </div>
  );
}

export default Users;
