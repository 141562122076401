import { db } from "./firebase";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, onSnapshot, query, addDoc, doc, deleteDoc } from "firebase/firestore";
import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

function Departments() {
  /*   const [varGroups, setVarGroups] = useState([]); */
  const [name, setName] = useState("");
  const [departments, setDepartments] = useState([]);

  function departmentsData() {
    const q = query(collection(db, "departments"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      setDepartments([...newData]);
    });
    return unsubscribe;
  }
  useEffect(() => departmentsData(), []);

  function handleAddDepartment(event) {
    event.preventDefault();
    const dbRef = collection(db, "departments");
    addDoc(dbRef, {
      name: name
    })
      .then((docRef) => {
        console.log(docRef.id); //p4eZcO5QV43IYnigxALJ
      })
      .catch((error) => {
        console.log(error);
      });
    setName("");
  }

  function handleDeleteDepartment(event, dId) {
    event.preventDefault();
    if (window.confirm("Biztosan törlöd a részleget?")) {
      const docRef = doc(db, "departments", dId);
      deleteDoc(docRef)
        .then((docRef) => {
          console.log("Document deleted");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <div className="Departments">
      <Container className="pt-4">
        <Form>
          <Row>
            <Col className="text-start align-middle">
              <Form.Label htmlFor="inputTitle">Új részleg létrehozása</Form.Label>
            </Col>
          </Row>
          <Row className="pt-2 pb-2">
            <Col className="text-start align-middle">
              <Form.Control id="activity" onChange={(e) => setName(e.target.value)} />
            </Col>
            <Col>
              <Button onClick={handleAddDepartment} variant="primary" type="submit">
                Hozzáad
              </Button>
            </Col>
          </Row>
        </Form>
        {departments &&
          departments.map((d, i) => (
            <Row key={i} className="pt-2 pb-2 border-secondary border-bottom">
              <Col className="d-flex justify-content-start align-items-center">
                {d.hasOwnProperty("name") && d.name}
              </Col>
              <Col>
                <Button onClick={(event) => handleDeleteDepartment(event, d.id)} variant="primary" type="submit">
                  Törlés
                </Button>
              </Col>
            </Row>
          ))}
      </Container>
    </div>
  );
}

export default Departments;
