import "./styles.css";
import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { onAuthStateChanged, signOut, getAuth, deleteUser } from "firebase/auth";
import { auth } from "./firebase";
import { useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Clients from "./Clients";
import Proposals from "./Proposals";
import EditProposal from "./EditProposal";
import Activities from "./Activities";
import EditActivity from "./EditActivity";
import PriceGroups from "./PriceGroups";
import EditPriceGroup from "./EditPriceGroup";
import VarGroups from "./VarGroups";
import EditVarGroup from "./EditVarGroup";
import ActGroups from "./ActGroups";
import EditActGroup from "./EditActGroup";
import Conditions from "./Conditions";
import EditCondition from "./EditCondition";
import Login from "./Login";
import Gallery from "./Gallery";
import Users from "./Users";
import EditUser from "./EditUser"
import Departments from "./Departments";
import ExcelExport from "./ExcelExport";
import EditDataSheet from "./EditDataSheet";
import { collection, onSnapshot, query} from "firebase/firestore";
import { db } from "./firebase";
import packageInfo from '../package.json';

function App() {
  const [user, setUser] = useState();
  const [users, setUsers] = useState([]);
  const [userDetails, setUserDetails] = useState();

  useEffect(() => {
    onAuthStateChanged(auth, (userFromDb) => {

      if (userFromDb) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setUser(userFromDb);
        // betöltjük a részleteket a firestoreból (név, email, stb).
        const q = query(collection(db, "users"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const newData = [];
          querySnapshot.forEach((doc) => {
            newData.push({ id: doc.id, ...doc.data() });
          });
          setUsers(newData);
          setUserDetails(newData.find((x) => x.uId === userFromDb.uid));
        });
        return unsubscribe;
      } else {
        // User is signed out
        // ...
      }
    });
  }, []);

  
useEffect(() => {
                   document.title = user ? "Hotel Visegrád SALES" : "..."
              }, [user])

  return (
    <div className="App">
      {user ? (
        <>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <Proposals users={users} userDetails={userDetails} />
                </>
              }
            />
            <Route
              path="/excel"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <ExcelExport userDetails={userDetails} />
                </>
              }
            />
            <Route
              path="/login"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <Login userDetails={userDetails} />
                </>
              }
            />
            <Route
              path="/clients"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <Clients users={users} userDetails={userDetails} />
                </>
              }
            />
            <Route
              path="/proposals"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <Proposals users={users} userDetails={userDetails} />
                </>
              }
            />
            <Route path="/proposal/:id/:secondparam?" element={<EditProposal />} />
            <Route
              path="/proposal"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <EditProposal users={users} userDetails={userDetails} />
                </>
              }
            />
            <Route
              path="/activities"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <Activities users={users} userDetails={userDetails} />
                </>
              }
            />
            <Route
              path="/activity"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <EditActivity users={users} userDetails={userDetails} />
                </>
              }
            />
            <Route
              path="/pricegroups"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <PriceGroups users={users} userDetails={userDetails} />
                </>
              }
            />
            <Route
              path="/pricegroup"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <EditPriceGroup users={users} userDetails={userDetails} />
                </>
              }
            />
            <Route
              path="/vargroups"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <VarGroups users={users} userDetails={userDetails} />
                </>
              }
            />
            <Route
              path="/vargroup"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <EditVarGroup users={users} userDetails={userDetails} />
                </>
              }
            />
            <Route
              path="/actgroups"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <ActGroups users={users} userDetails={userDetails} />
                </>
              }
            />
            <Route
              path="/actgroup"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <EditActGroup users={users} userDetails={userDetails} />
                </>
              }
            />
            <Route
              path="/gallery"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <Gallery users={users} userDetails={userDetails} />
                </>
              }
            />
            <Route
              path="/conditions"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <Conditions users={users} userDetails={userDetails} />
                </>
              }
            />
            <Route
              path="/condition"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <EditCondition users={users} userDetails={userDetails} />
                </>
              }
            />
            {(userDetails?.hasOwnProperty("level") && userDetails.level === 0) &&
              <>
              <Route
              path="/users"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <Users users={users} userDetails={userDetails} />
                </>
              }
              />
               <Route
              path="/user"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <EditUser users={users} userDetails={userDetails} />
                </>
              }
              />
              <Route
              path="/departments"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <Departments users={users} userDetails={userDetails} />
                </>
              }
              />
              </>
            }
          </Routes>
        </>
      ) : (
        <Routes>
            <Route path="/proposal/:id/:secondparam?" element={<EditProposal />} />
          <Route path="/*" element={<Login />} />
        </Routes>
      )}
    </div>
  );
}

function AdminNav(props) {
  const user = props.user;
  const setUser = props.setUser;
  const userDetails = props.userDetails;
  const navigate = useNavigate();
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        setUser();
        navigate("/login");
        console.log("Signed out successfully");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="/">HOTEL Visegrád Sales {packageInfo.version}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="container-fluid">
            <Nav.Link as={Link} to="/clients">
              Ügyfelek
            </Nav.Link>
            <Nav.Link as={Link} to="/proposals">
              Ajánlatok
            </Nav.Link>
            <Nav.Link as={Link} to="/activities">
              Programok
            </Nav.Link>
{/*             <Nav.Link as={Link} to="/pricegroups">
              Árcsoportok
            </Nav.Link>
            <Nav.Link as={Link} to="/vargroups">
              Variációcsoportok
            </Nav.Link>
            <Nav.Link as={Link} to="/actgroups">
              Programcsoportok
            </Nav.Link>
            <Nav.Link as={Link} to="/gallery">
              Képek
            </Nav.Link> */}
            <NavDropdown title="Részletek" id="details">
              <NavDropdown.Item as={Link} to="/pricegroups">Árcsoportok</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/vargroups">Variációcsoportok</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/actgroups">Programcsoportok</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/conditions">Lemondási feltételek</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/gallery">Képek</NavDropdown.Item>
            </NavDropdown>
            {(userDetails?.hasOwnProperty("level") && userDetails.level === 0) &&
              <>
              <NavDropdown title="Admin" id="admin">
                <NavDropdown.Item as={Link} to="/users">Felhasználók</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/departments">Részlegek</NavDropdown.Item>
              </NavDropdown>
              </>
            }
            {/*  <NavDropdown title="Nyelv" id="lang">
              {langs.map((l) => (
                <NavDropdown.Item key={l.id}>{l.name}</NavDropdown.Item>
              ))}
            </NavDropdown> */}
            <Nav.Item className="ms-auto">
              <Nav.Link onClick={handleLogout}>{userDetails?.name ? userDetails?.name : userDetails?.email}</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default App;