import React from "react";
import { useLocation } from "react-router-dom";
import { db } from "./firebase";
import { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { XSquareFill } from "react-bootstrap-icons";
import { vatTypes, langs } from "./Common";

import {
  collection,
  onSnapshot,
  query,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  getDoc
} from "firebase/firestore";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Accordion,
  Modal,
  Table
} from "react-bootstrap";

function EditPriceGroup(props) {
  let location = useLocation();
  // dev mode-ban berak egy alap árcsoportot, hogy működjön
  const gId = location.state ? location.state.gId : "AmS0J1CHASowLSWGItet";
  const [pricegroup, setPricegroup] = useState();
  const [priceGroups, setPriceGroups] = useState([]);
  const [acts, setActs] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [lang, setLang] = useState(langs[0].id);

  function pricegroupData() {
    const docRef = doc(db, "pricegroups", gId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      setPricegroup(doc.data());
    });
    return unsubscribe;
  }
  useEffect(() => pricegroupData(), []);

  function actsData() {
    const q = query(collection(db, "activities"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      var sortedArray = [...newData.filter((n) => n.vargroup !== true)].sort(
        function (a, b) {
          const nameA = a.names.find((x) => x.id === lang).name.toUpperCase();
          const nameB = b.names.find((x) => x.id === lang).name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }
      ); // names must be equal
      setActs([...sortedArray]);
    });
    return unsubscribe;
  }
  useEffect(() => actsData(), []);

  function currencyData() {
    const docRef = doc(db, "other", "currencies");
    getDoc(docRef).then((doc) => {
      setCurrencies(Object.values(doc.data()));
    });
  }
  useEffect(() => currencyData(), [acts]);

  
  function priceGroupsLetolt() {
    const q = query(collection(db, "pricegroups"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      var sortedArray = [...newData.filter((n) => n.vargroup !== true)].sort(
        function (a, b) {
          const nameA = a.names.find((x) => x.id === lang).name.toUpperCase();
          const nameB = b.names.find((x) => x.id === lang).name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }
      ); // names must be equal
      setPriceGroups([...sortedArray]);
    });
    return unsubscribe;
  }

  useEffect(() => priceGroupsLetolt(), []);

  function handleChangeField(event, eId) {
    var newValue = event.target.value;
    var targetId = event.target.id;

      if (targetId === "derived") {
      newValue = event.target.checked;
      setPricegroup({ ...pricegroup, [targetId]: newValue });
    }

    if (targetId === "name") {
      var tempArray = [...pricegroup.names];
      var tempIndex = tempArray.findIndex((t) => {
        return t.id === eId;
      });
      if (tempIndex !== -1) {
        tempArray[tempIndex].name = newValue;
        tempArray[tempIndex].id = eId;
      } else {
        tempArray.push({ id: eId, name: newValue });
      }
      setPricegroup({ ...pricegroup, names: tempArray });
    }

    if (targetId === "currencyId" | targetId === "opValue") {
      // átalakítja stringből integerré
      newValue = +newValue;
      setPricegroup({ ...pricegroup, [targetId]: newValue });
    }

     if (targetId === "refPrice" | targetId === "opType") {
      setPricegroup({ ...pricegroup, [targetId]: newValue });
    }
  }

  function handleUpdatePricegroup(event) {
    event.preventDefault();
    const docRef = doc(db, "pricegroups", gId);
    updateDoc(docRef, {
      ...pricegroup
    })
      .then((docRef) => {
        console.log("Document field updated");
      })
      .catch((error) => {
        console.log(error);
      });
    acts.map((a) => {
      var actDocRef = doc(db, "activities", a.id);
      var currentAct = acts.find((b) => b.id === a.id);
      updateDoc(actDocRef, {
        ...currentAct
      })
        .then((docRef) => {
          console.log("Document field updated");
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  function handleChangePriceField(event, aId, pId) {
    var newValue = +event.target.value;
    // összes program betöltése
    var tempArray = [...acts];
    // aktuális program betöltése
    var tempActObjIndex = tempArray.findIndex((a) => a.id === aId);
    // az aktuális ár ID-nek és group ID-nek megfelelő ár betöltése
    var tempItemObjIndex2 = tempArray[tempActObjIndex].prices.findIndex(
      (p) => p.id === pId && p.groupId === gId
    );
    var tempItemObj = tempArray[tempActObjIndex].prices.find(
      (p) => p.id === pId && p.groupId === gId
    );
    tempItemObj = { ...tempItemObj, amount: newValue };
    tempArray[tempActObjIndex].prices[tempItemObjIndex2].amount = newValue;
    setActs([...tempArray]);
  }

  // változás: ha van 0-s rangeId az árak között, akkor nincsenek ársávok, csak 1 ár van
  function handleChangeSinglePriceField(event, aId) {
    var newValue = +event.target.value;
    var tempArray = [...acts];
    var tempActIndex = tempArray.findIndex((a) => a.id === aId);

    if (!tempArray[tempActIndex].hasOwnProperty("prices"))
      tempArray[tempActIndex].prices = [];
    console.log("tempArray1", tempArray[tempActIndex].prices);
    console.log("gId", gId);
    console.log(
      tempArray[tempActIndex].prices.find(
        (p) => p.groupId === gId && p.rangeId === 0
      )
    );
    if (
      tempArray[tempActIndex].prices.find(
        (p) => p.groupId === gId && p.rangeId === 0
      )
    ) {
      tempArray[tempActIndex].prices.find(
        (p) => p.groupId === gId && p.rangeId === 0
      ).amount = newValue;
    } else {
      var priceIds = [];
      var newPriceId = 0;
      tempArray[tempActIndex].prices.map((l) => {
        tempArray[tempActIndex].prices.forEach((c) => priceIds.push(c.id));
        newPriceId = Math.max(...priceIds) + 1;
      });
      tempArray[tempActIndex].prices.push({
        amount: newValue,
        groupId: gId,
        id: newPriceId,
        rangeId: 0
      });
    }

    setActs([...tempArray]);
  }

  function getPriceItemId(a, r) {
    if (!a.hasOwnProperty("prices")) a.prices = [];
    if (
      a.prices.find((i) => i.rangeId === r.id && i.groupId === gId) &&
      a.prices
        .find((i) => i.rangeId === r.id && i.groupId === gId)
        .hasOwnProperty("id")
    ) {
      return a.prices.find((i) => i.rangeId === r.id && i.groupId === gId).id;
    } else {
      var priceIds = [];
      var newPriceId = 0;
      a.prices.map((l) => {
        if (a.prices && a.prices.length > 0) {
          a.prices.forEach((c) => priceIds.push(c.id));
          newPriceId = Math.max(...priceIds) + 1;
        }
      });
      a.prices.push({
        amount: 0,
        groupId: gId,
        id: newPriceId,
        rangeId: r.id
      });
      return newPriceId;
    }
  }

  return (
    <div className="EditPriceGroup">
      {acts && currencies && pricegroup && (
        <>
          <Container>
            <Form>
              <Form.Group as={Row} className="pb-4 pt-4">
                <Form.Label column sm={2}>
                  Árcsoport neve
                </Form.Label>
                <Col sm={4}>
                  {langs.map((l) => (
                    <>
                      {l.name}
                      <Form.Control
                        key={l.id}
                        id="name"
                        value={
                          pricegroup.hasOwnProperty("names") &&
                          pricegroup.names.find((b) => b.id === l.id) &&
                          pricegroup.names
                            .find((b) => b.id === l.id)
                            .hasOwnProperty("name")
                            ? pricegroup.names.find((b) => b.id === l.id).name
                            : ""
                        }
                        onChange={(event) => handleChangeField(event, l.id)}
                      />
                    </>
                  ))}
                </Col>
                <Col sm={4}>
                  <Button
                    id="saveButton"
                    onClick={handleUpdatePricegroup}
                    variant="primary"
                    type="submit"
                  >
                    Mentés
                  </Button>
                </Col>
              </Form.Group>
             
              <Form.Group as={Row} className="mb-4">
                <Form.Label column sm={2} className="text-start">
                  Másik árcsoportból kalkulált ár
                </Form.Label>
                <Col sm={4}>
                  <Form.Check type="checkbox" id="derived" onChange={handleChangeField} checked={pricegroup?.hasOwnProperty("derived") ? pricegroup?.derived : false} />
                </Col>
              </Form.Group>

                    
              <Form.Group as={Row} className="mb-4">
                <Form.Label column sm={2}>
                  Deviza
                </Form.Label>
                <Col
                  sm={4}
                  className="d-flex justify-content-start align-items-center"
                >
                  <Form.Select
                    id="currencyId"
                    aria-label="Currency"
                    onChange={handleChangeField}
                    value={
                      pricegroup.hasOwnProperty("currencyId")
                        ? pricegroup.currencyId
                        : "label"
                    }
                  >
                    {" "}
                    <option value="label" disabled>
                      Válassz...
                    </option>
                    {currencies.map((l, i) => (
                      <option key={i} value={l.cId}>
                        {l.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group>
            </Form>
          </Container>
          {/* *** NEM KALKULÁLT ÁR ***  */}
          {(!pricegroup.hasOwnProperty("derived") | pricegroup.derived === false) ?
          <Container>
            <Form>
              {acts &&
                acts.length > 0 &&
                acts.map((a, i) => (
                  <div key={i}>
                    <Row>
                      <Col className="text-start">
                        {a.hasOwnProperty("names") &&
                        a.names.find((l) => l.id === lang) &&
                        a.names
                          .find((l) => l.id === lang)
                          .hasOwnProperty("name")
                          ? a.names.find((l) => l.id === lang).name
                          : ""}
                      </Col>

                      {/*  Ha nincsenek ársávjai a programnak, akkor feltételezzük, hogy csak egy ára van, így azt írjuk ki és engedjük állítani. */}

                      {a &&
                        !a.hasOwnProperty("anyranges") |
                          (a.anyranges === false) && (
                          <Col className="text-start">
                            <Form.Control
                              type="number"
                              id="price"
                              value={
                                a.hasOwnProperty("prices") &&
                                a.prices.find(
                                  (p) => p.groupId === gId && p.rangeId === 0
                                ) &&
                                a.prices
                                  .find(
                                    (p) => p.groupId === gId && p.rangeId === 0
                                  )
                                  .hasOwnProperty("amount") &&
                                a.prices.find(
                                  (p) => p.groupId === gId && p.rangeId === 0
                                ).amount
                              }
                              onChange={(event) =>
                                handleChangeSinglePriceField(event, a.id)
                              }
                            ></Form.Control>
                          </Col>
                        )}
                    </Row>
                    <Table striped bordered hover>
                      <tbody>
                        {a.hasOwnProperty("priceranges") &&
                          a.hasOwnProperty("anyranges") &&
                          a.anyranges === true &&
                          a.priceranges.map((r, j) => (
                            <tr key={j}>
                              <td>
                                {r.min} - {r.max}
                              </td>
                              <td>
                                <Form.Control
                                  type="number"
                                  id="price"
                                  value={
                                    a.hasOwnProperty("prices") &&
                                    a.prices.find(
                                      (i) =>
                                        i.rangeId === r.id && i.groupId === gId
                                    ) &&
                                    a.prices
                                      .find(
                                        (i) =>
                                          i.rangeId === r.id &&
                                          i.groupId === gId
                                      )
                                      .hasOwnProperty("amount") &&
                                    a.prices.find(
                                      (i) =>
                                        i.rangeId === r.id && i.groupId === gId
                                    ).amount
                                  }
                                  onChange={(event) =>
                                    handleChangePriceField(
                                      event,
                                      a.id,
                                      getPriceItemId(a, r)
                                    )
                                  }
                                ></Form.Control>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                ))}
              </Form>
            {/* *** /NEM KALKULÁLT ÁR ***  */}
            </Container>
                :
            <Container>
            {/* *** KALKULÁLT ÁR ***  */}
              <Form>
                <Form.Group as={Row} className="mb-4">
                <Form.Label column sm={2}>
                  Referenciaár
                </Form.Label>
                  <Col
                    sm={4}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <Form.Select
                      id="refPrice"
                      aria-label="ReferencePrice"
                      onChange={handleChangeField}
                      value={
                        pricegroup.hasOwnProperty("refPrice")
                          ? pricegroup.refPrice
                          : "label"
                      }
                    >
                      {" "}
                      <option value="label" disabled>
                        Válassz...
                      </option>
                      {priceGroups.map((l, i) => (
                        <option key={i} value={l.id}>
                          {console.log("l", l)}
                          {l.names?.find((l) => l.id === lang)?.hasOwnProperty("name")
                          ? l.names.find((l) => l.id === lang).name
                          : ""}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
                 <Form.Group as={Row} className="mb-4">
                <Form.Label column sm={2}>
                  Művelet típusa
                </Form.Label>
                  <Col
                    sm={4}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <Form.Select
                      id="opType"
                      aria-label="OperationType"
                      onChange={handleChangeField}
                      value={
                        pricegroup.hasOwnProperty("opType")
                          ? pricegroup.opType
                          : "label"
                      }
                    >
                      {" "}
                      <option value="label" disabled>
                        Válassz...
                      </option>
                      <option value="substract">
                        -
                      </option>
                      <option value="add">
                        +
                      </option>
                      <option value="multiply">
                        *
                      </option>
                      <option value="divide">
                        /
                      </option>
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-4">
                <Form.Label column sm={2}>
                  Érték
                </Form.Label>
                  <Col
                    sm={4}
                    className="d-flex justify-content-start align-items-center"
                  >
                

                    <Form.Control
                                type="number"
                                id="opValue"
                                value={
                                    pricegroup.hasOwnProperty("opValue")
                                      ? pricegroup.opValue
                                      : 0
                                  }
                                onChange={handleChangeField}
                                
                    >
                  </Form.Control>
                </Col>
                </Form.Group>
              </Form>
            {/* *** /KALKULÁLT ÁR ***  */}  
            </Container>
           }
        </>
      )}
    </div>
  );
}

export default EditPriceGroup;
