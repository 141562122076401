import React from "react";
import { useLocation } from "react-router-dom";
import { db } from "./firebase";
import { useState, useEffect } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { langs } from "./Common";

import { collection, onSnapshot, query, doc, updateDoc, getDoc } from "firebase/firestore";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

function EditVarGroup(props) {
  let location = useLocation();
  // dev mode-ban berak egy alap variációcsoportot, hogy működjön
  const gId = location.state ? location.state.aId : "PeGoNhnpgv8966n2Lu8y";
  const [vargroup, setVargroup] = useState();
  const [acts, setActs] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [lang, setLang] = useState(langs[0].id);

  /*   function vargroupData() {
    const docRef = doc(db, "activities", gId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      setVargroup(doc.data());
    });
    return unsubscribe;
  }
  useEffect(() => vargroupData, []); */

  function actsData() {
    const q = query(collection(db, "activities"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      var sortedArray = [...newData.filter((n) => n.vargroup !== true)].sort(function (a, b) {
        const nameA = a.names.find((x) => x.id === lang).name.toUpperCase();
        const nameB = b.names.find((x) => x.id === lang).name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }); // names must be equal
      setActs([...sortedArray]);
      setVargroup(newData.find((n) => n.id === gId));
    });
    return unsubscribe;
  }
  useEffect(() => actsData(), []);

  function currencyData() {
    const docRef = doc(db, "other", "currencies");
    getDoc(docRef).then((doc) => {
      setCurrencies(Object.values(doc.data()));
    });
  }
  useEffect(() => currencyData(), [acts]);

  function handleChangeField(event, eId) {
    var newValue = event.target.value;
    var targetId = event.target.id;

    if (targetId === "name") {
      var tempNameArray = [...vargroup.names];
      var tempNameIndex = tempNameArray.findIndex((t) => {
        return t.id === eId;
      });
      if (tempNameIndex !== -1) {
        tempNameArray[tempNameIndex].name = newValue;
        tempNameArray[tempNameIndex].id = eId;
      } else {
        tempNameArray.push({ id: eId, name: newValue });
      }
      setVargroup({ ...vargroup, names: tempNameArray });
    }

    if (targetId === "desc") {
      var tempDescArray = vargroup.hasOwnProperty("descs") ? [...vargroup.descs] : [];
      var tempDescIndex = tempDescArray.findIndex((t) => {
        return t.id === eId;
      });
      if (tempDescIndex !== -1) {
        tempDescArray[tempDescIndex].desc = newValue;
        tempDescArray[tempDescIndex].id = eId;
      } else {
        tempDescArray.push({ id: eId, desc: newValue });
      }
      setVargroup({ ...vargroup, descs: tempDescArray });
    }
  }

  function handleUpdateVargroup(event) {
    event.preventDefault();
    const docRef = doc(db, "activities", gId);
    updateDoc(docRef, {
      ...vargroup
    })
      .then((docRef) => {
        console.log("Document field updated");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleChangeActList(event) {
    var tempActId = event.target.value;
    var tempArray = [];
    if (vargroup) {
      if (!vargroup.hasOwnProperty("activities")) vargroup.activities = [];
      tempArray = vargroup.activities;
    }
    console.log("tempActId, tempArray", tempActId, tempArray);

    if (tempArray.indexOf(tempActId) === -1 && event.target.checked) {
      tempArray.push(tempActId);
    } else {
      tempArray = tempArray.filter((e) => e !== tempActId);
    }
    console.log("tempArray utana", tempArray);
    setVargroup({ ...vargroup, activities: tempArray });
  }

  return (
    <div className="EditVarGroup">
      {vargroup && (
        <>
          {console.log("vargrouprender", vargroup)}
          <Container>
            <Form>
              <Form.Group as={Row} className="pb-4 pt-4">
                <Form.Label column sm={2}>
                  Variáció csoport neve
                </Form.Label>
                <Col sm={4}>
                  {langs.map((l) => (
                    <>
                      {l.name}
                      <Form.Control
                        key={l.id}
                        id="name"
                        value={
                          vargroup.hasOwnProperty("names") && vargroup.names.find((b) => b.id === l.id) && vargroup.names.find((b) => b.id === l.id).hasOwnProperty("name")
                            ? vargroup.names.find((b) => b.id === l.id).name
                            : ""
                        }
                        onChange={(event) => handleChangeField(event, l.id)}
                      />
                    </>
                  ))}
                </Col>
                <Form.Group as={Row} className="mb-4" controlId="desc">
                  <Form.Label column sm={2}>
                    Leírás
                  </Form.Label>
                  <Col sm={4}>
                    {langs.map((l) => (
                      <>
                        {l.name}
                        <Form.Control
                          key={l.id}
                          as="textarea"
                          rows={3}
                          value={
                            vargroup.hasOwnProperty("descs") && vargroup.descs.find((b) => b.id === l.id) && vargroup.descs.find((b) => b.id === l.id).hasOwnProperty("desc")
                              ? vargroup.descs.find((b) => b.id === l.id).desc
                              : ""
                          }
                          onChange={(event) => handleChangeField(event, l.id)}
                        />
                      </>
                    ))}
                  </Col>
                </Form.Group>
                <Col sm={4}>
                  <Button id="saveButton" onClick={handleUpdateVargroup} variant="primary" type="submit">
                    Mentés
                  </Button>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="pb-4 pt-4">
                <Col className="text-start">
                  {acts.map((a, i) => (
                    <>
                      <Form.Check
                        key={i}
                        type="checkbox"
                        id="listActs"
                        label={
                          a.hasOwnProperty("names") && a.names.find((l) => l.id === lang) && a.names.find((l) => l.id === lang).hasOwnProperty("name") ? a.names.find((l) => l.id === lang).name : ""
                        }
                        onChange={handleChangeActList}
                        checked={vargroup.hasOwnProperty("activities") && vargroup.activities.indexOf(a.id) !== -1}
                        value={a.id}
                      />
                    </>
                  ))}
                </Col>
              </Form.Group>
            </Form>
          </Container>
        </>
      )}
    </div>
  );
}

export default EditVarGroup;
