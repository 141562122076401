import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase";
import { NavLink, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Accordion } from "react-bootstrap";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        navigate("/");
        console.log(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };

  return (
    <>
      <Container>
        <Row className="vh-100 justify-content-center">
          <Col sm={4}>
            <Row className="vh-100">
              <Col className="d-flex justify-content-center flex-column">
                <Row>
                  <Col>
                    <h4>Bejelentkezés</h4>
                  </Col>
                  <Form>
                    <Col className="pt-3">
                      <Form.Label htmlFor="email-address">
                        E-mail cím
                      </Form.Label>
                      <Form.Control
                        id="email"
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </Col>
                    <Col className="pt-3">
                      <Form.Label htmlFor="email-address">Jelszó</Form.Label>
                      <Form.Control
                        id="password"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </Col>
                    <Col className="pt-3">
                      <Button onClick={onLogin} variant="primary" type="submit">
                        Bejelentkezés
                      </Button>
                    </Col>
                  </Form>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/*  <main>
        <section>
          <div>
            <p> FocusApp </p>

            <form>
              <div>
                <label htmlFor="email-address">Email address</label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  required
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="password">Password</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div>
                <button onClick={onLogin}>Login</button>
              </div>
            </form>

            <p className="text-sm text-white text-center">
              No account yet? <NavLink to="/signup">Sign up</NavLink>
            </p>
          </div>
        </section>
      </main> */}
    </>
  );
};

export default Login;
