import React from "react";
import { useLocation } from "react-router-dom";
import { db } from "./firebase";
import { useState, useEffect } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { langs } from "./Common";

import { collection, onSnapshot, query, doc, updateDoc, getDoc } from "firebase/firestore";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

function EditUser(props) {
  let location = useLocation();
  const uId = location.state ? location.state.uId : "PeGoNhnpgv8966n2Lu8y";
  const [user, setUser] = useState();
  const [departments, setDepartments] = useState([]);

  function userData() {
    const docRef = doc(db, "users", uId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      setUser(doc.data());
    });
    return unsubscribe;
    }
    
  useEffect(() => userData(), []);

  function departmentsData() {
    const q = query(collection(db, "departments"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      setDepartments([...newData]);
    });
    return unsubscribe;
  }
    useEffect(() => departmentsData(), []);
    
    function handleChangeField(event) {
        var newValue = event.target.value;
        var targetId = event.target.id;
        if (targetId === "name") {
            setUser(...user, { name: newValue })

        }
    }

  /* function handleChangeField(event, eId) {
    var newValue = event.target.value;
    var targetId = event.target.id;

    if (targetId === "name") {
      var tempNameArray = [...vargroup.names];
      var tempNameIndex = tempNameArray.findIndex((t) => {
        return t.id === eId;
      });
      if (tempNameIndex !== -1) {
        tempNameArray[tempNameIndex].name = newValue;
        tempNameArray[tempNameIndex].id = eId;
      } else {
        tempNameArray.push({ id: eId, name: newValue });
      }
      setVargroup({ ...vargroup, names: tempNameArray });
    }

    if (targetId === "desc") {
      var tempDescArray = vargroup.hasOwnProperty("descs") ? [...vargroup.descs] : [];
      var tempDescIndex = tempDescArray.findIndex((t) => {
        return t.id === eId;
      });
      if (tempDescIndex !== -1) {
        tempDescArray[tempDescIndex].desc = newValue;
        tempDescArray[tempDescIndex].id = eId;
      } else {
        tempDescArray.push({ id: eId, desc: newValue });
      }
      setVargroup({ ...vargroup, descs: tempDescArray });
    }
  } */

  function handleUpdateUser(event) {
    event.preventDefault();
    const docRef = doc(db, "users", uId);
    updateDoc(docRef, {
      ...user
    })
      .then((docRef) => {
        console.log("Document field updated");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleChangeDepartmentList(event) {
    var tempActId = event.target.value;
    var tempArray = [];
    if (user) {
      if (!user.hasOwnProperty("departments")) user.departments = [];
      tempArray = user.departments;
    }
    if (tempArray.indexOf(tempActId) === -1 && event.target.checked) {
      tempArray.push(tempActId);
    } else {
      tempArray = tempArray.filter((e) => e !== tempActId);
    }
    setUser({ ...user, departments: tempArray });
  }

  function handleChangeDefaults(event) {
    var tempValue = event.target.value;
    var tempId = event.target.id;
    var tempArray = [];
    if (user) {
      if (!user.hasOwnProperty("defaults")) user.defaults = {};
      if (tempId === "visibleDeptsInProposalList") {
        if (!user.defaults.hasOwnProperty("visibleDeptsInProposalList")) user.defaults.visibleDeptsInProposalList = [];
        tempArray = user.defaults.visibleDeptsInProposalList;
        if (tempArray.indexOf(tempValue) === -1 && event.target.checked) {
          tempArray.push(tempValue);
        } else {
          tempArray = tempArray.filter((e) => e !== tempValue);
        }
        setUser({ ...user, defaults: { ...user.defaults, visibleDeptsInProposalList: tempArray } });
      }

      if (tempId === "defaultDepartment") {
        console.log("tempValue", tempValue)
        setUser({ ...user, defaults: { ...user.defaults, defaultDepartment: tempValue } });
      }
    }
  }

  return (
    <div className="EditUser">
      {user && (
        <>
          <Container>
            <Form>
              <Form.Group as={Row} className="pb-4 pt-4">
                <Form.Label column sm={2}>
                  Felhasználó neve
                </Form.Label>
                <Col sm={4}>
                      <Form.Control
                        id="name"
                        value={user.hasOwnProperty("name") ? user.name : "" }
                        onChange={handleChangeField}
                      />
                </Col>
                <Col sm={4}>
                  <Button id="saveButton" onClick={handleUpdateUser} variant="primary" type="submit">
                    Mentés
                  </Button>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="pb-4 pt-4">
                 <Form.Label column sm={2}>
                  A felhasználóhoz tartozó részlegek
                </Form.Label>
                <Col className="text-start">
                  {departments.map((d, i) => (
                    <>
                      <Form.Check
                        key={i}
                        type="checkbox"
                        id="listDepartments"
                        label={d.hasOwnProperty("name") ? d.name : ""}
                        onChange={handleChangeDepartmentList}
                        checked={user.hasOwnProperty("departments") && user.departments.indexOf(d.id) !== -1}
                        value={d.id}
                      />
                    </>
                  ))}
                </Col>
              </Form.Group>
              <Row className="border-secondary border-bottom pb-2"><Col className="text-start">
                Alapbeállítások
              </Col></Row>
              <Form.Group as={Row} className="pb-4 pt-4">
                 <Form.Label column sm={2}>
                  Ajánlatok listájában látható részlegek
                </Form.Label>
                <Col className="text-start">
                  {departments.map((d, i) => (
                    //csak a felhasználóhoz tartozó részlegeket listázza ki
                    user.departments?.find(x => x === d.id) && <>
                      {console.log("userdefaults", user.defaults?.visibleDeptsInProposalList?.indexOf(d.id))}
                      <Form.Check
                        key={i}
                        type="checkbox"
                        id="visibleDeptsInProposalList"
                        label={d.hasOwnProperty("name") ? d.name : ""}
                        onChange={handleChangeDefaults}
                        checked={user.hasOwnProperty("defaults") && user.defaults.hasOwnProperty("visibleDeptsInProposalList") && user.defaults?.visibleDeptsInProposalList?.indexOf(d.id) !== -1}
                        value={d.id}
                      />
                    </>
                  ))}
                </Col>
              </Form.Group>
               <Form.Group as={Row} className="pb-4 pt-4">
                 <Form.Label column sm={2}>
                  Új ajánlatnál beállított részleg
                </Form.Label>
                <Col className="text-start" sm={4}>
                  <Form.Select id="defaultDepartment" aria-label="Department" onChange={handleChangeDefaults} value={user.defaults?.hasOwnProperty("defaultDepartment") ? user.defaults.defaultDepartment : "label"}>
                        <option key="default" value="label" disabled>
                          Válassz...
                        </option>
                        {departments.map((d) => (
                          <option key={d.id} value={d.id}>
                            {d.name}
                          </option>
                        ))}
                      </Form.Select>
                </Col>
              </Form.Group>
            </Form>
          </Container>
        </>
      )}
    </div>
  );
}

export default EditUser;
