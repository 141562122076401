export const words = {
  ajanlat: {
    hu: "Ajánlat",
    en: "Proposal"
  },
  naknek: {
    hu: "",
    en: "for"
  },
  reszere: {
    hu: "részére",
    en: ""
  },
  arajanlat: {
    hu: "árajánlat",
    en: "price offer"
  },
  nev: {
    hu: "név",
    en: "title"
  },
  egyseg: {
    hu: "egység",
    en: "unit"
  },
  brutto: {
    hu: "bruttó",
    en: "gross"
  },
  netto: {
    hu: "nettó",
    en: "net"
  },
  afa: {
    hu: "ÁFA",
    en: "VAT"
  },
  ar: {
    hu: "ár",
    en: "price"
  },
  bruttoosszeg: {
    hu: "bruttó összeg",
    en: "total gross"
  },
  nettoosszeg: {
    hu: "nettó összeg",
    en: "total net"
  },
  letszam: {
    hu: "létszám",
    en: "guest count"
  },
  mennyiseg: {
    hu: "mennyiség",
    en: "amount"
  },
  osszesen: {
    hu: "összesen",
    en: "total"
  },
  letoltesxls: {
    hu: "letöltés XLS formátumban",
    en: "download XLS"
  },
  nyelv: {
    hu: "nyelv",
    en: "language"
  },
  magyar: {
    hu: "magyar",
    en: "hungarian"
  },
  angol: {
    hu: "angol",
    en: "english"
  },
  ajanlatreszletei: {
    hu: "az ajánlat részletei",
    en: "details of the proposal"
  },
  erkezesdatuma: {
    hu: "érkezés dátuma",
    en: "date of arrival"
  },
  tavozasdatuma: {
    hu: "távozás dátuma",
    en: "date of departure"
  },
  opciodatuma: {
    hu: "opció lejáratának dátuma",
    en: "tentative deadline"
  },
  kb:
  {
    hu: "kb.",
    en: "approx."
    },
  idotartam: {
    hu: "időtartam",
    en: "duration"
  },
  perc: {
    hu: "perc",
    en: "minute"
  },
  percek: {
    hu: "perc",
    en: "minutes"
  },
  lemondasifoglalasifeltetelek: {
    hu: "foglalási és lemondási feltételek",
    en: "booking and cancellation conditions"
  },
  alkalom: {
    hu: "alkalom",
    en: "occasion"
  },


};
