import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// const config = {
//   apiKey: "AIzaSyDblTESEB1SbAVkpy2q39DI2OHphL2-Jxw",
//   authDomain: "fun-food-friends-eeec7.firebaseapp.com",
//   databaseURL: "https://fun-food-friends-eeec7.firebaseio.com",
//   projectId: "fun-food-friends-eeec7",
//   storageBucket: "fun-food-friends-eeec7.appspot.com",
//   messagingSenderId: "144750278413"
// };
// firebase.initializeApp(config);
// export default firebase;

// Set the configuration for your app
// TODO: Replace with your project's config object
var config = {
  apiKey: "AIzaSyBBi_TXvqmGMJAyJVMR0D9vmnY8YWby9kQ",
  authDomain: "hotelsales-42031.firebaseapp.com",
  projectId: "hotelsales-42031",
  storageBucket: "hotelsales-42031.appspot.com",
  messagingSenderId: "898782339451",
  appId: "1:898782339451:web:7e0433768db7eff5701dde",
  measurementId: "G-S5YNBV5G65"
};
const app = initializeApp(config);

export const auth = getAuth(app);
// Get a reference to the database service
export const db = getFirestore(app);
