export const imagesPath = "/images/";

export const vatTypes = [
  {
    id: 0,
    amount: 0
  },
  {
    id: 1,
    amount: 5
  },
  {
    id: 2,
    amount: 27
  }
];

export const langs = [
  {
    id: "hu",
    name: "Magyar"
  },
  {
    id: "en",
    name: "English"
  }
];
