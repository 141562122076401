import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { db } from "./firebase";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image
} from "react-bootstrap";
import {
  collection,
  onSnapshot,
  query,
  addDoc,
  Timestamp,
  doc,
  deleteDoc
} from "firebase/firestore";
import { XSquareFill} from "react-bootstrap-icons";
import { imagesPath } from "./Common";

function Gallery() {
  const [responses, setResponses] = useState([]);

  const [images, setImages] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  var [newImageFileName, setNewImageFileName] = useState("");

  const q = query(collection(db, "images"));
  function adatLetolt() {
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      setImages(newData);
    });
    return unsubscribe;
  }

  useEffect(() => adatLetolt(), []);

  function onFileChange(e) {
    setSelectedFile(e.target.files);
    /*   dataForm.append("file[]", files[0], files[0].name);
    

    setSelectedImage(dataForm);
    setNewImageFileName(files[0].name); */
  }

  function onSubmit(event) {
    event.preventDefault();

    const data = new FormData();

    for (let i = 0; i < selectedFile.length; i++) {
      data.append("file[]", selectedFile[i]);
    }
    console.log("selectedFile", selectedFile);
    axios({
      method: "post",
      url: "https://www.visegradevent.hu/upload.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then(function (response) {
        addImage(response.data);
        console.log(response);
        /*         addImage(); */
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }

  function addImage(filesArray) {
    const dbRef = collection(db, "images");
    filesArray.map((f) => {
      addDoc(dbRef, {
        filename: f.filename,
        dateAdded: Timestamp.fromDate(new Date())
      })
        .then((docRef) => {
          console.log(docRef.id); //p4eZcO5QV43IYnigxALJ
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  function thumbFileName(filename) {
    var dotIndex = filename.lastIndexOf(".");
    if (dotIndex === -1) return filename + "_thumb";
    // itt a png-ből png-t csinál, de nekünk minden thumb jpeg
    /*  else return filename.substring(0, dotIndex) + "_thumb" + filename.substring(dotIndex); */ else
      return filename.substring(0, dotIndex) + "_thumb.jpg";
  }

  function handleDeleteImage(event, imageId) {
    event.preventDefault();
    if (window.confirm("Biztosan törlöd ezt a képet?")) {
      const docRef = doc(db, "images", imageId);
      deleteDoc(docRef)
        .then((docRef) => {
          console.log("Document deleted");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <Container>
      <Form>
        <Form.Group as={Row} className="pb-4 pt-4">
          <Form.Label column sm={2} className="text-start">
            Kép feltöltése
          </Form.Label>
          <Col sm={4}>
            <Form.Control
              type="file"
              multiple
              id="userFile"
              name="userFile"
              onChange={onFileChange}
            />
          </Col>
          <Col className="d-flex justify-content-center align-items-end">
            <Button onClick={onSubmit} variant="primary" type="submit">
              Feltölt
            </Button>
          </Col>
        </Form.Group>
      </Form>
      <Row>
        {images &&
          images.length > 0 &&
          images.sort((a,b) => (b.dateAdded - a.dateAdded)).map((i) => (
            <>
              {i.hasOwnProperty("filename") && (
                <Col
                  key={i.id}
                  sm={2}
                  className="d-flex flex-column justify-content-between"
                  style={{ wordBreak: "break-all" }}
                >
                  <Col className="d-flex justify-content-end">
                    <XSquareFill onClick={(event) => handleDeleteImage(event, i.id)} />
                  </Col>
                  <Image
                    src={
                      imagesPath +
                      i.filename.substring(0, i.filename.lastIndexOf(".")) +
                      "_thumb.jpg"
                    }
                    thumbnail
                  />
                  {i.filename}
                </Col>
              )}
            </>
          ))}
      </Row>
    </Container>
  );
}

export default Gallery;
