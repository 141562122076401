//2022.12.23. Ebben a változatban volt még order és drag, de kiszedem

// proposal = aktuális ajánlat, object
// acts = összes aktivitás (programok), (array)
// selectedActs = az aktuális ajánlathoz tartozó kiválasztott programok (array)

//TODO: Lovagi torna árát nem tölti be, ha a lovagi tornánál a globál létszám van beállítva
//TODO: bármilyen selectnél kiválaszt egyet, de valójában az nincs kiválasztva value szerint, tehát pl.
//      az árat nem tölti be itt, ha a Céges 2022-n áll, csak ha egyszer átállítod, majd vissza
//      Ugyanez a helyzet az Activity szerkesztőben is (fő látszik mindenhol egyésgként, de valójában semmmi nincs beállítva)

import { React, useRef} from "react";
import { useLocation, useParams } from "react-router-dom";
import { db } from "./firebase";
import { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { XSquareFill, CardList, CurrencyExchange } from "react-bootstrap-icons";
import { vatTypes, langs, imagesPath } from "./Common";
import "./clientview.css";
import { words } from "./Vocabulary";

import { collection, onSnapshot, query, doc, updateDoc, getDoc } from "firebase/firestore";
import { Container, Row, Col, Form, Button, Modal, Table, Nav, Navbar, NavDropdown, Image } from "react-bootstrap";
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import ProposalPdf from "./ProposalPdf";
import { pdf } from '@react-pdf/renderer';
import EditDataSheet from "./EditDataSheet";
import EditActivity from "./EditActivity";

function EditProposal(props) {
  let location = useLocation();
  let viewId = useParams();
  var pId = "";
  if (location.state) {
    pId = location.state.pId;
  } else {
    if (viewId.hasOwnProperty("id")) {
      pId = viewId.id;
    } else {
      // dev mode-ban berak egy alap ajánlatot, hogy működjön
      pId = "zMSj6UXg5uEegO4Mllc6";
    }
  }
  /*  location.state ? location.state.pId : "Rz9rjr0I2PEy1HeONO9T"; */
  const [clients, setClients] = useState([]);
  const [proposal, setProposal] = useState();
  const [statuses, setStatuses] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]);
  const [selectedContact, setSelectedContact] = useState([]);
  const [actualContacts, setActualContacts] = useState([]);
  const [acts, setActs] = useState([]);
  const [selectedActs, setSelectedActs] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [units, setUnits] = useState([]);
  const [vatShares, setVatShares] = useState([]);
  const [pricegroups, setPricegroups] = useState([]);
  const [actgroups, setActgroups] = useState([]);
  var [netPrices, setNetPrices] = useState([]);
  var [grossPrices, setGrossPrices] = useState([]);
  const [lang, setLang] = useState("hu");
  const [images, setImages] = useState([]);
  const [selVarItems, setSelVarItems] = useState([]);
  var [departments, setDepartments] = useState([]);
  var [conditions, setConditions] = useState([]);
  const [selectedgroups, setSelectedgroups] = useState([]);
  const priceTableRef = useRef(null);
  var tableCounter = 0;

  var totalNet = [];
  var totalGross = [];

  function proposalData() {
    const docRef = doc(db, "proposals", pId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      setProposal(doc.data());
    });
    return unsubscribe;
  }
  useEffect(() => proposalData(), []);

  function actsData() {
    const q = query(collection(db, "activities"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      var sortedArray = [...newData].sort(function (a, b) {
        const nameA = a.names.find((x) => x.id === lang).name.toUpperCase();
        const nameB = b.names.find((x) => x.id === lang).name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }); // names must be equal
      setActs([...sortedArray]);
    });
    return unsubscribe;
  }
  useEffect(() => actsData(), []);

  function currencyData() {
    const docRef = doc(db, "other", "currencies");
    getDoc(docRef).then((doc) => {
      setCurrencies(Object.values(doc.data()));
    });
  }
  useEffect(() => currencyData(), [proposal]);

  function unitData() {
    const docRef = doc(db, "other", "units");
    getDoc(docRef).then((doc) => {
      setUnits(Object.values(doc.data()));
    });
  }
  useEffect(() => unitData(), [proposal]);

  function statusData() {
    const docRef = doc(db, "other", "statuses");
    getDoc(docRef).then((doc) => {
      setStatuses(Object.values(doc.data()));
    });
  }
  useEffect(() => statusData(), [proposal]);

  function clientData() {
    const q = query(collection(db, "clients"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      setClients(newData);
    });
    return unsubscribe;
  }
  useEffect(() => clientData(), [proposal]);

  function departmentsData() {
    const q = query(collection(db, "departments"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      setDepartments([...newData]);
    });
    return unsubscribe;
  }
  useEffect(() => departmentsData(), []);

    function conditionsData() {
    const q = query(collection(db, "conditions"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      setConditions([...newData]);
    });
    return unsubscribe;
  }
  useEffect(() => conditionsData(), []);

  function pricegroupData() {
    const q = query(collection(db, "pricegroups"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      var sortedArray = [...newData].sort(function (a, b) {
        const nameA = a.names.find((x) => x.id === lang).name.toUpperCase();
        const nameB = b.names.find((x) => x.id === lang).name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }); // names must be equal
      setPricegroups([...sortedArray]);
    });
    return unsubscribe;
  }
  useEffect(() => pricegroupData(), []);

  function actgroupData() {
    const q = query(collection(db, "actgroups"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      var sortedArray = [...newData].sort(function (a, b) {
        const nameA = a.names.find((x) => x.id === lang).name.toUpperCase();
        const nameB = b.names.find((x) => x.id === lang).name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }); // names must be equal
      setActgroups([...sortedArray]);
    });
    return unsubscribe;
  }
  useEffect(() => actgroupData(), []);

  function imagesData() {
    const q = query(collection(db, "images"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ ...doc.data(), id: doc.id });
      });
      setImages([...newData]);
    });
    return unsubscribe;
  }

  useEffect(() => imagesData(), []);

  function getSelectedClient() {
    if (clients && proposal && clients.find((c) => c.id === proposal.clientId)) {
      setSelectedClient([clients.find((c) => c.id === proposal.clientId)]);
      if (
        clients.find((c) => c.id === proposal.clientId) &&
        clients.find((c) => c.id === proposal.clientId).hasOwnProperty("contacts") &&
        clients.find((c) => c.id === proposal.clientId).contacts.length > 0 &&
        clients.find((c) => c.id === proposal.clientId).contacts?.find((e) => e.id === proposal.contactId)
      ) {
        setSelectedContact([clients.find((c) => c.id === proposal.clientId).contacts?.find((e) => e.id === proposal.contactId)]);
      }
    }
  }
  useEffect(() => getSelectedClient(), [clients, proposal]);

  function getActualContacts() {
    if (clients && selectedClient[0] && clients.find((c) => c.id === selectedClient[0].id) && clients.find((c) => c.id === selectedClient[0].id).hasOwnProperty("contacts")) {
      setActualContacts(clients.find((c) => c.id === selectedClient[0].id).contacts);
    }
  }

  useEffect(() => {
    if (selectedClient) {
      getActualContacts();
    }
  }, [selectedClient]);

  function getSelectedActs() {
    if (acts && proposal && proposal.acts) {
      setSelectedActs(proposal.acts);
    }
  }

  useEffect(() => {
    if (acts) getSelectedActs();
  }, [proposal]);

  /*   function changeProposalActs() {
    if (proposal && selectedActs) {
      setProposal({ ...proposal, acts: selectedActs });
    }
  }

  useEffect(() => {
    if (selectedActs) changeProposalActs();
  }, [selectedActs]);
 */

  function getCurrency() {
    return currencies?.find((x) => x.cId === proposal?.currencyId)?.hasOwnProperty("name") && currencies?.find((x) => x.cId === proposal?.currencyId)?.name;
  }

  function getVatShares() {
    var tempArray = [];
    selectedActs.map((a) => {
      var tempIndex = tempArray.push({ id: a.id }) - 1;
      var tempVatArray = [];
      vatTypes.map((v) => {
        if (acts.find((b) => b.id === a.actId) && acts.find((b) => b.id === a.actId).hasOwnProperty("vat") && acts.find((b) => b.id === a.actId).vat.find((w) => w.id === v.id)) {
          tempVatArray.push(acts.find((b) => b.id === a.actId).vat.find((w) => w.id === v.id));
        }
      });
      tempArray[tempIndex].share = tempVatArray;
    });
    setVatShares(tempArray);
  }
  useEffect(() => {
    if (selectedActs && selectedActs.length > 0 && acts && acts.length > 0) {
      getVatShares();
    }
  }, [selectedActs]);

  // getNetPrices és getGrossPrices-ban gyűjtjük ki minden kiválasztott program árát egy array-ben
  // akkor, ha számolni kell (pl. felhasználó a nettó árat adja meg, akkor a grossPrices array-ből nézzük ki a bruttót)
  // beszorozza a különböző ÁFA kulcsok megoszlásait (share) az árral és az ÁFA kulccsal

  function getNetPrices() {
    var tempArray = [];
    selectedActs.map((a) => {
      var tempIndex = tempArray.push({ id: a.id }) - 1;
      tempArray[tempIndex].totalAmount = 0;
      vatTypes.map((w) => {
        if (
          vatShares?.find((v) => v.id === a.id)?.share?.find((x) => x.id === w.id)?.hasOwnProperty("share")
        ) {
          tempArray[tempIndex].totalAmount =
            tempArray[tempIndex].totalAmount + ((vatShares.find((v) => v.id === a.id).share.find((x) => x.id === w.id).share / 100).toFixed(2) * a.price) / (w.amount / 100 + 1);
        }
      });
    });

    setNetPrices([...tempArray]);
  }

  useEffect(() => {
    if (vatShares && vatShares.length > 0 && selectedActs && selectedActs.length > 0 && selectedActs.length === vatShares.length) {
      getNetPrices();
    }
  }, [vatShares, selectedActs]);

  function getGrossPrices() {
    var tempArray = [];
    selectedActs.map((a) => {
        var tempIndex = tempArray.push({ id: a.id }) - 1;
        tempArray[tempIndex].totalAmount = 0;
        vatTypes.map((w) => {
          if (
            vatShares
              ?.find((v) => v.id === a.id)
              ?.share?.find((x) => x.id === w.id)
              ?.hasOwnProperty("share")
          ) {
            tempArray[tempIndex].totalAmount =
              tempArray[tempIndex].totalAmount + (vatShares.find((v) => v.id === a.id).share.find((x) => x.id === w.id).share / 100).toFixed(2) * a.price * (w.amount / 100 + 1);
          }
        });
      }
    );

    setGrossPrices([...tempArray]);
  }

  useEffect(() => {
    if (vatShares && vatShares.length > 0 && selectedActs && selectedActs.length > 0) {
      getGrossPrices();
    }
  }, [vatShares]);

  function timestampToDate(ts) {
    ts = new Date(ts * 1000);
    return ts.toLocaleString("en-EN", { year: "numeric" }) + "-" + ts.toLocaleString("en-EN", { month: "2-digit" }) + "-" + ts.toLocaleString("en-EN", { day: "2-digit" });
  }

  function getNetPrice(a) {
    if ((a.hasOwnProperty("selected") && a.selected === true) || !a.hasOwnProperty("parentId")) {
      // ha ki van töltve az "alkalom" mező, akkor annyiszor szorozza be az árat, ha nincs, akkor alapból 1-el
      let actMultiplier = a.hasOwnProperty("actMultiplier") ? a.actMultiplier : 1;
      const netPrice = proposal.net
        ? a.actPax !== 0
          ? a.actPax * actMultiplier * a.price
          : proposal.pax * actMultiplier * a.price
        : a.actPax !== 0
        ? a.actPax * actMultiplier * netPrices.find((n) => n.id === a.id)?.totalAmount
        : proposal.pax * actMultiplier * netPrices.find((n) => n.id === a.id)?.totalAmount;
      if (!totalNet.find((x) => x.id === a.id)) {
        totalNet.push({ id: a.id, netPrice: netPrice });
      }
      return netPrice;
    } else {
      return 0;
    }
  }

  function getGrossPrice(a) {
    if ((a.hasOwnProperty("selected") && a.selected === true) || !a.hasOwnProperty("parentId")) {
      let actMultiplier = a.hasOwnProperty("actMultiplier") ? a.actMultiplier : 1;
      const grossPrice = proposal.net
        ? a.actPax !== 0
          ? a.actPax * actMultiplier * grossPrices?.find((n) => n.id === a.id)?.totalAmount
          : proposal.pax * actMultiplier * grossPrices?.find((n) => n.id === a.id)?.totalAmount
        : a.actPax !== 0
        ? a.actPax * actMultiplier * a.price
        : proposal.pax * actMultiplier * a.price;
      if (!totalGross.find((x) => x.id === a.id)) {
        totalGross.push({ id: a.id, grossPrice: grossPrice });
      }
      return grossPrice;
    } else {
      return 0;
    }
  }

  function displayTotalGross() {
    return totalGross.reduce((a, b) => a + b.grossPrice, 0);
  }

  function displayTotalNet() {
    return totalNet.reduce((a, b) => a + b.netPrice, 0);
  }


  function getSelectedGroups() {
    var tempActGroupArray = [];
    if (selectedActs && selectedActs.length > 0 && actgroups && actgroups.length > 0) {
      actgroups.map((a) => {
        selectedActs.map((s) => {
          if (a.activities.find((x) => x === s.actId)) {
            return tempActGroupArray.push(a);
          }
        });
      });
    }
    tempActGroupArray = [...new Set(tempActGroupArray)];
    setSelectedgroups([...tempActGroupArray]);
  }

  useEffect(() => {
    if (selectedActs && selectedActs.length > 0 && actgroups && actgroups.length > 0) {
      getSelectedGroups();
    }
  }, [selectedActs]);

  function startsWithVowel(text) {
    const vowels = ["a", "e", "i", "o", "u", "ü", "ű", "ó", "ö", "ő", "á", "é"];
    return vowels.indexOf(text?.charAt(0).toLowerCase()) !== -1;
  }

  function imageInject(str, imageIdsArray) {
    if (typeof str !== "string") {
      return false;
    }
    // itt cseréli ki a kép taget, azaz a kapcsos zárójelbe rakott image-id egy Image react tagre
    var stringArray = str.split(/(\{.+?\})/g);
    return stringArray.map((s) => {

      if (/\{(.+?)\}/.test(s) && imageIdsArray instanceof Array) {
        const insImagesArray = s.split('+');
        return <>
          <Row>
            {insImagesArray.map((j) => {
              var imageId = imageIdsArray.find((x) => x === j.replace(/{/, "").replace(/}/, ""));
              return <Col><Image src={imagesPath + images.find((i) => i.id === imageId)?.filename} /></Col>;
            })}
          </Row>
        </>
        
      } else {
        // ha nem képről van szó, akkor a szövegben megkeresi a [b] és [/b] között található szöveget, és vastagítja

        var newStringArray = s.split(/(\[b][\s\S]+?\[\/b])/g);
        return newStringArray.map((z) => {
         
          if (/\[b]([\s\S]+?)\[\/b]/.test(z)) {
           
            return <span style={{ fontWeight: "bold" }}>{z.replace("[b]", "").replace("[/b]", "")}</span>;
          } else {
          
            return z;
          }
        });
      }
    });
  }

  function formatCurrency(int) {
    var currDisplay = "name";
    var currStyle = "decimal";
    var actualCurrency = getCurrency();
    if (proposal?.currencyId === 1) {
      currDisplay = "narrowSymbol";
      currStyle = "currency";
      actualCurrency = "EUR";
    }
    if (lang === "hu") {
      return Intl.NumberFormat("hu-HU", { maximumFractionDigits: 2, style: currStyle, currencyDisplay: currDisplay, currency: actualCurrency }).format(int);
    } else if (lang === "en") {
      return Intl.NumberFormat("en-EN", { /* maximumSignificantDigits: 3 */ maximumFractionDigits: 2, style: currStyle, currencyDisplay: currDisplay, currency: actualCurrency }).format(int);
    } else {
      return int;
    }
  }

  function resetTableCounter() {
    tableCounter = 0;
  }


  function handleChangeActChecked(event, aId) {
    const actChecked = event.target.checked;
    var tempArray = [...selectedActs];
      var tempObjIndex = tempArray.findIndex((x) => x.id === aId);
      tempArray[tempObjIndex].actChecked = actChecked;
      setSelectedActs([...tempArray]);

  }

  function handleChangeVarChecked(event, varId, actId) {
    var tempArray = [...selectedActs];
    var currentVarAct = tempArray.find((x) => {
      return x.actId === varId && x.parentId === actId;
    });

    currentVarAct.selected = !currentVarAct.selected;

    /*  if (
      tempArray.find((x) => {
        return x.actId === varId && x.parentId === actId;
      })
    ) {
      tempArray = tempArray.filter((x) => {
        if ((x.actId !== varId && x.parentId !== actId) || (x.actId !== varId && x.parentId === actId)) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      if (selectedActs && selectedActs.length > 0) {
        selectedActs.forEach((c) => selectedActIds.push(c.id));
        newSelectedActId = Math.max(...selectedActIds) + 1;
      }

      tempArray.push({
        actId: varId,
        id: newSelectedActId,
        actPax: proposal.pax,
        price: 0,
        parentId: actId
      });
    } */
    setSelectedActs([...tempArray]);
  }


  /*   const displayTotalGross = useMemo(() => totalGross?.reduce((a, b) => a + b, 0), [totalGross])
   */
  function loadPrices(event, isSinglePrice, sId) {
    // az elején megnézzük, hogy vargroupról van-e szó, ha igen, akkor átlagot számolunk a vargrouphoz tartozó programok áraiból
    // ha nem, akkor az egyes programok árait nézzük
    // betöltjük az árakat, attól függően, hogy van-e ársáv, vagy nincs.
    // ha van ársáv, akkor megkeressük az ide tartozó árat (ársáv az activity-ben meghatározva, árcsoporttal együtt)
    // ha nincs ársáv, akkor a single árat keressük ki, ami ahhoz az árcsoporthoz (pricegroup) tartozik
    // 2022.12.27 - vargroupoknak már nincs ára, mert az átlagolást elvetettem, helyette kiválasztani lehet,
    // hogy melyik elemet rakod be a vargroupból, és csak azt számolja (peddig mennyi szopás volt átlagot számolni!)

    var currentPrice = 0;
    if (selectedActs && proposal && proposal.pricegroupId) {
      // megnézzük, hogy csak egy tétel árát akarjuk-e betölteni, vagy az összeset
      var singleActIndex = isSinglePrice ? selectedActs?.findIndex((s) => s.id === sId) : 0;
      // ha csak 1 ár kell, akkor annak az árnak az adata lesz az array-ben, különben az összes
      var tempArray = (isSinglePrice === true) ? [selectedActs[singleActIndex]] : [...selectedActs];
      // de kell az eredeti array is (akkor is, ha csak egy árat változtatunk)
      var tempSelectedActs = [...selectedActs];
      tempArray.map((s) => {
        var currentAct = acts.find((x) => x.id === s.actId);
        
      // kalkulált (derived) ár esetén egy másik árból képlettel számoljuk ki az árat
            var priceDerived = (pricegroups.find((g) => g.id === proposal.pricegroupId)?.hasOwnProperty("derived") && pricegroups.find((g) => g.id === proposal.pricegroupId).derived === true) ? true : false;
            var currentPriceGroupId = priceDerived ? pricegroups.find((g) => g.id === proposal.pricegroupId).refPrice : proposal.pricegroupId
            var currentActPrice = currentAct.prices?.find((p) => p.groupId === currentPriceGroupId && p.rangeId === 0);
  
            var currentPriceGroup = pricegroups.find((g) => g.id === currentActPrice?.groupId);
          if ((currentAct.anyranges === false) | !currentAct.hasOwnProperty("anyranges")) {
            if (priceDerived) {
             
              if (pricegroups.find((g) => g.id === proposal.pricegroupId).opType === "multiply") currentPrice = currentActPrice?.hasOwnProperty("amount") ? (currentActPrice.amount * pricegroups.find((g) => g.id === proposal.pricegroupId).opValue) : 0;
             
            } else {
              currentPrice = currentActPrice?.hasOwnProperty("amount") ? currentActPrice.amount : 0;
            }
          } else {
            if (
              currentAct.priceranges?.find((i) => s.actPax <= i.max && s.actPax >= i.min)?.hasOwnProperty("id")
            ) {
              var rangeId = currentAct.priceranges.find((i) => s.actPax <= i.max && s.actPax >= i.min).id;
              if (priceDerived) {
                currentActPrice = currentAct.prices?.find((p) => p.groupId === currentPriceGroupId && p.rangeId === rangeId);
                    if (pricegroups.find((g) => g.id === proposal.pricegroupId).opType === "multiply") currentPrice = currentActPrice.amount * pricegroups.find((g) => g.id === proposal.pricegroupId).opValue;
              } else 
                  if (
                    currentAct.prices?.find((i) => i.rangeId === rangeId && i.groupId === proposal.pricegroupId)?.hasOwnProperty("amount")
                  ) {
                    currentPrice = currentAct.prices.find((i) => i.rangeId === rangeId && i.groupId === proposal.pricegroupId).amount;
                  }
            }
          }
        
        s.price = currentPrice;
        currentPrice = 0;
      });
      // ha csak 1 ár változott, akkor azt módosítjuk az arrayben, majd beállítjuk a selectedActs-re
      if (isSinglePrice === true) {
        tempSelectedActs[singleActIndex] = tempArray[0];
        console.log("tempSelectedActs", tempSelectedActs)
        setSelectedActs([...tempSelectedActs]);
      // ha minden árat újra tölöttünk, akkor az egész arrayt úrjaírjuk
      } else {
        setSelectedActs([...tempArray]);
      }
    }
  }

   function capFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

  async function downloadExcel() {
    var columns = [
      { header: capFirstLetter(words.nev[lang]), key: 'name' },
      { header: `${capFirstLetter(words.netto[lang])} ${getCurrency()? getCurrency() : ""}`, key: 'netPrice' }]
  
    vatTypes.map((v) => (
      columns = [...columns,
        { header: v.amount + "% " + words.afa[lang], key: v.amount.toString() }
      ]))
    columns = [...columns,    
      { header: `${capFirstLetter(words.brutto[lang])} ${getCurrency()? getCurrency() : ""}`, key: 'grossPrice' },
      { header: capFirstLetter(words.egyseg[lang]), key: 'unit' },
      { header: capFirstLetter(words.mennyiseg[lang]), key: 'pax' },
      { header: capFirstLetter(words.alkalom[lang]), key: 'multiplier' },
      { header: capFirstLetter(words.nettoosszeg[lang]), key: 'totalNet' },
      { header: capFirstLetter(words.bruttoosszeg[lang]), key: 'totalGross' },
    ];

    var data = [];
    
    selectedActs && selectedActs?.sort((a, b) => {
      if (acts.find((x) => x.id === a.actId)?.hasOwnProperty("orderId") & acts.find((x) => x.id === b.actId)?.hasOwnProperty("orderId")) {
                                  
        return (acts.find((x) => x.id === a.actId)?.orderId - acts.find((x) => x.id === b.actId)?.orderId);
      } else {
        return 0;
      }
    }).map((a, idx) => {
      if (a.actChecked | a.selected) {
        var newDataIndex = data.push({
          name: acts.find((x) => x.id === a.actId)?.names?.find((l) => l.id === lang)?.hasOwnProperty("name")
            ? acts.find((x) => x.id === a.actId).names.find((l) => l.id === lang).name
            : "",
          netPrice: proposal?.net ? a.price : 0,
          grossPrice: proposal?.net ? /* grossPrices?.find((n) => n.id === a.id)?.totalAmount */ 0 : a.price,
          unit: units?.find((u) => u.uId === acts.find((b) => b.id === a.actId)?.unitId)?.names[lang],
          pax: a.actPax !== 0 ? a.actPax : proposal.pax,
          multiplier: a.hasOwnProperty("actMultiplier") ? a.actMultiplier : 1
        
        }) - 1;
        vatTypes.map((v) => {
        /*   var vatValue = (vatShares.find((w) => w.id === a.id)?.share?.find((x) => x.id === v.id)?.share /100 ) * (v.amount / 100) * a.price */
         var vatShare = (vatShares.find((w) => w.id === a.id)?.share?.find((x) => x.id === v.id)?.share /100)
          data[newDataIndex].vatShares = {
            ...data[newDataIndex].vatShares,
            [v.amount] : vatShare ? vatShare : 0
        }
        })
      }
    })
    const workbook = new Excel.Workbook();
    const workSheetName = capFirstLetter(words.ajanlat[lang]);
  

    try {
      const fileName = (proposal.department ? (departments.find((d) => d.id === proposal.department)?.name + "_") : "") + words.ajanlat[lang] + (proposal.arrival ? "_"+timestampToDate(proposal.arrival) : ""); 

      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);
      
      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach((column) => {
        column.width = column.header.length + 5;
        if (column.key === "name") {
          column.aligment = { horizontal: 'left' }
        } else {
          column.alignment = { horizontal: 'right' };
        }
      });
      // a vatTypes dinamikus, ezért ki kell számolni, hanyadik oszlop az, ahova a képlet kerül

      // loop through data and add each one to worksheet
     
      data.forEach((singleData) => {
        var newRow = worksheet.addRow(singleData);
        // nettó összeg kiszámolása oszloponként
        newRow.getCell("totalNet").value = { formula: `${newRow.getCell("netPrice")._address}*${newRow.getCell("pax")._address}*${newRow.getCell("multiplier")._address}`}
     /*    worksheet.getCell(`I${rowCounter}`).value = { formula: `B${rowCounter}*H${rowCounter}` }; */
        // bruttó összeg kiszámolása oszloponként
        newRow.getCell("totalGross").value = { formula: `${newRow.getCell("grossPrice")._address}*${newRow.getCell("pax")._address}*${newRow.getCell("multiplier")._address}`}
       /*  worksheet.getCell(`J${rowCounter}`).value = { formula: `F${rowCounter}*H${rowCounter}` }; */
        
        // Nettó ár kiszámolása Excel képlettel, ha bruttó az ár
        if (!proposal.net) {
          var grossPriceAddress = `${newRow.getCell("grossPrice")._address}`;
          var calcForm = "";
          vatTypes.map((v) => {
            calcForm = calcForm + ("-" + `${newRow.getCell(v.amount.toString())._address}`)
          })
          var calcForm = grossPriceAddress + calcForm;
          newRow.getCell("netPrice").value = {formula:  calcForm}
        }
        
        
        //ÁFA kiszámolása Excel képlettel
        if (proposal.net) {
          vatTypes.map((v) => {
            const vatCell = newRow.getCell(v.amount.toString());
            var vatShare = singleData.vatShares[v.amount.toString()];
            vatCell.value = { formula: `${newRow.getCell("netPrice")._address}*${vatShare}*${v.amount / 100}` }
          })
        }

        if (!proposal.net) {
          vatTypes.map((v) => {
            const vatCell = newRow.getCell(v.amount.toString());
            var vatShare = singleData.vatShares[v.amount.toString()];
            vatCell.value = { formula: `(${newRow.getCell("grossPrice")._address}*${vatShare})-((${newRow.getCell("grossPrice")._address}*${vatShare})/${(v.amount / 100)+1})` }
          })
        }
        
        // Bruttó ár kiszámolása Excel képlettel, ha nettó az ár
        if (proposal.net) {
          var netPriceAddress = `${newRow.getCell("netPrice")._address}`;
          var calcForm = "";
          vatTypes.map((v) => {
            calcForm = calcForm + ("+" + `${newRow.getCell(v.amount.toString())._address}`)
          })
          var calcForm = netPriceAddress + calcForm;
          newRow.getCell("grossPrice").value = {formula:  calcForm}
        }

        
        
      });

      

      //nettó és bruttó végösszegek kiszámolása Excel képlettel
      const endRow = worksheet.lastRow._number + 1;
      worksheet.getRow(endRow).font = { bold: true };
      worksheet.getRow(endRow).getCell(1).value = capFirstLetter(words.osszesen[lang]);
      var netColStart = worksheet.getRow(2).getCell("totalNet")._address;
      var netColEnd = worksheet.getRow(endRow - 1).getCell("totalNet")._address
      var grossColStart = worksheet.getRow(2).getCell("totalGross")._address;
      var grossColEnd = worksheet.getRow(endRow - 1).getCell("totalGross")._address;
      worksheet.getRow(endRow).getCell("totalNet").value = { formula: `SUM(${netColStart}:${netColEnd})` };
      worksheet.getRow(endRow).getCell("totalGross").value = { formula: `SUM(${grossColStart}:${grossColEnd})` };

      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, (row) => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };

        });
      });

      
     

         /**
         * Autofit columns by width
         *
         * @param worksheet {ExcelJS.Worksheet}
         * @param minimalWidth
         */
        const autoWidth = (worksheet, minimalWidth = 10) => {
            worksheet.columns.forEach((column) => {
                let maxColumnLength = 0;
                column.eachCell({ includeEmpty: true }, (cell) => {
                    maxColumnLength = Math.max(
                        maxColumnLength,
                        minimalWidth,
                        cell.value ? cell.value.toString().length : 0
                    );
                });
                column.width = maxColumnLength + 2;
            });
        };

      autoWidth(worksheet);

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error('<<<ERRROR>>>', error);
      console.error('Something Went Wrong', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
 
  }

  function downloadPdf() {
    ReactPDF.renderToStream(<ProposalPdf />);
  }

  return (
    <div className="EditProposal">
      {!viewId.hasOwnProperty("id") | (viewId.id === "") ? (
        <AdminEditProposal
          statuses={statuses}
          proposal={proposal}
          clients={clients}
          currencies={currencies}
          selectedClient={selectedClient}
          selectedContact={selectedContact}
          actualContacts={actualContacts}
          setSelectedContact={setSelectedContact}
          timestampToDate={timestampToDate}
          pricegroups={pricegroups}
          loadPrices={loadPrices}
          acts={acts}
          selectedActs={selectedActs}
          units={units}
          vatShares={vatShares}
          netPrices={netPrices}
          grossPrices={grossPrices}
          getNetPrice={getNetPrice}
          getGrossPrice={getGrossPrice}
          totalNet={totalNet}
          totalGross={totalGross}
          setSelectedClient={setSelectedClient}
          setSelectedActs={setSelectedActs}
          setProposal={setProposal}
          pId={pId}
          lang={lang}
          db={db}
          getCurrency={getCurrency}
          selVarItems={selVarItems}
          setSelVarItems={setSelVarItems}
          displayTotalNet={displayTotalNet}
          displayTotalGross={displayTotalGross}
          handleChangeVarChecked={handleChangeVarChecked}
          handleChangeActChecked={handleChangeActChecked}
          departments={departments}
          setDepartments={setDepartments}
          downloadExcel={downloadExcel}
          downloadPdf={downloadPdf}
          selectedgroups={selectedgroups}
          setSelectedgroups={setSelectedgroups}
          priceTableRef={priceTableRef}
          tableCounter={tableCounter}
          resetTableCounter={resetTableCounter}
          formatCurrency={formatCurrency}
          imageInject={imageInject}
          startsWithVowel={startsWithVowel}
          images={images}
          conditions={conditions}
          setConditions={setConditions}
        />
      ) : (
          (viewId.hasOwnProperty("secondparam") && viewId.secondparam === "datasheet") ? 
       // Ha a második paraméter "datasheet" az URL-ben, akkor az adatlap komponenst tölti be,
      // ellenkező esetben pedig a sima ajánlat ügyfélnézetét
            <EditDataSheet
          statuses={statuses}
            proposal={proposal}
            clients={clients}
            currencies={currencies}
            selectedClient={selectedClient}
            selectedContact={selectedContact}
            actualContacts={actualContacts}
            setSelectedContact={setSelectedContact}
            timestampToDate={timestampToDate}
            pricegroups={pricegroups}
            loadPrices={loadPrices}
            acts={acts}
            selectedActs={selectedActs}
            setSelectedActs={setSelectedActs}
              units={units}
            />
            :
             <ViewProposal
            statuses={statuses}
            proposal={proposal}
            clients={clients}
            currencies={currencies}
            selectedClient={selectedClient}
            selectedContact={selectedContact}
            actualContacts={actualContacts}
            setSelectedContact={setSelectedContact}
            timestampToDate={timestampToDate}
            pricegroups={pricegroups}
            loadPrices={loadPrices}
            acts={acts}
            selectedActs={selectedActs}
            setSelectedActs={setSelectedActs}
            units={units}
            vatShares={vatShares}
            netPrices={netPrices}
            grossPrices={grossPrices}
            getNetPrice={getNetPrice}
            getGrossPrice={getGrossPrice}
            totalNet={totalNet}
            totalGross={totalGross}
            viewId={viewId}
            pId={pId}
            lang={lang}
            setLang={setLang}
            db={db}
            actgroups={actgroups}
            images={images}
            getCurrency={getCurrency}
            displayTotalNet={displayTotalNet}
            displayTotalGross={displayTotalGross}
            handleChangeVarChecked={handleChangeVarChecked}
            handleChangeActChecked={handleChangeActChecked}
            departments={departments}
            setDepartments={setDepartments}
            downloadExcel={downloadExcel}
            selectedgroups={selectedgroups}
            setSelectedgroups={setSelectedgroups}
            priceTableRef={priceTableRef}
            tableCounter={tableCounter}
            resetTableCounter={resetTableCounter}
            formatCurrency={formatCurrency}
            imageInject={imageInject}
            startsWithVowel={startsWithVowel}
            downloadPdf={downloadPdf}
            conditions={conditions}
            setConditions={setConditions}
            /> 
      )}
    </div>
  );
}

function ViewProposal(props) {
  const statuses = props.statuses;
  const proposal = props.proposal;
  const clients = props.clients;
  const currencies = props.currencies;
  const selectedClient = props.selectedClient;
  const acts = props.acts;
  const selectedActs = props.selectedActs;
  const units = props.units;
  const vatShares = props.vatShares;
  const netPrices = props.netPrices;
  const grossPrices = props.grossPrices;
  const getNetPrice = props.getNetPrice;
  const getGrossPrice = props.getGrossPrice;
  const images = props.images;
  const lang = props.lang;
  const setLang = props.setLang;
  const getCurrency = props.getCurrency;
  const displayTotalGross = props.displayTotalGross;
  const displayTotalNet = props.displayTotalNet;
  const handleChangeVarChecked = props.handleChangeVarChecked;
  const handleChangeActChecked = props.handleChangeActChecked;
  const downloadExcel = props.downloadExcel;
  const selectedgroups = props.selectedgroups;
  const priceTableRef = props.priceTableRef;
  var tableCounter = props.tableCounter;
  const resetTableCounter = props.resetTableCounter;
  const formatCurrency = props.formatCurrency;
  const imageInject = props.imageInject;
  const startsWithVowel = props.startsWithVowel;
  const downloadPdf = props.downloadPdf;
  const viewId = props.viewId;
  const conditions = props.conditions;
  const setConditions = props.setConditions;

  useEffect(() => {
    document.title = selectedClient[0]?.name ? (words.ajanlat[lang] + " " + (lang === "hu" ? (startsWithVowel(selectedClient[0]?.name) ? "az " : "a ") : "") + words.naknek[lang]
      + " " + selectedClient[0]?.name + " " + words.reszere[lang]) : "";
  }, [proposal, lang, selectedClient[0]]);

  // nyelv betöltése ajánlatból, ha van benne 
  useEffect(() => {
    (proposal?.hasOwnProperty("lang") && proposal?.lang) && setLang(proposal.lang);
  }, [proposal])


  return (
    <>
      
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="/">{words.ajanlat[lang]}</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="container-fluid">
              <NavDropdown className="cap" title={words.nyelv[lang]} id="lang">
                {langs.map((l) => (
                  <NavDropdown.Item href="#" active={lang === l.id ? true : false} key={l.id} onClick={(event) => setLang(l.id)}>
                    {l.name}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="clientView">
        {statuses && clients && proposal && currencies && selectedClient[0] && selectedClient[0].hasOwnProperty("name") && currencies.length > 0 && (
          <>
            {/*   *** HERO ***  */}
            <Row className="py-5 hero g-0">
              <Col className="d-flex align-items-center h1 justify-content-center hero-col">
                <Row>
                  {words.ajanlat[lang]} {lang === "hu" && (startsWithVowel(selectedClient[0].name) ? "az" : "a")} {words.naknek[lang]}
                </Row>
                <Row>{selectedClient[0].name}</Row>
                <Row> {words.reszere[lang]}</Row>
              </Col>
            </Row>
            {/*   *** /HERO ***  */}
            {/*   *** RÉSZLETEK ***  */}
             <Container className="programokContainer">
              {(proposal.content || proposal.hasDate || proposal.tentative) && (
                <>
                <Row className="py-4">
                    <Col>
                    <h2 className="cap">{words.ajanlatreszletei[lang]}</h2>
                    </Col>
                </Row>
                  {proposal.content && proposal.content[lang] &&
                    <Row className="clientViewContent">
                      <Col className="pb-4 content">
                        {proposal.content?.[lang]}
                      </Col>
                    </Row>}
                  
                      {(proposal.hasDate || proposal.tentative) && 
                    (
                    <Row className="justify-content-center">
                      <Col sm={6}>
                      <table>
                          <tbody>
                          {proposal.hasDate &&
                            <>
                              <tr>
                                <td className="cap text-start">
                                  {words.erkezesdatuma[lang]}
                                </td>
                                <td className="text-end">
                                  {new Date(proposal.arrival * 1000).toLocaleDateString("hu-HU")}
                                </td>
                            </tr>
                                  {((proposal.arrival !== proposal.departure) && proposal.departure) &&
                                          <tr>
                                            <td className="cap text-start">
                                              {words.tavozasdatuma[lang]}
                                            </td>
                                            <td className="text-end">
                                              {new Date(proposal.departure * 1000).toLocaleDateString("hu-HU")}
                                            </td>
                                          </tr>
                                      }
                            </>
                            }
                            {proposal.tentative &&
                              <tr>
                                  <td className="cap text-start">
                                  {words.opciodatuma[lang]}
                                </td>
                                <td className="text-end">
                                  {new Date(proposal.deadline * 1000).toLocaleDateString("hu-HU")}
                                </td>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                        )
                  }
                  
                </>
              )}
            {/*   *** /RÉSZLETEK ***  */}
              {/*   *** ÁRAJÁNLAT *** */}
              <Row className="py-4">
                <Col>
                  <h2 className="cap">{words.arajanlat[lang]}</h2>
                </Col>
              </Row>
              <table ref={priceTableRef}>
                <thead>
                  <tr>
                    <th><div className="cap">{words.nev[lang]}</div></th>
                    <th className="text-end">
                      <div className="cap">{words.netto[lang]}</div>
                      <div>{getCurrency()}</div>
                    </th>
                    <th className="text-end"><div className="cap">{words.brutto[lang]}</div>
                      <div>{getCurrency()}</div>
                    </th>
                    <th className="cap text-end">{words.egyseg[lang]}</th>
                    <th className="cap text-end">{words.mennyiseg[lang]}</th>
                    <th className="cap text-end">{words.alkalom[lang]}</th>
                    <th className="text-end">
                      <div className="cap">{words.nettoosszeg[lang]}</div>
                      <div>{getCurrency()}</div>
                    </th>
                    <th className="text-end">
                     <div className="cap">{words.bruttoosszeg[lang]}</div>
                      <div>{getCurrency()}</div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {selectedActs &&
                      selectedActs?.sort((a, b) => {
                        if (acts.find((x) => x.id === a.actId)?.hasOwnProperty("orderId") & acts.find((x) => x.id === b.actId)?.hasOwnProperty("orderId")) {
                                  
                                      return (acts.find((x) => x.id === a.actId)?.orderId - acts.find((x) => x.id === b.actId)?.orderId);
                                    } else {
                                      return 0;
                                    }
                                  }).map((a, idx) =>
                        !a.hasOwnProperty("parentId") && (
                          <>
                            <tr key={idx} className="align-middle">
                             <td data-label={words.nev[lang]} className="text-start">
                                             { /* checkbox */}
                                              {(!acts.find((x) => x.id === a.actId)?.hasOwnProperty("vargroup") | acts.find((x) => x.id === a.actId)?.vargroup === false) ? <input
                                                type="checkbox"
                                                id="actChecked"
                                                checked={a.actChecked || false}
                                                onChange={(event) => handleChangeActChecked(event, a.id)}
                                              /> : ""}
                                              
                                {/* név */}
                                {
                                acts
                                  .find((x) => x.id === a.actId)?.names?.find((l) => l.id === lang)?.hasOwnProperty("name")
                                  ? acts.find((x) => x.id === a.actId).names.find((l) => l.id === lang).name
                                  : ""}
                              </td>
                              {!acts.find((b) => b.id === a.actId)?.hasOwnProperty("vargroup") | (acts.find((b) => b.id === a.actId)?.vargroup === false) ? (
                                <>
                                  <td data-label={words.netto[lang]} className="text-end">
                                    {/* nettó ár */}
                                    {/* ÁFA kulcsonként hány százalék az ÁFA az adott tételben: (acts.find((b) => b.id === a.actId)).prices.vat.find((w) => w.id === 1).share} */}

                                    {proposal.net ? formatCurrency(a.price) : formatCurrency(netPrices.find((n) => n.id === a.id)?.totalAmount)}
                                  </td>
                                  <td data-label={words.brutto[lang]} className="text-end">
                                    {/* bruttó ár */}
                                    {proposal?.net ? formatCurrency(grossPrices?.find((n) => n.id === a.id)?.totalAmount) : formatCurrency(a.price)}
                                  </td>
                                  <td data-label={words.egyseg[lang]} className="text-end">
                                    {units?.find((u) => u.uId === acts.find((b) => b.id === a.actId)?.unitId)?.names[lang]}
                                   </td>
                                   {/* csak akkor írja ki a létszámot, az alkalmak számát és a nettó összeget, ha ki van jelölve */}
                                                {a.actChecked ?
                                                  <>
                                                    <td data-label={words.mennyiseg[lang]} className="text-end">{a.actPax !== 0 ? a.actPax : proposal.pax}</td>
                                                    <td data-label={words.alkalom[lang]} className="text-end">{a.hasOwnProperty("actMultiplier") ? a.actMultiplier : 1}</td>
                                                    <td data-label={words.nettoosszeg[lang]} className="text-end">
                                                      {formatCurrency(getNetPrice(a))}
                                                    </td>
                                                    <td data-label={words.bruttoosszeg[lang]} className="text-end">
                                                      {formatCurrency(getGrossPrice(a))}
                                                    </td>
                                                  </>
                                                  :
                                                  <td colSpan={7}></td>}          
                                </>
                              ) : (
                                <>
                                  <td colSpan={7}></td>
                                </>
                              )}
                            </tr>
                            {/* Ha egy variációcsoportról van szó, akkor listázza ki a hozzá tartozó tételeket} */}
                            {acts.find((b) => b.id === a.actId)?.hasOwnProperty("vargroup") && acts.find((b) => b.id === a.actId)?.vargroup === true && (
                              <>
                                {selectedActs?.sort((a, b) => {
                                    if (acts.find((x) => x.id === a.actId)?.hasOwnProperty("orderId") & acts.find((x) => x.id === b.actId)?.hasOwnProperty("orderId")) {
                                  
                                      return (acts.find((x) => x.id === a.actId)?.orderId - acts.find((x) => x.id === b.actId)?.orderId);
                                    } else {
                                      return 0;
                                    }
                                  }).map((c, j) => {
                                   
                                    if (c.parentId === a.id)
                                      return (
                                        <>
                                          <tr key={j} className="align-middle clientViewVariation">
                                            <td
                                              data-label="Név"
                                              className="text-start"
                                               style={{
                                                opacity: selectedActs.find((x) => {
                                                  return x.actId === c.actId && x.parentId === a.id;
                                                })?.selected
                                                  ? 1
                                                  : 0.7
                                              }}
                                              
                                            >
                                              {/* checkbox */}
                                              <input
                                                type="checkbox"
                                                checked={
                                                  selectedActs.find((x) => {
                                                    return x.actId === c.actId && x.parentId === a.id;
                                                  })?.selected || false
                                                }
                                                onChange={(event) => handleChangeVarChecked(event, c.actId, a.id)}
                                              />
                                              {/* név */}
                                              {acts
                                                .find((x) => x.id === c.actId)
                                                ?.names?.find((l) => l.id === lang)
                                                ?.hasOwnProperty("name")
                                                ? acts.find((x) => x.id === c.actId).names.find((l) => l.id === lang).name
                                                : ""}
                                            </td>
                                            <td data-label="Nettó ár" className="text-end"  style={{
                                                opacity: selectedActs.find((x) => {
                                                  return x.actId === c.actId && x.parentId === a.id;
                                                })?.selected
                                                  ? 1
                                                  : 0.7
                                              }}>
                                              {/* nettó ár */}
                                              {/* ÁFA kulcsonként hány százalék az ÁFA az adott tételben: (acts.find((b) => b.id === a.actId)).prices.vat.find((w) => w.id === 1).share} */}

                                              {proposal.net ? formatCurrency(c.price) : formatCurrency(netPrices.find((n) => n.id === c.id)?.totalAmount)}
                                            </td>
                                            <td data-label="Bruttó ár" className="text-end"  style={{
                                                opacity: selectedActs.find((x) => {
                                                  return x.actId === c.actId && x.parentId === a.id;
                                                })?.selected
                                                  ? 1
                                                  : 0.7
                                              }}>
                                              {/* bruttó ár */}

                                              {proposal?.net ? formatCurrency(grossPrices?.find((n) => n.id === c.id)?.totalAmount) : formatCurrency(c.price)}
                                            </td>
                                            <td data-label="Egység" className="text-end"  style={{
                                                opacity: selectedActs.find((x) => {
                                                  return x.actId === c.actId && x.parentId === a.id;
                                                })?.selected
                                                  ? 1
                                                  : 0.7
                                              }}>
                                              {units?.find((u) => u.uId === acts.find((b) => b.id === c.actId)?.unitId)?.names[lang]}
                                            </td>
                                            {selectedActs.find((x) => {
                                              return x.actId === c.actId && x.parentId === a.id;
                                            })?.selected ? (
                                              <>
                                                <td data-label="Mennyiség" className="text-end">{c.actPax !== 0 ? c.actPax : proposal.pax}</td>
                                                <td data-label="Alkalom" className="text-end">{c.hasOwnProperty("actMultiplier") ? c.actMultiplier : 1}</td>
                                                <td data-label="Nettó összeg" className="text-end">
                                                  {formatCurrency(getNetPrice(c))}
                                                </td>
                                                <td data-label="Bruttó összeg" className="text-end">
                                                  {formatCurrency(getGrossPrice(c))}
                                                </td>
                                              </>
                                            ) : (
                                              <td colSpan="7"></td>
                                            )}
                                          </tr>
                                        </>
                                      );
                                  })}
                              </>
                            )}
                          </>
                        )
                    )}
                  </tbody>
                  <tfoot>
                  <tr>
                    <td className="text-start cap">
                     {words.osszesen[lang]}
                    </td>
                    <td colSpan={5}></td>
                    <td className="text-end">{formatCurrency(displayTotalNet())}</td>
                    <td className="text-end">{formatCurrency(displayTotalGross())}</td>
                  </tr>
                </tfoot>
                
              </table>
              <Row>
                <Col className="d-flex justify-content-end">
                  <button onClick={downloadExcel}>{words.letoltesxls[lang]}</button>
                </Col>
              </Row>
            </Container>
            {/*   **** /ÁRAJÁNLAT *** */}
            {/*   *** PROGRAMOK ***  */}
            <Container className="programokContainer">
              {selectedgroups &&
                images &&
                selectedgroups.map((g, idx) => {
                  return (
                    <>
                      <Row key={idx} className="clientViewContent">
                        <Col>
                          <Row>
                            <Col sm={12} className="pb-3 pt-5 text-start">
                              <h2>
                                {g.hasOwnProperty("names") && g.names.find((l) => l.id === lang) && g.names.find((l) => l.id === lang).hasOwnProperty("name")
                                  ? g.names.find((l) => l.id === lang).name
                                  : ""}
                              </h2>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <Row>
                                <Col className="pb-3 text-start content">
                                  {g.hasOwnProperty("descs") && g.descs.find((l) => l.id === lang) && g.descs.find((l) => l.id === lang).hasOwnProperty("desc")
                                    ? imageInject(g.descs.find((l) => l.id === lang).desc, g.images)
                                    : ""}
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  {g.hasOwnProperty("activities") &&
                                    acts
                                      ?.sort((a, b) => {
                                        if (a.hasOwnProperty("orderId") & b.hasOwnProperty("orderId")) {
                                          return a.orderId - b.orderId;
                                        } else {
                                          return 0;
                                        }
                                      })
                                    .map((a, idx) => {
                                        
                                        /* Ha benne van a kiválasztott programok között, csak akkor listázza */
                                      /* 2022.12.28. módosítás: + egy feltétel, hogy ne egy programcsoporthoz tartozzon */
                                   
                                        if (g.activities.find((y) => y === a.id) && selectedActs.find((x) => x.actId === a.id) && !selectedActs.find((x) => x.actId === a.id).hasOwnProperty("parentId"))
                                          return (
                                            <>
                                              {/*  Ha van leírása egy programnak, csak akkor listázza itt ki, VAGY HA nincs leírása, DE variációcsoport */}
                                              {((a.descs?.find((l) => l.id === lang)?.hasOwnProperty("desc") && a.descs?.find((l) => l.id === lang)?.desc !== "") | (a.hasOwnProperty("vargroup") && a.vargroup === true)) ? (
                                                <>
                                                  <Row key={idx} className="pt-4">
                                                    <Col className="text-start">
                                                      <h3>{a.names?.find((l) => l.id === lang)?.hasOwnProperty("name") ? a.names?.find((l) => l.id === lang)?.name : ""}</h3>
                                                      {(a.duration && a.duration !== 0) && <h5 className="cap">{words.idotartam[lang]}: {words.kb[lang]} {a.duration} {(a.duration === 1) ? words.perc[lang] : words.percek[lang]}</h5>}
                                                    </Col>
                                                  </Row>
                                                  <Row className="pb-3">
                                                    <Col
                                                      className="text-start"
                                                      style={{
                                                        whiteSpace: "pre-wrap"
                                                      }}
                                                    >
                                                      {a.descs?.find((l) => l.id === lang)?.hasOwnProperty("desc") ? imageInject(a.descs.find((l) => l.id === lang).desc, a.images) : ""}
                                                    </Col>
                                                  </Row>

                                                  {/* *** VARIÁCIÓCSOPORT PROGRAMOK *** */}
                                                  <Row>
                                                    <Col>
                                                      {a.hasOwnProperty("vargroup") && a.vargroup === true && (
                                                        <>
                                                          {a.hasOwnProperty("activities") &&
                                                            acts &&
                                                            acts
                                                              .sort((a, b) => {
                                                                if (a.hasOwnProperty("orderId") & b.hasOwnProperty("orderId")) {
                                                                  return a.orderId - b.orderId;
                                                                } else {
                                                                  return 0;
                                                                }
                                                              })
                                                              .map((c, j) => {
                                                                if (
                                                                  a.activities.find((z) => z === c.id) &&
                                                                  c.descs?.find((l) => l.id === lang)?.hasOwnProperty("desc") &&
                                                                  c.descs?.find((l) => l.id === lang)?.desc !== ""
                                                                )
                                                                  return (
                                                                    <div key={c.id}>
                                                                      <Row className="pb-2 h5">
                                                                        <Col className="text-start">
                                                                          {c.names?.find((l) => l.id === lang)?.hasOwnProperty("name") ? c.names?.find((l) => l.id === lang)?.name : ""}
                                                                        </Col>
                                                                      </Row>
                                                                      <Row key={j} className="pb-4">
                                                                        <Col
                                                                          className="text-start content"
                                                                        >
                                                                          {c.descs?.find((l) => l.id === lang)?.hasOwnProperty("desc") ? imageInject(c.descs?.find((l) => l.id === lang)?.desc) : ""}
                                                                        </Col>
                                                                      </Row>
                                                                    </div>
                                                                  );
                                                              })}
                                                        </>
                                                      )}
                                                    </Col>
                                                  </Row>

                                                  {/* *** /VARIÁCIÓCSOPORT PROGRAMOK *** */}
                                                </>
                                              ) : ""}
                                            </>
                                          );
                                      })}
                                </Col>
                              </Row>
                            </Col>
                            {/* *** ÁRLISTA *** */}
                            <Row className="g-0">
                              <Col>
                                <table>
                                  <tbody>
                                  
                                  {acts
                                    ?.sort((a, b) => {
                                      if (a.hasOwnProperty("orderId") & b.hasOwnProperty("orderId")) {
                                        return a.orderId - b.orderId;
                                      } else {
                                        return 0;
                                      }
                                    })
                                      .map((a, idx) => {
                                      /* Ha benne van a kiválasztott programok között, csak akkor listázza */
                                        if (g.activities.find((y) => y === a.id) && selectedActs.find((x) => x.actId === a.id) && (!a.hasOwnProperty("vargroup") || a.vargroup === "false")) {
                                      
                                          tableCounter++;
                                          // ez true, ha a termék 0%-os ÁFA tartalma 100% - ebben az esetben nincs ÁFA-ja, tehát nem kell majd kiírni, hogy (+ ÁFA)
                                          var actContainsNoVat = (a?.vat?.find((x) => x.id === 0)?.share === 100) ? true : false;
                                          return (
                                            <>
                                              {/*  Ha nincs leírása egy programnak, akkor csak oldalra simán listázza */}
                                              {/* 2022.12.21. módosítás: minden programot írjon ki árral */}
                                              {tableCounter === 1 &&
                                                
                                                <tr className="priceTableHeader">
                                                  <td className="cap text-start">{words.nev[lang]}</td>
                                                  <td className="cap text-end">{words.ar[lang]}</td>
                                                </tr>
                                              }
                                              <tr key={idx}>
                                                <td className="text-start">{a.names?.find((l) => l.id === lang)?.hasOwnProperty("name") ? a.names?.find((l) => l.id === lang)?.name : ""}</td>
                                                <td className="text-end">
                                                 
                                                  {selectedActs.find((x) => x.actId === a.id)?.hasOwnProperty("price") &&
                                                    formatCurrency(selectedActs.find((x) => x.actId === a.id).price)}
                                                  
                                                  
                                                  {proposal.net && !actContainsNoVat && " +" + words.afa[lang]}
                                                  {units.find((u) => u.uId === a.unitId)?.names[lang] && "/" + units.find((u) => u.uId === a.unitId)?.names[lang]}
                                                </td>
                                              </tr>
                                              
                                            </>
                                         
                                          )
                                        };
                                      })
                                    }
                                    </tbody>
                                </table>
                                {resetTableCounter()}
                              </Col>
                            </Row>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  );
                })}
              
                  
                  {/* *** LEMONDÁSI FELTÉTELEK *** */}

                  {proposal.condition && proposal.condition !== "none" &&
                    <>
                    <Row className="py-4">
                      <Col>
                      <h2 className="cap">{words.lemondasifoglalasifeltetelek[lang]}</h2>
                    </Col>
                    </Row>
                    <Row className="clientViewContent">
                      <Col className="pb-4 content">
                        {imageInject(conditions?.find((cond) => proposal.condition === cond.id).descs?.find((l) => l.id === lang)?.desc)}
                      </Col>
                    </Row>
                    </>
                  }
              {/* *** /LEMONDÁSI FELTÉTELEK *** */}
              
            </Container>
          </>
        )}
      </div>
    </>
  );
}

function AdminEditProposal(props) {
  const statuses = props.statuses;
  const proposal = props.proposal;
  const clients = props.clients;
  const currencies = props.currencies;
  const selectedClient = props.selectedClient;
  const selectedContact = props.selectedContact;
  const actualContacts = props.actualContacts;
  const setSelectedContact = props.setSelectedContact;
  const timestampToDate = props.timestampToDate;
  const pricegroups = props.pricegroups;
  const loadPrices = props.loadPrices;
  const acts = props.acts;
  const selectedActs = props.selectedActs;
  const units = props.units;
  const vatShares = props.vatShares;
  const netPrices = props.netPrices;
  const grossPrices = props.grossPrices;
  const getNetPrice = props.getNetPrice;
  const getGrossPrice = props.getGrossPrice;
  var totalNet = props.totalNet;
  var totalGross = props.totalGross;
  const pId = props.pId;
  const setSelectedClient = props.setSelectedClient;
  const setSelectedActs = props.setSelectedActs;
  const setProposal = props.setProposal;
  const lang = props.lang;
  const getCurrency = props.getCurrency;
  const selVarItems = props.selVarItems;
  const setSelVarItems = props.setSelVarItems;
  const displayTotalGross = props.displayTotalGross;
  const displayTotalNet = props.displayTotalNet;
  const handleChangeVarChecked = props.handleChangeVarChecked;
  const handleChangeActChecked = props.handleChangeActChecked;
  const departments = props.departments;
  const setDepartments = props.setDepartments;
  const downloadExcel = props.downloadExcel;
  const downloadPdf = props.downloadPdf;
  const viewId = props.viewId;
  const actgroups = props.actgroups;
  const images = props.images;
  const selectedgroups = props.selectedgroups;
  const setSelectedgroups = props.setSelectedgroups;
  const priceTableRef = props.priceTableRef;
  const tableCounter = props.tableCounter;
  const resetTableCounter = props.resetTableCounter;
  const formatCurrency = props.formatCurrency;
  const imageInject = props.imageInject;
  const startsWithVowel = props.startsWithVowel;
  const conditions = props.conditions;
  const setConditions = props.setConditions;

  /* A "Program hozzáadása" modal ablak változói */

  const [showAddAct, setShowAddAct] = useState(false);
  const handleCloseAddAct = () => setShowAddAct(false);
  const handleShowAddAct = () => setShowAddAct(true);
   
  /*  Az "Egyedi tétel" modal ablak változói */

  const [showAddCustomAct, setShowAddCustomAct] = useState({ openModal: false, customActId: null });
  const handleCloseAddCustomAct = () => setShowAddCustomAct({ ...showAddCustomAct, openModal: false });
  const handleShowAddCustomAct = (event, customActId) => setShowAddCustomAct({ ...showAddCustomAct, openModal: true, customActId: customActId });

  var [listActsAdd, setListActsAdd] = useState([]);
  

  function handleUpdateProposal(event) {
    event.preventDefault();
    const docRef = doc(db, "proposals", pId);
    console.log("proposal", proposal)
    console.log("selectedActs", selectedActs)
    updateDoc(docRef, {
      ...proposal,
      acts: selectedActs,
      ...(selectedClient[0] && selectedClient[0].hasOwnProperty("id") && { clientId: selectedClient[0].id }),
      ...(selectedContact[0] && selectedContact[0].hasOwnProperty("id") && { contactId: selectedContact[0].id })
    })
      .then((docRef) => {
        console.log("Document field updated");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleChangeClient(selected) {
    setSelectedClient(selected);
    setSelectedContact([]);
  }


  function handleChangeAddModal(event) {
    var targetId = event.target.id;
    var tempActId = event.target.value;
    var tempArray = [];
    if (targetId === "actAdd") tempArray = listActsAdd;
    if (tempArray.indexOf(tempActId) === -1 && event.target.checked) {
      tempArray.push(tempActId);
    } else {
      tempArray = tempArray.filter((e) => e !== tempActId);
    }
    if (targetId === "actAdd") setListActsAdd([...tempArray]);
  }

  function handleDeleteAct(event, id) {
    event.preventDefault();
    if (window.confirm("Biztosan törlöd?")) {
      var tempArray = [...selectedActs];
      if (acts.find((y) => y.id === tempArray.find((x) => x.id === id)?.actId)?.hasOwnProperty("vargroup") && acts.find((y) => y.id === tempArray.find((x) => x.id === id)?.actId)?.vargroup === true) {
        tempArray = tempArray.filter((x) => x.parentId !== id);
      }
      tempArray = tempArray.filter((x) => x.id !== id);

      setSelectedActs(tempArray);
    }
  }

  function handleAddSelectedAct(event) {
    event.preventDefault();
    const selectedActIds = [];
    const selectedVarIds = [];
    var newSelectedActId = 0;
    var newSelectedVarId = 0;
    var tempArray = selectedActs;
    listActsAdd.map((l) => {
      if (selectedActs && selectedActs.length > 0) {
        selectedActs.forEach((c) => selectedActIds.push(c.id));
        newSelectedActId = Math.max(...selectedActIds) + 1;
      }
     
      tempArray.push({
        actId: l,
        id: newSelectedActId,
        actPax: (acts.find((x) => x.id === l)?.hasOwnProperty("basedOnCount") && acts.find((x) => x.id === l).basedOnCount === false) ? 1 : (proposal.pax? proposal.pax : 0),
        price: 0,
       /*  orderId: acts.find((x) => x.id === l)?.orderId - ne tegye bele, mert ha utána változik az orderId az eredeti programnál, akkor összevissza fog listázni */
      });
  
      // Ha vargroup, akkor az ahhoz tartozókat is hozzáadja

      if (acts.find((x) => x.id === l)?.hasOwnProperty("vargroup") && acts.find((x) => x.id === l)?.vargroup === true) {
        acts
          .find((x) => x.id === l)
          .activities.map((t) => {
            if (selectedActs && selectedActs.length > 0) {
              selectedActs.forEach((c) => selectedVarIds.push(c.id));
              newSelectedVarId = Math.max(...selectedVarIds) + 1;
            }
            tempArray.push({
              actId: t,
              id: newSelectedVarId,
              actPax: (acts.find((x) => x.id === t)?.hasOwnProperty("basedOnCount") && acts.find((x) => x.id === t).basedOnCount === false) ? 1 : (proposal.pax? proposal.pax : 0),
              price: 0,
              parentId: newSelectedActId,
              selected: false,
          /*     orderId: acts.find((x) => x.id === t)?.orderId - ne tegye bele, mert ha utána változik az orderId az eredeti programnál, akkor összevissza fog listázni **/
            });
          });
      }
    });

    setSelectedActs([...tempArray]);
    setListActsAdd([]);
  }

  function handleAddSelectedCustomAct(event) {
    console.log("selectedActs", selectedActs)
  }

  function handleChangeField(event, aId, lang) {
    var newValue = event.target.value;
    var targetId = event.target.id;

    if ((targetId === "content")) {
      setProposal({ ...proposal, [targetId]: { ...proposal[targetId], [lang]: newValue } });
    }

    if ((targetId === "pricegroupId") | (targetId === "notes") | (targetId === "title") | (targetId === "department") | (targetId === "lang") | (targetId === "condition")) {
      setProposal({ ...proposal, [targetId]: newValue });
    }
    if ((targetId === "arrival") | (targetId === "departure") | (targetId === "deadline")) {
      newValue = Date.parse(newValue) / 1000;
      setProposal({ ...proposal, [targetId]: newValue });
    }
    if ((targetId === "tentative") | (targetId === "billed") | (targetId === "hasDate")) {
      newValue = event.target.checked;
      setProposal({ ...proposal, [targetId]: newValue });
    }

    if ((targetId === "pax") | (targetId === "currencyId") | (targetId === "hostwareId") | (targetId === "statusId")){
      // átalakítja stringből integerré
      newValue = +newValue;
      setProposal({ ...proposal, [targetId]: newValue });
    }

    if (targetId === "net") {
      var isTrueSet = newValue === "true";
      setProposal({ ...proposal, [targetId]: isTrueSet });
    }

    if ((targetId === "actPax") | (targetId === "price") | (targetId === "actMultiplier")) {
      newValue = +newValue;
      var tempArray = [...selectedActs];
      var tempObjIndex = tempArray.findIndex((x) => x.id === aId);
      tempArray[tempObjIndex][targetId] = newValue;
      setSelectedActs([...tempArray]);
    }

    /*     if ((targetId === "varPax") | (targetId === "varPrice")) {
      newValue = +newValue;
      var tempVarArray = [...selectedActs];
      var actualIndex = tempVarArray.findIndex((x) => x.id === aId);
      var actualAct = tempVarArray[actualIndex];
      if (!actualAct.selVars) {
        actualAct = { ...actualAct, selVars: [] };
      }
      if (actualAct.selVars?.find((x) => x.varId === varId)) {
        var actualVarIndex = actualAct.selVars?.findIndex((x) => x.varId === varId);
        if (targetId === "varPax") {
          tempVarArray[actualIndex].selVars[actualVarIndex].actPax = newValue;
        }
        if (targetId === "varPrice") {
          tempVarArray[actualIndex].selVars[actualVarIndex].price = newValue;
        }
      } 
      setSelectedActs([...tempVarArray]);
    } */
  }

  function handleOpenPreview(event) {
    event.preventDefault();
    window.open("/proposal/" + pId, "_blank", "noopener,noreferrer");
  }

  function handleCopyUrl(event) {
    event.preventDefault();
    navigator.clipboard.writeText("https://" + window.location.hostname + "/proposal/" + pId);
  }

  function formatCurrencyAdmin(int) {
    var currDisplay = "name";
    var currStyle = "decimal";
    var actualCurrency = getCurrency();
    if (lang === "hu") {
      return Intl.NumberFormat("hu-HU", { maximumFractionDigits: 2, style: currStyle, currencyDisplay: currDisplay, currency: actualCurrency }).format(int);
   
    } else {
      return int;
    }
  }

  const generatePdfDocument = async (fileName) => {
        const blob = await pdf((
            <ProposalPdf
                      statuses={statuses}
                      proposal={proposal}
                      clients={clients}
                      currencies={currencies}
                      selectedClient={selectedClient}
                      selectedContact={selectedContact}
                      actualContacts={actualContacts}
                      setSelectedContact={setSelectedContact}
                      timestampToDate={timestampToDate}
                      pricegroups={pricegroups}
                      loadPrices={loadPrices}
                      acts={acts}
                      selectedActs={selectedActs}
                      setSelectedActs={setSelectedActs}
                      units={units}
                      vatShares={vatShares}
                      netPrices={netPrices}
                      grossPrices={grossPrices}
                      getNetPrice={getNetPrice}
                      getGrossPrice={getGrossPrice}
                      totalNet={totalNet}
                      totalGross={totalGross}
                      viewId={viewId}
                      pId={pId}
                      lang={lang}
                      db={db}
                      actgroups={actgroups}
                      images={images}
                      getCurrency={getCurrency}
                      displayTotalNet={displayTotalNet}
                      displayTotalGross={displayTotalGross}
                      handleChangeVarChecked={handleChangeVarChecked}
                      handleChangeActChecked={handleChangeActChecked}
                      departments={departments}
                      setDepartments={setDepartments}
                      downloadExcel={downloadExcel}
                      selectedgroups={selectedgroups}
                      setSelectedgroups={setSelectedgroups}
                      priceTableRef={priceTableRef}
                      tableCounter={tableCounter}
                      resetTableCounter={resetTableCounter}
                      formatCurrency={formatCurrency}
                      imageInject={imageInject}
                      startsWithVowel={startsWithVowel}
                    />
        )).toBlob();
        saveAs(blob, "teszt.pdf");
  };
  
  return (
    <>
      <Container>
        {statuses && clients && proposal && currencies && currencies.length > 0 && conditions && (
          <>
            <Form>
              <Row className="pb-4 pt-4">
                <Col sm={5}>
                  <Form.Group as={Row} controlId="formHorizontalEmail">
                    <Form.Label column sm={2}>
                      Ügyfél cégneve
                    </Form.Label>
                    <Col sm={6}>
                      <Typeahead
                        id="client-name"
                        labelKey="name"
                        onChange={(selected) => handleChangeClient(selected)}
                        options={clients}
                        placeholder="Válassz ügyfél céget.. ."
                        selected={selectedClient}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-4">
                    <Form.Label column sm={2}>
                      Ügyintéző neve
                    </Form.Label>
                    <Col sm={6}>
                      <Typeahead id="contact-name" labelKey="name" onChange={setSelectedContact} options={actualContacts? actualContacts : []} placeholder="Válassz ügyintézőt..." selected={selectedContact? selectedContact : ""} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-4">
                    <Form.Label column sm={2}>
                      Ajánlat címe
                    </Form.Label>
                    <Col sm={6}>
                      <Form.Control
                        id="title"
                        value={proposal.title? proposal.title : ""}
                        onChange={handleChangeField}
                      />
                    </Col>
                  </Form.Group>
                    <Form.Group as={Row} className="mb-4">
                    <Form.Label column sm={2}>
                      Konkrét dátum van?
                    </Form.Label>
                    <Col sm={6} className="d-flex justify-content-start align-items-center">
                      <Form.Check type="checkbox" id="hasDate" onChange={handleChangeField} checked={proposal.hasDate} />
                 {/*      {proposal.hasDate && <Form.Control className="ms-4" onChange={handleChangeField} type="date" id="deadline" value={timestampToDate(proposal.deadline)}></Form.Control>} */}
                    </Col>
                  </Form.Group>
                  {proposal.hasDate && (
                    <>
                      <Form.Group as={Row} className="mb-4" controlId="arrival">
                        <Form.Label column sm={2}>
                          Érkezés dátuma
                        </Form.Label>
                        <Col sm={6}>
                          <Form.Control onChange={handleChangeField} type="date" value={timestampToDate(proposal.arrival)}></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-4" controlId="departure">
                        <Form.Label column sm={2}>
                          Távozás dátuma
                        </Form.Label>
                        <Col sm={6}>
                          <Form.Control onChange={handleChangeField} type="date" value={timestampToDate(proposal.departure)}></Form.Control>
                        </Col>
                      </Form.Group>
                    </>)
                  }
                  <Form.Group as={Row} className="mb-4">
                    <Form.Label column sm={2}>
                      Deviza
                    </Form.Label>
                    <Col sm={6} className="d-flex justify-content-start align-items-center">
                      <Form.Select id="currencyId" aria-label="Currency" onChange={handleChangeField} value={proposal.hasOwnProperty("currencyId") ? proposal.currencyId : "label"}>
                        <option key="default" value="label" disabled>
                          Válassz...
                        </option>
                        {currencies.map((l) => (
                          <option key={l.cid} value={l.cId}>
                            {l.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-4">
                    <Form.Label column sm={2}>
                      ÁFA
                    </Form.Label>
                    <Col sm={6} className="d-flex justify-content-start align-items-center">
                      <Form.Select aria-label="Net" id="net" onChange={handleChangeField} value={proposal.hasOwnProperty("net") ? (proposal.net ? true : false) : "label"}>
                        <option key="default" value="label" disabled>
                          Válassz...
                        </option>
                        <option key="net" value={true}>
                          Nettó
                        </option>
                        <option key="gross" value={false}>
                          Bruttó
                        </option>
                      </Form.Select>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-4">
                    <Form.Label column sm={2}>
                      Árcsoport
                    </Form.Label>
                    <Col sm={6} className="d-flex justify-content-start align-items-center">
                      <Form.Select id="pricegroupId" aria-label="Pricegroup" onChange={handleChangeField} value={proposal.hasOwnProperty("pricegroupId") ? proposal.pricegroupId : "label"}>
                        <option key="default" value="label" disabled>
                          Válassz...
                        </option>
                        {pricegroups.map((p) => (
                          <option key={p.id} value={p.id}>
                            {p.hasOwnProperty("names") && p.names.find((l) => l.id === lang) && p.names.find((l) => l.id === lang).hasOwnProperty("name")
                              ? p.names.find((l) => l.id === lang).name
                              : ""}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col className="text-start">
                      <Button onClick={loadPrices}>Árak betöltése</Button>
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm={5}>
                  <Form.Group as={Row} className="mb-4">
                    <Form.Label column sm={2}>
                      Részleg
                    </Form.Label>
                    <Col sm={6} className="d-flex justify-content-start align-items-center">
                      <Form.Select id="department" aria-label="Department" onChange={handleChangeField} value={proposal.hasOwnProperty("department") ? proposal.department : "label"}>
                        <option key="default" value="label" disabled>
                          Válassz...
                        </option>
                        {departments.map((d) => (
                          <option key={d.id} value={d.id}>
                            {d.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-4">
                    <Form.Label column sm={2}>
                      Létszám
                    </Form.Label>
                    <Col sm={6}>
                      <Form.Control type="number" id="pax" value={proposal.pax} onChange={(event) => handleChangeField(event)}></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-4">
                    <Form.Label column sm={2}>
                      HostWare ID
                    </Form.Label>
                    <Col sm={6}>
                      <Form.Control type="number" id="hostwareId" value={proposal.hostwareId} onChange={(event) => handleChangeField(event)}></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-4">
                    <Form.Label column sm={2}>
                      Státusz
                    </Form.Label>
                    <Col sm={6} className="d-flex justify-content-start align-items-center">
                      <Form.Select id="statusId" aria-label="Status" onChange={handleChangeField} value={proposal.hasOwnProperty("statusId") ? proposal.statusId : "label"}>
                        <option key="default" value="label" disabled>
                          Válassz...
                        </option>
                        {statuses.map((p) => (
                          <option key={p.sId} value={p.sId}>
                            {p.hasOwnProperty("names") && p.names.find((l) => l.id === lang) && p.names.find((l) => l.id === lang).hasOwnProperty("name")
                              ? p.names.find((l) => l.id === lang).name
                              : ""}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-4">
                    <Form.Label column sm={2}>
                      Számlázva
                    </Form.Label>
                    <Col sm={6} className="d-flex justify-content-start align-items-center">
                      <Form.Check type="checkbox" id="billed" onChange={handleChangeField} checked={proposal.billed} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-4">
                    <Form.Label column sm={2}>
                      Opciós
                    </Form.Label>
                    <Col sm={6} className="d-flex justify-content-start align-items-center">
                      <Form.Check type="checkbox" id="tentative" onChange={handleChangeField} checked={proposal.tentative} />
                      {proposal.tentative && <Form.Control className="ms-4" onChange={handleChangeField} type="date" id="deadline" value={timestampToDate(proposal.deadline)}></Form.Control>}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-4">
                    <Form.Label column sm={2}>
                      Ajánlat nyelve
                    </Form.Label>
                    <Col sm={6} className="d-flex justify-content-start align-items-center">
                      <Form.Select id="lang" aria-label="Status" onChange={handleChangeField} value={proposal.hasOwnProperty("lang") ? proposal.lang : "label"}>
                        <option key="default" value="label" disabled>
                          Válassz...
                        </option>
                        {langs.map((l) => (
                          <option key={l.id} value={l.id}>
                            {l.hasOwnProperty("name") ? l.name : ""}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-4">
                    <Form.Label column sm={2}>
                      Lemondási feltételek
                    </Form.Label>
                    <Col sm={6} className="d-flex justify-content-start align-items-center">
                      <Form.Select id="condition" aria-label="Status" onChange={handleChangeField} value={proposal.hasOwnProperty("condition") ? proposal.condition : "label"}>
                        <option key="default" value="none">
                          Nincs
                        </option>
                        {conditions.map((c) => (
                          <option key={c.id} value={c.id}>
                            {c.hasOwnProperty("names") && c.names.find((l) => l.id === lang) && c.names.find((l) => l.id === lang).hasOwnProperty("name")
                              ? c.names.find((l) => l.id === lang).name
                              : ""}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>

                   <Form.Group as={Row} className="mb-4">
                    <Form.Label row="true" className="text-start">
                      Megjegyzések
                    </Form.Label>
                    <Col sm={12} className="d-flex justify-content-start align-items-center">
                      <Form.Control
                        as="textarea"
                        id="notes"
                          rows={6}
                          value={(proposal.hasOwnProperty("notes") && proposal.notes)
                              ? proposal.notes
                              : ""
                          }
                          onChange={handleChangeField}
                        />
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm={2}>
                  <Row className="pb-4">
                    <Col>
                      <Button id="saveButton" onClick={handleUpdateProposal} variant="primary" type="submit">
                        Mentés
                      </Button>
                    </Col>
                  </Row>
                  <Row className="pb-4">
                    <Col>
                      <Button id="clientPreviewButton" onClick={handleOpenPreview} variant="primary" type="submit">
                        Ügyfélnézet
                      </Button>
                    </Col>
                  </Row>
                  <Row className="pb-4">
                    <Col>
                      <Button id="clientURLButton" onClick={handleCopyUrl} variant="primary" type="submit">
                        Ügyfél link másolása
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </>
        )}
        <Row>
          <Col sm={12}>
              {langs.map((l) => 
                    <>
                  <Form.Group as={Row} className="mb-4">
                    <Form.Label row="true" className="text-start">
                      Tartalom az ajánlathoz ({l.name})
                    </Form.Label>
                    <Col sm={10} className="d-flex justify-content-start align-items-center">
                      <Form.Control
                        as="textarea"
                        id="content"
                          rows={6}
                          value={(proposal?.content?.hasOwnProperty(l.id) && proposal?.content[l.id])
                              ? proposal?.content[l.id]
                              : ""
                          }
                          onChange={(event) => handleChangeField(event, 0, l.id)}
                        />
                    </Col>
                  </Form.Group>
                  </>
                  )}
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col sm={10}>
            <Form>
              <Table striped bordered hover>
                <thead className="table-secondary align-middle">
                  <tr>
                    <th>Név</th>
                    <th>
                      <div>Nettó ár</div>
                      <div>{getCurrency()}</div>
                    </th>
                    <th>
                      Bruttó ár<div></div>
                      <div>{getCurrency()}</div>
                    </th>
                    <th>Egység</th>
                    <th>Mennyiség</th>
                    <th>Alkalom</th>
                    <th>
                      Nettó összeg<div></div>
                      <div>{getCurrency()}</div>
                    </th>
                    <th>
                      Bruttó összeg<div></div>
                      <div>{getCurrency()}</div>
                    </th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {console.log("selectedActs", selectedActs)}
                  {console.log("acts", acts)}
                  {selectedActs &&
                    /* acts &&
                    selectedActs &&
                    currencies &&
                    proposal &&
                    units &&
                    vatShares &&
                    vatShares.length > 0 &&
                    netPrices &&
                    netPrices.length > 0 &&
                    acts.length > 0 &&
                    netPrices.length === selectedActs.length &&
                    vatShares.length === selectedActs.length && */
                    selectedActs?.sort((a, b) => {
                      console.log("selectedAct", a);
                        if (acts.find((x) => x.id === a.actId)?.hasOwnProperty("orderId") & acts.find((x) => x.id === b.actId)?.hasOwnProperty("orderId")) {
                                  
                                      return (acts.find((x) => x.id === a.actId)?.orderId - acts.find((x) => x.id === b.actId)?.orderId);
                                    } else {
                                      return 0;
                                    }
                                  }).map(
                        (a, idx) =>
                          !a.hasOwnProperty("parentId") && (
                            <>
                              <tr key={idx} className="align-middle">
                                  <td className="align-middle text-start">
                                   {/* checkbox */}
                                                <Row>
                                                {(!acts.find((x) => x.id === a.actId)?.hasOwnProperty("vargroup") | acts.find((x) => x.id === a.actId)?.vargroup === false) ?
                                                  <Col sm={1}>
                                                    <Form.Check
                                                      type="checkbox"
                                                      id="actChecked"
                                                      onChange={(event) => handleChangeActChecked(event, a.id)}
                                                      checked={a.actChecked || false}
                                                    />
                                                  </Col> : ""} 


                                                {/* név */}
                                                <Col>
                                                  {
                                  acts
                                    .find((x) => x.id === a.actId)?.names?.find((l) => l.id === lang)?.hasOwnProperty("name")
                                    ? acts.find((x) => x.id === a.actId)?.names?.find((l) => l.id === lang)?.name
                                                    : ""}
                                                </Col>
                                                </Row>
                                </td>
                                {!acts.find((b) => b.id === a.actId)?.hasOwnProperty("vargroup") | (acts.find((b) => b.id === a.actId)?.vargroup === false) ? (
                                  <>
                                    <td>
                                      {/* nettó ár */}
                                      {/* ÁFA kulcsonként hány százalék az ÁFA az adott tételben: (acts.find((b) => b.id === a.actId)).prices.vat.find((w) => w.id === 1).share} */}

                                      <Form.Control
                                        type="number"
                                        id="price"
                                        onChange={(event) => handleChangeField(event, a.id)}
                                        disabled={proposal.net ? false : true}
                                        value={proposal.net ? a.price : netPrices.find((n) => n.id === a.id)?.totalAmount}
                                      />
                                    </td>
                                    <td>
                                      {/* bruttó ár */}

                                      <Form.Control
                                        type="number"
                                        id="price"
                                        onChange={(event) => handleChangeField(event, a.id)}
                                        disabled={proposal?.net ? true : false}
                                        value={proposal?.net ? grossPrices?.find((n) => n.id === a.id)?.totalAmount : a.price}
                                      />
                                    </td>
                                    <td className="align-middle">{units?.find((u) => u.uId === acts.find((b) => b.id === a.actId)?.unitId)?.names[lang]}</td>
                                     <td className="col-md-1">
                                        <Form.Control  
                                        type="number"
                                        id="actPax"
                                        // ha a konkrét, ebben az ajánlatban tárolt programnak nincs külön létszáma (tehát 0), akkor marad a teljes ajánlat globális létszáma
                                        // kivéve, ha nem létszám alapú a számolás (pl. terem vagy sommelier). basedOnCount=false esetén nem változik a létszámtól függően
                                        value={(acts.find((x) => x.id === a.actId)?.hasOwnProperty("basedOnCount") && acts.find((x) => x.id === a.actId)?.basedOnCount === true) ? (a.actPax !== 0 ? a.actPax : proposal.pax) : (a.actPax !== 0 ? a.actPax : 1)}
                                        onChange={(event) => handleChangeField(event, a.id)}
                                      />
                                        </td>
                                      {/*    Alkalom oszlop, ami egy plusz szorzó a táblázatban, pl. ha több éjszakát kell számolni */}
                                      <td className="col-md-1">
                                        <Form.Control  
                                        type="number"
                                        id="actMultiplier"
                                        value={a.hasOwnProperty("actMultiplier") ? a.actMultiplier : 1}
                                        onChange={(event) => handleChangeField(event, a.id)}
                                      />
                                    </td>
                                    <td className="align-middle">{a.actChecked ? formatCurrencyAdmin(getNetPrice(a)): 0}</td>
                                    <td className="align-middle">{a.actChecked ? formatCurrencyAdmin(getGrossPrice(a)): 0}</td>
                                  </>
                                ) : (
                                  <>
                                    <td colSpan={7}></td>
                                  </>
                                            )}
                                            <td>
                                  <CurrencyExchange className="me-1" color="#0d6efd" onClick={(event) => loadPrices(event, true, a.id)} />
                                  <XSquareFill color="#0d6efd" onClick={(event) => handleDeleteAct(event, a.id)} />
                                            </td>
                              
                              </tr>
                              {/* Ha egy variációcsoportról van szó, akkor listázza ki a hozzá tartozó tételeket} */}
                              {acts.find((b) => b.id === a.actId)?.hasOwnProperty("vargroup") && acts.find((b) => b.id === a.actId).vargroup === true && (
                                <>
                                  {selectedActs?.sort((a, b) => {
                        if (acts.find((x) => x.id === a.actId)?.hasOwnProperty("orderId") & acts.find((x) => x.id === b.actId)?.hasOwnProperty("orderId")) {
                                  
                                      return (acts.find((x) => x.id === a.actId)?.orderId - acts.find((x) => x.id === b.actId)?.orderId);
                                    } else {
                                      return 0;
                                    }
                                  }).map((c, j) => {
                                      if (c.parentId === a.id)
                                        return (
                                          <>
                                            <tr key={j} className="align-middle clientViewVariation">
                                              <td
                                                data-label="Név"
                                                className="text-start"
                                                style={{
                                                  opacity: selectedActs.find((x) => {
                                                    return x.actId === c.actId && x.parentId === a.id;
                                                  })?.selected
                                                    ? 1
                                                    : 0.5
                                                }}
                                              >
                                                {/* checkbox */}
                                                <Row>
                                                  <Col sm={1}>
                                                    <Form.Check
                                                      type="checkbox"
                                                      id="variationCheck"
                                                      onChange={(event) => handleChangeVarChecked(event, c.actId, a.id)}
                                                      checked={
                                                        selectedActs.find((x) => {
                                                          return x.actId === c.actId && x.parentId === a.id;
                                                        })?.selected || false
                                                      }
                                                    />
                                                  </Col>
                                                  {/* név */}
                                                  <Col>
                                                    {acts
                                                      .find((x) => x.id === c.actId)
                                                      ?.names?.find((l) => l.id === lang)
                                                      ?.hasOwnProperty("name")
                                                      ? acts.find((x) => x.id === c.actId).names.find((l) => l.id === lang).name
                                                      : ""}
                                                  </Col>
                                                </Row>
                                              </td>
                                              <td data-label="Nettó ár" className="text-end">
                                                {/* nettó ár */}
                                                {/* ÁFA kulcsonként hány százalék az ÁFA az adott tételben: (acts.find((b) => b.id === a.actId)).prices.vat.find((w) => w.id === 1).share} */}

                                                <Form.Control
                                                  type="number"
                                                  id="price"
                                                  onChange={(event) => handleChangeField(event, c.id)}
                                                  disabled={proposal.net ? false : true}
                                                  value={proposal.net ? c.price : netPrices.find((n) => n.id === c.id)?.totalAmount}
                                                />
                                              </td>
                                              <td data-label="Bruttó ár" className="text-end">
                                                {/* bruttó ár */}
                                                <Form.Control
                                                  type="number"
                                                  id="price"
                                                  onChange={(event) => handleChangeField(event, c.id)}
                                                  disabled={proposal.net ? true : false}
                                                  value={proposal.net ? grossPrices?.find((n) => n.id === c.id)?.totalAmount : c.price}
                                                />
                                              </td>
                                              <td data-label="Egység">{units?.find((u) => u.uId === acts.find((b) => b.id === c.actId)?.unitId)?.names[lang]}</td>
                                              <td data-label="Mennyiség">
                                                <Form.Control
                                                  type="number"
                                                  id="actPax"
                                                  // ha a konkrét, ebben az ajánlatban tárolt programnak nincs külön létszáma (tehát 0), akkor marad a teljes ajánlat globális létszáma
                                                  // kivéve, ha nem létszám alapú a számolás (pl. terem vagy sommelier). basedOnCount=false esetén nem változik a létszámtól függően
                                                  value={(acts.find((x) => x.id === c.actId)?.hasOwnProperty("basedOnCount") && acts.find((x) => x.id === c.actId)?.basedOnCount === true) ? (c.actPax !== 0 ? c.actPax : proposal.pax) : (c.actPax !== 0 ? c.actPax : 1)}
                                                  onChange={(event) => handleChangeField(event, c.id)}
                                                />
                                              </td>
                                              {/*    Alkalom oszlop, ami egy plusz szorzó a táblázatban, pl. ha több éjszakát kell számolni */}
                                              <td data-label="Alkalom" className="col-md-1">
                                                <Form.Control  
                                                type="number"
                                                id="actMultiplier"
                                                value={c.hasOwnProperty("actMultiplier") ? c.actMultiplier : 1}
                                                onChange={(event) => handleChangeField(event, c.id)}
                                              />
                                            </td>
                                              <td data-label="Nettó összeg" className="text-end">
                                                {formatCurrencyAdmin(getNetPrice(c))}
                                              </td>
                                              <td data-label="Bruttó összeg" className="text-end">
                                                {formatCurrencyAdmin(getGrossPrice(c))}
                                              </td>
                                              <td>
                                                <CurrencyExchange className="me-1" color="#0d6efd" onClick={(event) => loadPrices(event, true, c.id)} />
                                              </td>
                                            </tr>
                                          </>
                                        );
                                    })}
                                </>
                              )}
                            </>
                          )
                      )}
                  <tr className="table-dark">
                    <td>
                      <b>Összesen</b>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{formatCurrencyAdmin(displayTotalNet())}</td>
                    <td>{formatCurrencyAdmin(displayTotalGross())}</td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Form>
          </Col>
          <Col sm={2}>
            <Row className="pb-4">
              <Col>
            <Button id="addSelectedAct" onClick={handleShowAddAct} variant="primary" type="submit">
              Hozzáad
            </Button>
           
            <Modal show={showAddAct} onHide={handleCloseAddAct} scrollable={true} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Programok hozzáadása</Modal.Title>
              </Modal.Header>
              {listActsAdd && (
                <Modal.Body>
                  <Form>
                    {acts?.sort((a, b) => {
              if (a.hasOwnProperty("orderId") & b.hasOwnProperty("orderId")) {
                return a.orderId - b.orderId;
              } else {
                return 0;
              }
            })?.map((a, i) => (
                      <> 
                        <Form.Check
                          key={a.id}
                          type="checkbox"
                          id="actAdd"
                          label={<>
                            {(a.hasOwnProperty("vargroup") && a.vargroup === true) && <CardList color="#0d6efd" className="me-2" />}
                            {a.hasOwnProperty("names") && a.names.find((l) => l.id === lang) && a.names.find((l) => l.id === lang).hasOwnProperty("name") ? a.names.find((l) => l.id === lang).name : ""
                            }</>}
                          onChange={handleChangeAddModal}
                          checked={listActsAdd.indexOf(a.id) !== -1}
                          value={a.id}
                        />
                      </>
                    ))}
                  </Form>
                </Modal.Body>
              )}
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseAddAct}>
                  Bezár
                </Button>
                <Button variant="primary" onClick={handleAddSelectedAct}>
                  Hozzáad
                </Button>
              </Modal.Footer>
                </Modal>
              </Col>
              </Row>
              
                <Row className="pb-4">
                  <Col>

               {/*  EGYEDI TÉTEL HOZZÁADÁSA MODAL ABLAK */}
                
                <Button id="addSelectedCustomAct" onClick={(event) => handleShowAddCustomAct(event, "new")} variant="primary" type="submit">Egyedi tétel</Button>
                
                <Modal show={showAddCustomAct.openModal} onHide={handleCloseAddCustomAct} scrollable={true} size="lg">
          
                        <Modal.Header closeButton>
                          <Modal.Title>Egyedi tétel hozzáadása</Modal.Title>
                        </Modal.Header>
                  <Modal.Body>
                    <EditActivity aId="newItem"/>
                            {/*   <Form>
                                <Form.Group as={Row} className="pb-4 pt-4">
                                  <Form.Label column sm={2} className="text-start">
                                    Program neve
                                  </Form.Label>
                                  <Col sm={4}>
                                    {langs.map((l) => (
                                      <>
                                        {l.name}
                                       <Form.Control
                                          key={l.id}
                                          id="name"
                                          value={showAddCustomAct?.hasOwnProperty("customActId") && showAddCustomAct.customActId === "new" ? "Új" : showAddCustomAct?.customActId}
                                          onChange={(event) => handleChangeField(event, l.id)}
                                        />
                                      </>
                                    ))}
                              </Col>
                            </Form.Group>
                          </Form> */}
                          </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleCloseAddCustomAct}>
                            Bezár
                          </Button>
                          <Button variant="primary" onClick={handleAddSelectedCustomAct}>
                            Hozzáad
                          </Button>
                        </Modal.Footer>
                </Modal>
                
                {/*  /EGYEDI TÉTEL HOZZÁADÁSA MODAL ABLAK */}

          </Col>
            </Row>
            <Row className="pb-4">
              <Col>
            <Button id="downloadExcel" onClick={downloadExcel} variant="primary" type="submit">
              Excel letöltése
            </Button>
              </Col>
            </Row>
            <Row className="pb-4">
              <Col>
                {selectedgroups && selectedgroups?.length > 0 && images && statuses && clients && proposal && currencies && selectedClient[0] && selectedClient[0].hasOwnProperty("name") && currencies.length > 0 &&
                  <Button id="downloadPdf" variant="primary" type="submit" onClick={generatePdfDocument}>
                    PDF letöltése
                  </Button>}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default EditProposal;
