import { db } from "./firebase";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, onSnapshot, query, addDoc, doc, updateDoc, deleteDoc } from "firebase/firestore";
import React from "react";
import { Container, Row, Col, Form, Button, Accordion } from "react-bootstrap";
import { XSquareFill, CardList } from "react-bootstrap-icons";
import { langs } from "./Common";

function Activities() {
  const [acts, setActs] = useState([]);
  const [name, setName] = useState("");
  const [lang, setLang] = useState(langs[0].id);
  var [newOrderId, setNewOrderId] = useState(0);

  const q = query(collection(db, "activities"));
  function adatLetolt() {
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      // ez csak akkor, ha nem kellenek bele a vargroup-ok
      /*   var sortedArray = [...newData.filter((n) => n.vargroup !== true)].sort( */
      var sortedArray = [...newData].sort(function (a, b) {
        const nameA = a.names.find((x) => x.id === lang).name.toUpperCase();
        const nameB = b.names.find((x) => x.id === lang).name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }); // names must be equal
      setActs([...sortedArray]);
    });
    return unsubscribe;
  }

  useEffect(() => adatLetolt(), []);

  function generateNewOrderId() {
    const orderIds = [];
    var tempNewOrderId = 0;
    acts?.forEach((a) => typeof a.orderId === 'number' && orderIds.push(a.orderId));
   /*  orderIds.forEach((item, index) => (typeof item !== 'number' | item === 'undefined') && orderIds.splice(index, 1)); */
     console.log("orderids elobb", orderIds)
    tempNewOrderId = Math.max(...orderIds) + 1;
    console.log("orderids", orderIds)
    console.log("tempNewOrderId", tempNewOrderId);
    return tempNewOrderId;
  }


  function handleAddAct(event) {
    event.preventDefault();
    const dbRef = collection(db, "activities");
    addDoc(dbRef, {
      names: [
        {
          id: lang,
          name: name
        }
      ],
      vat: [],
      basedOnCount: true,
      orderId: generateNewOrderId()
    })
      .then((docRef) => {
        console.log(docRef.id); //p4eZcO5QV43IYnigxALJ
      })
      .catch((error) => {
        console.log(error);
      });
    setName("");
  }

  function handleDeleteAct(event, actId) {
    event.preventDefault();
    if (window.confirm("Biztosan törlöd a teljes ügyfelet?")) {
      const docRef = doc(db, "activities", actId);
      deleteDoc(docRef)
        .then((docRef) => {
          console.log("Document deleted");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function handleDragStart(event, orderId) {
    console.log("outgoing Id", orderId);
    event.dataTransfer.setData("text", orderId);
  }

  function handleOnDrop(event, newOrderId) {
    event.preventDefault();
    // Itt addig kísérleteztem az algoritmussal, hogy végül működik a reorder:
    // Ha kisebbről nagyobb id-re rakod át, akkor is működik, és ha nagyobbról kisebbre, akkor is
    var incomingOrderId = +event.dataTransfer.getData("text");
    var tempArray = [...acts];
    var sourceAct = tempArray.find((x) => x.orderId === incomingOrderId);
    console.log("incomingOrderId, newOrderId, sourceAct", incomingOrderId, newOrderId, sourceAct);
    if (newOrderId < incomingOrderId) {
      tempArray.forEach((t) => t.orderId >= newOrderId && t.orderId !== incomingOrderId && t.orderId++);
      sourceAct.orderId = newOrderId;
      tempArray.forEach((t) => t.orderId > incomingOrderId && t.orderId--);
    } else {
      tempArray.forEach((t) => incomingOrderId < t.orderId && t.orderId <= newOrderId && t.orderId--);
      sourceAct.orderId = newOrderId;
    }

    setActs([...tempArray]);
  }

  function handleSaveOrder(event) {
    event.preventDefault();
    acts.map((a) => {
      const docRef = doc(db, "activities", a.id);
      updateDoc(docRef, {
        orderId: a.orderId
      })
        .then((docRef) => {
          console.log("Document field updated");
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  /*     db.collection("activities").get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        console.log(doc);
         /*  doc.ref.update({
             
          });
      });
  }); */

  function handleOnDragOver(event) {
    event.preventDefault();
  }

  return (
    <div className="Activities">
      <Container className="pt-4">
        <Form>
          <Row>
            <Col className="text-start align-middle">
              <Form.Label htmlFor="inputTitle">Új program létrehozása</Form.Label>
            </Col>
          </Row>
          <Row className="pt-2 pb-2">
            <Col className="text-start align-middle">
              <Form.Control id="activity" value={name} onChange={(event) => setName(event.target.value)} />
            </Col>
            <Col>
              <Button onClick={handleAddAct} variant="primary" type="submit">
                Hozzáad
              </Button>
            </Col>
            <Col>
              <Button onClick={handleSaveOrder} variant="primary" type="submit">
                Sorrend mentése
              </Button>
            </Col>
          </Row>
        </Form>
        {acts &&
          acts
            .sort((a, b) => {
              if (a.hasOwnProperty("orderId") & b.hasOwnProperty("orderId")) {
                return a.orderId - b.orderId;
              } else {
                return 0;
              }
            })
          .map((a, idx) => {
            console.log("a.id, orderId, generateNewOrderId", a.id, a.orderId)
             if (!a.hasOwnProperty("basedOnCount") && a.vargroup !== true) console.log("a.basedOnCount", a.id)
              if (!a.hasOwnProperty("orderId")) a.orderId = generateNewOrderId();
              return (
                <div key={a.id} id={a.orderId} draggable onDragOver={handleOnDragOver} onDragStart={(event) => handleDragStart(event, a.orderId)} onDrop={(event) => handleOnDrop(event, a.orderId)}>
                  <Row className="pt-2 pb-2 border-secondary border-bottom">
                    <Col className="d-flex justify-content-start align-items-center">
                      {a.hasOwnProperty("vargroup") && a.vargroup === true && <CardList color="#0d6efd" className="me-2" />}
                      <Link to={a.hasOwnProperty("vargroup") && a.vargroup === true ? "/vargroup" : "/activity"} state={{ aId: a.id }} style={{ textDecoration: "none", color: "#000" }}>
                        {a.hasOwnProperty("names") && a.names.find((l) => l.id === lang) && a.names.find((l) => l.id === lang).hasOwnProperty("name") && a.names.find((l) => l.id === lang).name}
                      </Link>
                    </Col>
                    <Col>
                      <Button onClick={(event) => handleDeleteAct(event, a.id)} variant="primary" type="submit">
                        Törlés
                      </Button>
                    </Col>
                  </Row>
                </div>
              );
            })}
      </Container>
    </div>
  );
}

export default Activities;
