import React from "react";
import { db } from "./firebase";
import { useState, useEffect } from "react";
import { langs, imagesPath } from "./Common";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  Image
} from "react-bootstrap";

function ImageSelect(props) {
  const images = props.images;
  const setImages = props.setImages;
  const target = props.target;
  const setTarget = props.setTarget;
  const selectedImages = props.selectedImages;
  const setSelectedImages = props.setSelectedImages;
  const showAddImage = props.showAddImage;
  const setShowAddImage = props.setShowAddImage;
  const handleCloseAddImage = () => setShowAddImage(false);


  function handleChangeAddImageModal(event) {
    var targetId = event.target.id;
    var tempImageId = event.target.value;
    var tempArray = [];
    if (targetId === "imageAdd") tempArray = selectedImages;
    if (tempArray.indexOf(tempImageId) === -1 && event.target.checked) {
      tempArray.push(tempImageId);
    } else {
      tempArray = tempArray.filter((e) => e !== tempImageId);
    }
    if (targetId === "imageAdd") setSelectedImages([...tempArray]);
  }

  function handleAddSelectedImage(event) {
    event.preventDefault();
    if (target) {
      if (!target.hasOwnProperty("images")) target.images = [];
    }
    setTarget({ ...target, images: selectedImages });
  }



  return (
     <Modal
                      show={showAddImage}
                      onHide={handleCloseAddImage}
                      scrollable={true}
                      fullscreen={true}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Képek kiválasztása</Modal.Title>
                      </Modal.Header>
                      {selectedImages && images && (
                        <Modal.Body>
                          <Form>
                            <Row>
                              {images &&
                                images.length > 0 &&
                                images.sort((a,b) => (b.dateAdded - a.dateAdded)).map((i) => (
                                  <>
                                    {i.hasOwnProperty("filename") && (
                                      <Col
                                        key={i.id}
                                        sm={2}
                                        className="d-flex flex-column pb-4"
                                        style={{ wordBreak: "break-all" }}
                                      >
                                        {"ID: " + i.id}
                                        <Image
                                          src={
                                            imagesPath +
                                            i.filename.substring(
                                              0,
                                              i.filename.lastIndexOf(".")
                                            ) +
                                            "_thumb.jpg"
                                          }
                                          thumbnail
                                        />
                                        <Form.Check
                                          key={i.id}
                                          type="checkbox"
                                          id="imageAdd"
                                          label={
                                            i.hasOwnProperty("filename")
                                              ? i.filename
                                              : ""
                                          }
                                          onChange={handleChangeAddImageModal}
                                          checked={
                                            selectedImages.indexOf(i.id) !== -1
                                          }
                                          value={i.id}
                                        />

                                        {/*   {i.filename} */}
                                      </Col>
                                    )}
                                  </>
                                ))}
                            </Row>
                          </Form>

                        </Modal.Body>
                      )}
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={handleCloseAddImage}
                        >
                          Bezár
                        </Button>
                        <Button
                          variant="primary"
                          onClick={handleAddSelectedImage}
                        >
                          Mentés
                        </Button>
                      </Modal.Footer>
                    </Modal>
)

}

export default ImageSelect;