import React from "react";
import axios from "axios";
import { useState } from "react";


function EditDataSheet(props) {
    const [emailData, setEmailData] = useState({
  fname: '',
  lname: '',
  email: '',
  message: '',
  mailSent: false,
  error: null
});
    

function handleFormSubmit(e) {
  e.preventDefault();
  axios({
    method: 'post',
    url: 'https://www.visegradevent.hu/email.php',
    headers: { 'Content-Type': 'application/json' },
    data: emailData
  })
    .then(result => {
      setEmailData({
        mailSent: result.data.sent
      })
    })
    .catch(error => setEmailData({ error: error.message }));
};

    return(
        <>
            Adatlap

            <form>
  <label>First Name</label>
  <input type="text" id="fname" name="firstname" placeholder="Your name.."
    value={emailData.fname}
    onChange={e => setEmailData({ fname: e.target.value })}
  />
  <label>Last Name</label>
  <input type=" text" id="lname" name="lastname" placeholder="Your last name.."
    value={emailData.lname}
    onChange={e => setEmailData({ lname: e.target.value })}
  />


  <label>Email</label>
  <input type="email" id="email" name="email" placeholder="Your email"
    value={emailData.email}
    onChange={e => setEmailData({ email: e.target.value })}
  />


  <label>Message</label>
  <textarea id="message" name="message" placeholder="Write something.."
    onChange={e => setEmailData({ message: e.target.value })}
    value={emailData.message}
  ></textarea>
  <input type="submit" onClick={e => handleFormSubmit(e)} value="Submit" />
</form >
            <div>
                {emailData?.mailSent &&
                    <div>Thank you for contcting us.</div>
                }
                </div>
        </>
    )
}

export default EditDataSheet;