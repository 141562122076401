import { React} from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { imagesPath } from "./Common";
import "./clientview.css";
import { words } from "./Vocabulary";
import { Row, Col } from "react-bootstrap";

import {
  Document,
  Font,
  Page,
  Text,
  Image,
  StyleSheet,
  View
} from '@react-pdf/renderer';

function ProposalPdf(props) {
  const statuses = props.statuses;
  const proposal = props.proposal;
  const clients = props.clients;
  const currencies = props.currencies;
  const selectedClient = props.selectedClient;
  const acts = props.acts;
  const selectedActs = props.selectedActs;
  const units = props.units;
  const vatShares = props.vatShares;
  const netPrices = props.netPrices;
  const grossPrices = props.grossPrices;
  const getNetPrice = props.getNetPrice;
  const getGrossPrice = props.getGrossPrice;
  const images = props.images;
  const lang = props.lang;
  const setLang = props.setLang;
  const getCurrency = props.getCurrency;
  const displayTotalGross = props.displayTotalGross;
  const displayTotalNet = props.displayTotalNet;
  const handleChangeVarChecked = props.handleChangeVarChecked;
  const handleChangeActChecked = props.handleChangeActChecked;
  const downloadExcel = props.downloadExcel;
  const selectedgroups = props.selectedgroups;
  const priceTableRef = props.priceTableRef;
  var tableCounter = props.tableCounter;
  const resetTableCounter = props.resetTableCounter;
  const formatCurrency = props.formatCurrency;
  const imageInject = props.imageInject;
  const startsWithVowel = props.startsWithVowel;



  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
      fontFamily: "Merriweather",
      color: "#716a4d",
      fontSize: "14px",
      backgroundColor: "#ffffff",
      width: "100%",
      maxWidth: "100%"
   /*    alignItems: "center" */
      
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      fontFamily: "Josefin Sans",
      color: "#ff6f61",
      lineHeight: 1.2,
      marginTop: 10,
      marginBottom: 14,
      marginRight: 10,
      marginLeft: 10
    },
    titleCap: {
      fontSize: 20,
      textAlign: 'center',
      fontFamily: "Josefin Sans",
      color: "#ff6f61",
      lineHeight: 1.2,
      marginTop: 10,
      marginBottom: 14,
      marginRight: 10,
      marginLeft: 10,
      textTransform: 'uppercase'
    },
    cap: {
      textTransform: 'uppercase'
    },

    author: {
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 40,
      marginRight: 10,
      marginLeft: 10
    },
    subtitle: {
      fontSize: 14,
      marginBottom: 12,
      fontWeight: 600,
      marginRight: 10,
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 10
    },
    text: {
      /*  margin: 12, */
      color: "#716a4d",
      fontSize: 10,
      textAlign: 'left',
      fontWeight: 400,
      marginRight: 10,
      marginLeft: 10,
      whiteSpace: "pre-wrap",
      lineHeight: 2
    },
     cellText: {
      fontSize: 10,
      textAlign: 'left',
      fontWeight: 600,
      whiteSpace: "pre-wrap"
    },
    textBold: {
      /*   margin: 12, */
      fontSize: 10,
      textAlign: 'left',
      fontWeight: 600,
      marginRight: 10,
      marginLeft: 10,
      lineHeight: 2
    },
    image: {
      /*       marginVertical: 15,
            marginHorizontal: 100, */
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    imagesView: {
      maxWidth: '100%',
      width: '100%',
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      justifyContent: 'space-between'
      /*  margin: "0 -10" */
    },
    singleImageView: {
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: "auto",
      padding: "0 10"
    },
    singleImage: {
      maxWidth: '100%',
      objectFit: 'cover'
    },
    rowFirst: {
      borderTop: 1,
      borderBottom: 1,
      width: "70%",
      flexDirection: "row",
      alignItems: "left",
      borderColor: "rgb(243, 242, 239)"
    },
    row: {
      borderTop: 0,
      borderBottom: 1,
      width: "70%",
      flexDirection: "row",
      alignItems: "left",
       borderColor: "rgb(243, 242, 239)"
    },
    cellFirst: {
      padding: 5,
      width: "50%",
      borderRight: 1,
      borderLeft: 1,
      borderColor: "#000",
      alignItems: "center",
      backgroundColor: "#d9caa9",
      borderColor: "rgb(243, 242, 239)"
    },
     cell: {
      padding: 5,
      width: "50%",
      borderRight: 1,
      borderLeft: 0,
      borderColor: "#000",
       alignItems: "center",
       backgroundColor: "#d9caa9",
       borderColor: "rgb(243, 242, 239)"
    },
    priceRowFirst: {
      color: "#FFF",
      backgroundColor: "rgb(167, 155, 130)",
      borderTop: 1,
      borderBottom: 1,
      width: "100%",
      flexDirection: "row",
      alignItems: "left",
      borderColor: "rgb(243, 242, 239)"
    },
     priceRow: {
      borderTop: 0,
      borderBottom: 1,
      width: "100%",
      flexDirection: "row",
       alignItems: "left",
      backgroundColor: "#d9caa9",
       borderColor: "rgb(243, 242, 239)"
    },
     
    priceCellFirst: {
      display: "block",
      textAlign: "left",
      padding: 5,
      borderRight: 1,
      borderLeft: 1,
      borderColor: "#000",
     /*  backgroundColor: "#d9caa9", */
      borderColor: "rgb(243, 242, 239)"
    },
     priceCell: {
      padding: 5,
      borderRight: 1,
      borderLeft: 0,
      borderColor: "#000",
       alignItems: "flex-end",
       /* backgroundColor: "#d9caa9", */
       borderColor: "rgb(243, 242, 239)",
    },
    cell40: {
       width: "40%"
    },
    cell10: {
      width: "10%"
    },
    
    centerTable: {
      alignItems: 'center',
      marginBottom: 10
     }
  });

  Font.register({
    family: 'Merriweather',
    fonts: [ 
    { src: "/fonts/Merriweather-Regular.ttf", fontWeight: 400 },
      { src: "/fonts/Merriweather-Bold.ttf", fontWeight: 600 }
    ]
  });
  
  Font.register({
    family: 'Josefin Sans',
    src: '/fonts/JosefinSans-Bold.ttf',
  })

  const Subtitle = ({ children, ...props }) => (
    <Text style={styles.subtitle} {...props}>
      {children}
    </Text>
  );

  function pdfImageInject(str, imageIdsArray) {
    if (typeof str !== "string") {
      return false;
    }
    // itt cseréli ki a kép taget, azaz a kapcsos zárójelbe rakott image-id egy Image react tagre
    var stringArray = str.split(/(\{.+?\})/g);
    return stringArray.map((s) => {

      if (/\{(.+?)\}/.test(s) && imageIdsArray instanceof Array) {
        const insImagesArray = s.split('+');
        return <>
          <View style={styles.imagesView}>
            {insImagesArray.map((j) => {
              var imageId = imageIdsArray.find((x) => x === j.replace(/{/, "").replace(/}/, ""));
              return <View style={styles.singleImageView}><Image style={styles.singleImage} src={imagesPath + images.find((i) => i.id === imageId)?.filename} /></View>
            })}
          </View>
        </>
        
      } else {
        // ha nem képről van szó, akkor a szövegben megkeresi a [b] és [/b] között található szöveget, és vastagítja

        var newStringArray = s.split(/(\[b][\s\S]+?\[\/b])/g);
        return newStringArray.map((z) => {
         
          if (/\[b]([\s\S]+?)\[\/b]/.test(z)) {
            return <Text style={styles.textBold}>{z.replace("[b]", "").replace("[/b]", "")}</Text>;
          } else {
            return <Text style={styles.text}>{z}</Text>;
          }
        });
      }
    });
  }

  var detailsTableCounter = 0;
    
  return ( /* (selectedgroups && selectedgroups?.length > 0 && images && statuses && clients && proposal && currencies && selectedClient[0] && selectedClient[0].hasOwnProperty("name") && currencies.length > 0) ? */ (
      <Document>
        <Page style={styles.body} wrap>
          <Text style={styles.title}>{words.ajanlat[lang]} {lang === "hu" && (startsWithVowel(selectedClient[0].name) ? "az" : "a")} {words.naknek[lang]}</Text>
          <Text style={styles.title}>{selectedClient[0].name}</Text>
        <Text style={styles.title}>{words.reszere[lang]}</Text>

        {/*   *** RÉSZLETEK ***  */}
        {(proposal.content || proposal.hasDate || proposal.tentative) && (
          <View style={styles.centerTable}>
            <View>
              <Text style={styles.titleCap}>{words.ajanlatreszletei[lang]}</Text>
            </View>

            {proposal.content && proposal.content[lang] &&
              <Text style={styles.text}>
                {proposal.content?.[lang]}
              </Text>}
            {(proposal.hasDate || proposal.tentative) &&
              <>
            {proposal.hasDate &&
                   <>
                <View style={styles.rowFirst} wrap={true}>
                 {/*  Növeljük a számot, így az opicós sornál nem rak ki duplán bordert */}
                  {detailsTableCounter++}
                  <View style={styles.cellFirst}><Text style={{ ...styles.cellText, ...styles.cap }}>{words.erkezesdatuma[lang]}</Text></View>
                  <View style={styles.cell}><Text style={styles.cellText}>{new Date(proposal.arrival * 1000).toLocaleDateString("hu-HU")}</Text></View>
                  </View>  
                    {((proposal.arrival !== proposal.departure) && proposal.departure) &&
                          <View style={styles.row}>
                                         <View style={styles.cellFirst}><Text style={{ ...styles.cellText, ...styles.cap }}>
                                              {words.tavozasdatuma[lang]}
                                          </Text></View>
                                            <View style={styles.cell}><Text style={{ ...styles.cellText, ...styles.cap }}>
                                              {new Date(proposal.departure * 1000).toLocaleDateString("hu-HU")}
                                            </Text></View>
                          </View>                  
                }
                {proposal.tentative &&
                  <View style={(detailsTableCounter === 0) ? styles.rowFirst : styles.row} wrap={true}>
                  <View style={styles.cellFirst}><Text style={{ ...styles.cellText, ...styles.cap }}>{words.opciodatuma[lang]}</Text></View>
                  <View style={styles.cell}><Text style={styles.cellText}>{new Date(proposal.deadline * 1000).toLocaleDateString("hu-HU")}</Text></View>
                  </View>       
                            }
                   
                  </>
              }
              </>
             
            }
          </View>)}
                {/*   *** /RÉSZLETEK ***  */}   
        
               {/*   *** ÁRAJÁNLAT *** */}
              <View>
              <Text style={styles.titleCap}>{words.arajanlat[lang]}</Text>
              </View>
              <View style={styles.priceRowFirst} wrap>
                  <View style={{ ...styles.priceCellFirst, ...styles.cell40 }}><Text style={{ ...styles.cellText, ...styles.cap }}>{words.nev[lang]}</Text></View>
                  <View style={{ ...styles.priceCell, ...styles.cell10 }}><Text style={{ ...styles.cellText, ...styles.cap }}>{words.netto[lang]}</Text></View>
                  <View style={{ ...styles.priceCell, ...styles.cell10 }}><Text style={{ ...styles.cellText, ...styles.cap }}>{words.brutto[lang]} {getCurrency()}</Text></View>
                  <View style={{ ...styles.priceCell, ...styles.cell10 }}><Text style={{ ...styles.cellText, ...styles.cap }}>{words.egyseg[lang]}</Text></View>
                  <View style={{ ...styles.priceCell, ...styles.cell10 }}><Text style={{ ...styles.cellText, ...styles.cap }}>{words.mennyiseg[lang]}</Text></View>
                  <View style={{ ...styles.priceCell, ...styles.cell10 }}><Text style={{ ...styles.cellText, ...styles.cap }}>{words.nettoosszeg[lang]} {getCurrency()}</Text></View>
                  <View style={{ ...styles.priceCell, ...styles.cell10 }}><Text style={{ ...styles.cellText, ...styles.cap }}>{words.bruttoosszeg[lang]} {getCurrency()}</Text></View>
              </View>

        {selectedActs &&
          selectedActs?.sort((a, b) => {
            if (acts.find((x) => x.id === a.actId).hasOwnProperty("orderId") & acts.find((x) => x.id === b.actId).hasOwnProperty("orderId")) {
                                  
              return (acts.find((x) => x.id === a.actId)?.orderId - acts.find((x) => x.id === b.actId)?.orderId);
            } else {
              return 0;
            }
          }).map((a, idx) =>
            !a.hasOwnProperty("parentId") && /* csak PDF-ben csak a kijelölt sorokat írjuk! */ a.actChecked && (
              <>
                <View key={idx} style={styles.priceRow} wrap>
                              <View style={{ ...styles.priceCellFirst, ...styles.cell40 }}><Text style={styles.cellText}>
                                {/* név */}
                                {
                                acts
                                  .find((x) => x.id === a.actId)?.names?.find((l) => l.id === lang)?.hasOwnProperty("name")
                                  ? acts.find((x) => x.id === a.actId).names.find((l) => l.id === lang).name
                        : ""}
                              </Text>
                          </View>
                              {!acts.find((b) => b.id === a.actId)?.hasOwnProperty("vargroup") | (acts.find((b) => b.id === a.actId)?.vargroup === false) ? (
                                <>
                                  <View style={{ ...styles.priceCell, ...styles.cell10 }}><Text style={styles.cellText}>
                                    {/* nettó ár */}
                                    {proposal.net ? formatCurrency(a.price) : formatCurrency(netPrices.find((n) => n.id === a.id)?.totalAmount)}
                                  </Text></View>
                                  <View style={{ ...styles.priceCell, ...styles.cell10 }}><Text style={styles.cellText}>
                                    {/* bruttó ár */}
                                    {proposal?.net ? formatCurrency(grossPrices?.find((n) => n.id === a.id)?.totalAmount) : formatCurrency(a.price)}
                                  </Text></View>
                                  <View style={{ ...styles.priceCell, ...styles.cell10 }}><Text style={styles.cellText}>
                                    {units?.find((u) => u.uId === acts.find((b) => b.id === a.actId)?.unitId)?.names[lang]}
                                   </Text></View>
                                   {/* PDF-BEN MINDIG írja ki a létszámot és a nettó összeget*/}
                                        <View style={{ ...styles.priceCell, ...styles.cell10 }}><Text style={styles.cellText}>{a.actPax !== 0 ? a.actPax : proposal.pax} </Text></View>
                                        <View style={{ ...styles.priceCell, ...styles.cell10 }}><Text style={styles.cellText}>
                                        {formatCurrency(getNetPrice(a))}
                                        </Text></View>
                                         <View style={{ ...styles.priceCell, ...styles.cell10 }}><Text style={styles.cellText}>
                                         {formatCurrency(getGrossPrice(a))}
                                        </Text></View>         
                                </>
                              ) : (
                                <>
                                  ""
                                </>
                  )}
                              {/* Ha egy variációcsoportról van szó, akkor listázza ki a hozzá tartozó tételeket} */}
                            {acts.find((b) => b.id === a.actId)?.hasOwnProperty("vargroup") && acts.find((b) => b.id === a.actId).vargroup === true && (
                              <>
                                {selectedActs?.sort((a, b) => {
                                    if (acts.find((x) => x.id === a.actId).hasOwnProperty("orderId") & acts.find((x) => x.id === b.actId).hasOwnProperty("orderId")) {
                                  
                                      return (acts.find((x) => x.id === a.actId)?.orderId - acts.find((x) => x.id === b.actId)?.orderId);
                                    } else {
                                      return 0;
                                    }
                                  }).map((c, j) => {
                                   //TODO: átalakítani PDF-re, betenni feltételnek, hgy selectedActs.find((x) => { return x.actId === c.actId && x.parentId === a.id })?.selected
                                    if (c.parentId === a.id && selectedActs.find((x) => { return x.actId === c.actId && x.parentId === a.id })?.selected)
                                      return (
                                        
                                        <>
                                     <View key={idx} style={styles.priceRow} wrap>
                                    <View style={{ ...styles.priceCellFirst, ...styles.cell40 }}><Text style={styles.cellText}>
                                              {/* név */}
                                              {acts
                                                .find((x) => x.id === c.actId)
                                                ?.names?.find((l) => l.id === lang)
                                                ?.hasOwnProperty("name")
                                                ? acts.find((x) => x.id === c.actId).names.find((l) => l.id === lang).name
                                                : ""}
                                            </Text>
                                            </View>
                                          <View style={{ ...styles.priceCell, ...styles.cell10 }}><Text style={styles.cellText}>
                                            {/* nettó ár */}
                                            {proposal.net ? formatCurrency(c.price) : formatCurrency(netPrices.find((n) => n.id === c.id)?.totalAmount)}
                                          </Text></View>
                                          <View style={{ ...styles.priceCell, ...styles.cell10 }}><Text style={styles.cellText}>
                                            {/* bruttó ár */}
                                            {proposal?.net ? formatCurrency(grossPrices?.find((n) => n.id === c.id)?.totalAmount) : formatCurrency(c.price)}
                                          </Text></View>
                                          <View style={{ ...styles.priceCell, ...styles.cell10 }}><Text style={styles.cellText}>
                                            {units?.find((u) => u.uId === acts.find((b) => b.id === c.actId)?.unitId)?.names[lang]}
                                          </Text></View>
                                          {/* PDF-BEN MINDIG írja ki a létszámot és a nettó összeget*/}
                                                <View style={{ ...styles.priceCell, ...styles.cell10 }}><Text style={styles.cellText}>{c.actPax !== 0 ? c.actPax : proposal.pax}</Text></View>
                                                <View style={{ ...styles.priceCell, ...styles.cell10 }}><Text style={styles.cellText}>
                                                {formatCurrency(getNetPrice(c))}
                                                </Text></View>
                                                <View style={{ ...styles.priceCell, ...styles.cell10 }}><Text style={styles.cellText}>
                                                {formatCurrency(getGrossPrice(c))}
                                                </Text></View>         
                                          </View>
                                        </>
                                      );
                                  })}
                              </>
                            )}
                            </View>
              </>))}
            
            
            {/*   **** /ÁRAJÁNLAT *** */}




          {selectedgroups.map((g, idx) => {
            return <>
              <Subtitle>
                {g.names?.find((l) => l.id === lang).hasOwnProperty("name")
                  ? g.names.find((l) => l.id === lang).name
                  : ""}
              </Subtitle>
                {g.descs?.find((l) => l.id === lang)?.hasOwnProperty("desc")
                  ? pdfImageInject(g.descs.find((l) => l.id === lang).desc, g.images)
                : ""}
                 
              <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
              />
              {g.hasOwnProperty("activities") && 
                acts
                  ?.sort((a, b) => {
                    if (a.hasOwnProperty("orderId") & b.hasOwnProperty("orderId")) {
                      return a.orderId - b.orderId;
                    } else {
                      return 0;
                    }
                  })
                .map((a, idx) => {
                    
                                        /* Ha benne van a kiválasztott programok között, csak akkor listázza */
                                      /* 2022.12.28. módosítás: + egy feltétel, hogy ne egy programcsoporthoz tartozzon */
                                        
                                        if (g.activities.find((y) => y === a.id) && selectedActs.find((x) => x.actId === a.id) && !selectedActs.find((x) => x.actId === a.id).hasOwnProperty("parentId"))
                                          return (
                                            <>
                                              {/*  Ha van leírása egy programnak, csak akkor listázza itt ki, VAGY HA nincs leírása, DE variációcsoport */}
                                              {((a.descs?.find((l) => l.id === lang)?.hasOwnProperty("desc") && a.descs?.find((l) => l.id === lang)?.desc !== "") | (a.hasOwnProperty("vargroup") && a.vargroup === true)) ? (
                                                <>
                                                  <View key={idx} /* break={(g.descs?.find((l) => l.id === lang)?.hasOwnProperty("desc") && g.descs?.find((l) => l.id === lang).desc !== "") ? true : false} */>
                                                    <Text style={styles.subtitle} /* break={idx > 1 ? true : false} */>
                                                      
                                                      {a.names?.find((l) => l.id === lang)?.hasOwnProperty("name") ? a.names?.find((l) => l.id === lang)?.name : ""}
                                                     </Text>  
                                                      {(a.duration && a.duration !== 0) && <Text style={styles.subtitle}>{words.idotartam[lang]}: {words.kb[lang]} {a.duration} {(a.duration === 1) ? words.perc[lang] : words.percek[lang]}</Text>}
                                                   
                                                      {a.descs?.find((l) => l.id === lang)?.hasOwnProperty("desc") ? pdfImageInject(a.descs.find((l) => l.id === lang).desc, a.images) : ""}
                                                   
                                                

                                                  {/* *** VARIÁCIÓCSOPORT PROGRAMOK *** */}
                                              
                                                      {a.hasOwnProperty("vargroup") && a.vargroup === true && (
                                                        <>
                                                          {a.hasOwnProperty("activities") &&
                                                            acts &&
                                                            acts
                                                              .sort((a, b) => {
                                                                if (a.hasOwnProperty("orderId") & b.hasOwnProperty("orderId")) {
                                                                  return a.orderId - b.orderId;
                                                                } else {
                                                                  return 0;
                                                                }
                                                              })
                                                          .map((c, j) => {
                                                            if (
                                                              a.activities.find((z) => z === c.id) &&
                                                              c.descs?.find((l) => l.id === lang)?.hasOwnProperty("desc") &&
                                                              c.descs?.find((l) => l.id === lang)?.desc !== ""
                                                            )
                                                              return (
                                                                <>
                                                                <Text style={styles.subtitle}>
                                                                          {c.names?.find((l) => l.id === lang)?.hasOwnProperty("name") ? c.names?.find((l) => l.id === lang)?.name : ""}
                                                                </Text>
                                                                          {c.descs?.find((l) => l.id === lang)?.hasOwnProperty("desc") ? pdfImageInject(c.descs?.find((l) => l.id === lang)?.desc) : ""}
                                                                
                                                                  </>
                                                                  );
                                                              })}
                                                        </>
                                                      )}
                                                    {/* *** /VARIÁCIÓCSOPORT PROGRAMOK *** */}
                                                    </View>
                                                </>
                                              ) : ""}
                                            </>
                                          );
                    

                   })}
            </>
          })}
        </Page>
      </Document>)
    )
    
}

export default ProposalPdf;