import { db } from "./firebase";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, onSnapshot, query, addDoc, doc, deleteDoc } from "firebase/firestore";
import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { langs } from "./Common";

function VarGroups() {
  /*   const [varGroups, setVarGroups] = useState([]); */
  const [name, setName] = useState("");
  const [vargroups, setVargroups] = useState([]);
  const [lang, setLang] = useState(langs[0].id);

  function actsData() {
    const q = query(collection(db, "activities"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      var sortedArray = [...newData.filter((n) => n.vargroup == true)].sort(function (a, b) {
        const nameA = a.names.find((x) => x.id === lang).name.toUpperCase();
        const nameB = b.names.find((x) => x.id === lang).name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }); // names must be equal
      setVargroups([...sortedArray]);
    });
    return unsubscribe;
  }
  useEffect(() => actsData(), []);

  function handleAddVG(event) {
    event.preventDefault();
    const dbRef = collection(db, "activities");
    addDoc(dbRef, {
      names: [
        {
          id: lang,
          name: name
        }
      ],
      vargroup: true
    })
      .then((docRef) => {
        console.log(docRef.id); //p4eZcO5QV43IYnigxALJ
      })
      .catch((error) => {
        console.log(error);
      });
    setName("");
  }

  function handleDeleteVG(event, vgId) {
    event.preventDefault();
    if (window.confirm("Biztosan törlöd a teljes csoportot?")) {
      const docRef = doc(db, "activities", vgId);
      deleteDoc(docRef)
        .then((docRef) => {
          console.log("Document deleted");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <div className="VarGroups">
      <Container className="pt-4">
        <Form>
          <Row>
            <Col className="text-start align-middle">
              <Form.Label htmlFor="inputTitle">Új variációcsoport létrehozása</Form.Label>
            </Col>
          </Row>
          <Row className="pt-2 pb-2">
            <Col className="text-start align-middle">
              <Form.Control id="activity" onChange={(e) => setName(e.target.value)} />
            </Col>
            <Col>
              <Button onClick={handleAddVG} variant="primary" type="submit">
                Hozzáad
              </Button>
            </Col>
          </Row>
        </Form>
        {console.log("vargroups", vargroups)}
        {vargroups &&
          vargroups.map((v, i) => (
            <Row key={i} className="pt-2 pb-2 border-secondary border-bottom">
              <Col className="d-flex justify-content-start align-items-center">
                <Link to="/vargroup" state={{ aId: v.id }} style={{ textDecoration: "none", color: "#000" }}>
                  {v.hasOwnProperty("names") && v.names.find((l) => l.id === lang) && v.names.find((l) => l.id === lang).hasOwnProperty("name") && v.names.find((l) => l.id === lang).name}
                </Link>
              </Col>
              <Col>
                <Button onClick={(event) => handleDeleteVG(event, v.id)} variant="primary" type="submit">
                  Törlés
                </Button>
              </Col>
            </Row>
          ))}
      </Container>
    </div>
  );
}

export default VarGroups;
