import { db } from "./firebase";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  collection,
  onSnapshot,
  query,
  addDoc,
  doc,
  deleteDoc
} from "firebase/firestore";
import React from "react";
import { Container, Row, Col, Form, Button, Accordion } from "react-bootstrap";
import { langs } from "./Common";

function PriceGroups() {
  const [priceGroups, setPriceGroups] = useState([]);
  const [name, setName] = useState("");
  const [lang, setLang] = useState(langs[0].id);

  const q = query(collection(db, "pricegroups"));
  function adatLetolt() {
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      var sortedArray = [...newData.filter((n) => n.vargroup !== true)].sort(
        function (a, b) {
          const nameA = a.names.find((x) => x.id === lang).name.toUpperCase();
          const nameB = b.names.find((x) => x.id === lang).name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }
      ); // names must be equal
      setPriceGroups([...sortedArray]);
    });
    return unsubscribe;
  }

  useEffect(() => adatLetolt(), []);

  function handleAddPG(event) {
    event.preventDefault();
    const dbRef = collection(db, "pricegroups");
    addDoc(dbRef, {
      names: [
        {
          id: lang,
          name: name
        }
      ]
    })
      .then((docRef) => {
        console.log(docRef.id); //p4eZcO5QV43IYnigxALJ
      })
      .catch((error) => {
        console.log(error);
      });
    setName("");
  }

  function handleDeletePG(event, pgId) {
    event.preventDefault();
    if (window.confirm("Biztosan törlöd a teljes ügyfelet?")) {
      const docRef = doc(db, "pricegroups", pgId);
      deleteDoc(docRef)
        .then((docRef) => {
          console.log("Document deleted");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <div className="PriceGroups">
      <Container className="pt-4">
        <Form>
          <Row>
            <Col className="text-start align-middle">
              <Form.Label htmlFor="inputTitle">
                Új árcsoport létrehozása
              </Form.Label>
            </Col>
          </Row>
          <Row className="pt-2 pb-2">
            <Col className="text-start align-middle">
              <Form.Control
                id="activity"
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col>
              <Button onClick={handleAddPG} variant="primary" type="submit">
                Hozzáad
              </Button>
            </Col>
          </Row>
        </Form>
        {priceGroups &&
          priceGroups.map((p, i) => (
            <Row key={i} className="pt-2 pb-2 border-secondary border-bottom">
              <Col className="d-flex justify-content-start align-items-center">
                <Link
                  to="/pricegroup"
                  state={{ gId: p.id }}
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  {p.hasOwnProperty("names") &&
                  p.names.find((l) => l.id === lang) &&
                  p.names.find((l) => l.id === lang).hasOwnProperty("name")
                    ? p.names.find((l) => l.id === lang).name
                    : ""}
                </Link>
              </Col>
              <Col>
                <Button
                  onClick={(event) => handleDeletePG(event, p.id)}
                  variant="primary"
                  type="submit"
                >
                  Törlés
                </Button>
              </Col>
            </Row>
          ))}
      </Container>
    </div>
  );
}

export default PriceGroups;
