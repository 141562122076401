import { db } from "./firebase";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { collection, onSnapshot, query, addDoc, doc, deleteDoc, getDoc, Timestamp } from "firebase/firestore";
import React from "react";
import { Container, Table, Button, Row, Col, Form } from "react-bootstrap";
import { XSquareFill, Folder2, Folder2Open, FileEarmarkPlus, CheckSquare, Receipt } from "react-bootstrap-icons";
import { langs } from "./Common";

function Proposals(props) {
  const userDetails = props.userDetails;
  const users = props.users;
  const [proposals, setProposals] = useState();
  const [propsView, setPropsView] = useState();
  const [clients, setClients] = useState([]);
  const [statuses, setStatuses] = useState();
  const [departments, setDepartments] = useState([]);
  const [lang, setLang] = useState(langs[0].id);
  const [onlyNotBilled, setOnlyNotBilled] = useState(false);
  const [onlyTentative, setOnlyTentative] = useState(false);
  const [onlyLost, setOnlyLost] = useState(false);
  const [onlyBilled, setOnlyBilled] = useState(false);
  const [deptsVisible, setDeptsVisible] = useState([]);
  const [folder, setFolder] = useState(0);


  function copyDefaults() {
    if (userDetails?.defaults?.hasOwnProperty("visibleDeptsInProposalList")) setDeptsVisible(userDetails.defaults.visibleDeptsInProposalList);
  }

  useEffect(() => copyDefaults(), [userDetails]);

  useEffect(() => proposals &&  setPropsView([...proposals]), [proposals]);


  function proposalData() {
    const q = query(collection(db, "proposals"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      setProposals([...newData].sort((a, b) => b.dateAdded - a.dateAdded));
      setFolder(1);
    });
    return unsubscribe;
  }
  useEffect(() => proposalData(), []);

  function departmentsData() {
    const q = query(collection(db, "departments"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      setDepartments([...newData]);
    });
    return unsubscribe;
  }
  useEffect(() => departmentsData(), []);

  function clientData() {
    const q = query(collection(db, "clients"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      setClients(newData);
    });
    return unsubscribe;
  }
  useEffect(() => clientData(), [proposals]);

  function statusData() {
    const docRef = doc(db, "other", "statuses");
    getDoc(docRef).then((doc) => {
      setStatuses(Object.values(doc.data()));
    });
  }
  useEffect(() => statusData(), [clients]);

  function displayClientName(p) {
    const currentClient = clients.find((c) => c.id === p.clientId);
    if (currentClient) {
      return currentClient.name;
    } else {
      return "Törölt cég";
    }
  }

  function displayContactName(p) {
    const currentClient = clients.find((c) => c.id === p.clientId);
    if (currentClient) {
      const currentContact = currentClient.contacts?.find((e) => e.id === p.contactId);
      if (currentContact) {
        return clients.find((c) => c.id === p.clientId).contacts.find((e) => e.id === p.contactId).name;
      } else {
        return "";
      }
    }
  }

  function handleAddProposal(event) {
    event.preventDefault();
    const dbRef = collection(db, "proposals");
    console.log("userDetails", userDetails)
    var defaultDepartment = userDetails.defaults?.hasOwnProperty("defaultDepartment") ? userDetails.defaults?.defaultDepartment : "";
    addDoc(dbRef, {
      dateAdded: Timestamp.fromDate(new Date()),
      createdBy: users?.find((x) => x?.uId === userDetails?.uId).id,
      department: defaultDepartment
    })
      .then((docRef) => {
        console.log(docRef.id); //p4eZcO5QV43IYnigxALJ
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleDeleteProposal(event, proposalId) {
    event.preventDefault();
    if (window.confirm("Biztosan törlöd ezt az ajánlatot?")) {
      const docRef = doc(db, "proposals", proposalId);
      deleteDoc(docRef)
        .then((docRef) => {
          console.log("Document deleted");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function arraysSame(array1, array2) {
    return (array1.length == array2.length) && array1.every(function(element, index) {
    return element === array2[index]; 
});
  }

  function handleChangeDeptsVisible(event) {

    var tempActId = event.target.value;
    var tempArray = [...deptsVisible]
    if (tempArray.indexOf(tempActId) === -1 && event.target.checked) {
      tempArray.push(tempActId);
    } else {
      tempArray = tempArray.filter((e) => e !== tempActId);
    }
    setDeptsVisible([...tempArray]);
  }

   /*  let tempArray = Array.from(event.target.selectedOptions, option => option.value); */
   

  function handleReOrder(event, param) {
   
    if (param === "name") {
      // ha név alapján kell szortírozni, akkor ez fut le. az arraysSame-mel leellenőrzi, hogy már szortírozva van-e
      // (tehát hogy az array megegyezik-e egy szortírozott array-jel, és ha igen, akkor fordítva szortírozza)
      if (arraysSame([...propsView],[...propsView].sort((a, b) => clients?.find((c) => c.id === a.clientId)?.name.localeCompare(clients?.find((c) => c.id === b.clientId)?.name))))
      {  setPropsView([...propsView].sort((a, b) => clients?.find((c) => c.id === b.clientId)?.name.localeCompare(clients?.find((c) => c.id === a.clientId)?.name)));}
      else {
        setPropsView([...propsView].sort((a, b) => clients?.find((c) => c.id === a.clientId)?.name.localeCompare(clients?.find((c) => c.id === b.clientId)?.name)));
      }
    }
    if (param === "dateAdded") {
       if (arraysSame([...propsView],[...propsView].sort((a, b) => b.dateAdded - a.dateAdded)))
      {  setPropsView([...propsView].sort((a, b) => a.dateAdded - b.dateAdded));}
      else {  setPropsView([...propsView].sort((a, b) => b.dateAdded - a.dateAdded));}
    
    }
  }

  function changeFolder() {
    if (proposals && folder) {
      var tempArray = [...proposals];
      if (folder === 1) {
        tempArray = tempArray.filter(x => ((!x.hasOwnProperty("billed") || x.billed === false) & x.statusId != 3));
        setPropsView([...tempArray]);
      }
       if (folder === 2) {
         tempArray = tempArray.filter(x => (x.billed === true || x.statusId === 3));
         if (onlyBilled === true) {
           tempArray = tempArray.filter(x => (x.billed === true));
         }
         if (onlyLost === true) {
           tempArray = tempArray.filter(x => (x.statusId === 3));
         }
        setPropsView([...tempArray]);
      }
    }
  }

  useEffect(() => changeFolder(), [folder, onlyBilled, onlyLost])

  return (
    <div className="Clients">
      <Container>
        <Row className="pt-4 pb-4">
          <Col className="text-start" sm={2}>
            <Button id="addProposal" onClick={handleAddProposal} variant="primary" type="submit">
              <FileEarmarkPlus /> Hozzáad
            </Button>
          </Col>
          <Col className="text-start" sm={2}>
            <Button id="folderOutgoing" onClick={() => setFolder(1)} variant="primary" type="submit">
              {folder === 1 ? <Folder2Open /> : <Folder2 />} Kimenő
            </Button>
          </Col>
          <Col className="text-start" sm={2}>
            <Button id="folderArchive" onClick={() => setFolder(2)} variant="primary" type="submit">
              {folder === 2 ? <Folder2Open /> : <Folder2 />} Archív
            </Button>
          </Col>
          <Col className="text-start">
            <Form>
             {/*  Kimenő üzenetek esetén a szűrőkapcsolók az alábbiak */}
              {(folder === 1) &&
                <>
                  <Form.Check type="checkbox" id="onlyNotBilled" label="Csak lejárt számlázatlanok" onChange={() => setOnlyNotBilled(onlyNotBilled ? false : true)} checked={onlyNotBilled} />
                  <Form.Check type="checkbox" id="onlyTentative" label="Csak lejárt opciósok" onChange={() => setOnlyTentative(onlyTentative ? false : true)} checked={onlyTentative} />
                </>}
            {/*   Archív üzenetek esetén pedig a szürőkapcsolók az alábbiak */}
              {(folder === 2) &&
              <>
                  <Form.Check type="checkbox" id="onlyBilled" label="Csak számlázottak" onChange={() => setOnlyBilled(onlyBilled ? false : true)} checked={onlyBilled} />
                  <Form.Check type="checkbox" id="onlyLost" label="Csak elvesztettek" onChange={() => setOnlyLost(onlyLost ? false : true)} checked={onlyLost} />
                </>}
            </Form>
          </Col>
          {departments && userDetails && <Col className="text-start">
            {departments.map((d, i) => (
                    userDetails.departments?.find(x => x === d.id) && <>
                      <Form.Check
                        key={i}
                        type="checkbox"
                        id="listDepartments"
                        label={d.hasOwnProperty("name") ? d.name : ""}
                        onChange={handleChangeDeptsVisible}
                        checked={deptsVisible && deptsVisible.indexOf(d.id) !== -1}
                        value={d.id}
                      />
                    </>
                  ))}
            {/* <Form>
              <Form.Control as="select" multiple value={deptsVisible} onChange={handleChangeDeptsVisible}>
                {departments.map((d, i) => (
                  <option key={i} value={d.id}>
                    {d.name}
                  </option>
                ))}
              </Form.Control>
            </Form> */}
          </Col>}
        </Row>
      </Container>
      <Container>
        <Table striped bordered hover className="proposalsTable">
          <thead>
            <tr>
              <th onClick={(event) => handleReOrder(event, "dateAdded")}>Létrehozás</th>
              <th onClick={(event) => handleReOrder(event, "name")}>Ügyfél neve</th>
              <th>Ügyintéző</th>
              <th>Cím</th>
              <th>Érkezés</th>
              <th>Távozás</th>
              <th>Státusz</th>
              <th>Opciós</th>
              <th>Határidő</th>
              <th><Receipt /></th>
              <th>HostWare id</th>
              <th>Létrehozta</th>
              <th>Törlés</th>
            </tr>
          </thead>
          <tbody>
            {clients &&
              statuses &&
              userDetails &&
              proposals &&
              propsView &&
              propsView?.map((p, i) => {
                        if ((
                          !onlyNotBilled |
                            (onlyNotBilled &&
                              p.hasOwnProperty("departure") &&
                              !p.hasOwnProperty("billed") | (p.billed === false) &&
                              new Date(p.departure * 1000)?.toLocaleDateString("hu-HU") < new Date(Date.now()).toLocaleDateString("hu-HU")) &&
                          !onlyTentative |
                            (onlyTentative &&
                              p.hasOwnProperty("deadline") &&
                              p.hasOwnProperty("tentative") &&
                              p.tentative === true &&
                              new Date(p.deadline * 1000)?.toLocaleDateString("hu-HU") < new Date(Date.now()).toLocaleDateString("hu-HU"))
                        ) && (/* deptsVisible.length === 0 | */ (p.hasOwnProperty("department") && (deptsVisible.indexOf(p.department) !== -1))))
                          return (
                            <tr key={i}>
                              <td>{new Date(p.dateAdded.seconds * 1000).toLocaleDateString("hu-HU")}</td>
                              <td>
                                <Link to="/proposal" state={{ pId: p.id }} style={{ textDecoration: "none", color: "#000" }}>
                                  {p.hasOwnProperty("clientId") ? displayClientName(p) : "Új ajánlat"}
                                </Link>
                              </td>
                              <td>{displayContactName(p)}</td>
                              <td>{p.title ? p.title : ""}</td>
                              <td>{p.hasOwnProperty("arrival") && p.hasOwnProperty("hasDate") && p.hasDate === true && new Date(p.arrival * 1000).toLocaleDateString("hu-HU")}</td>
                              <td>{p.hasOwnProperty("departure") && p.hasOwnProperty("hasDate") && p.hasDate === true && new Date(p.departure * 1000).toLocaleDateString("hu-HU")}</td>
                              <td>
                                {p.hasOwnProperty("statusId") &&
                                statuses.find((s) => s.sId === p.statusId) &&
                                statuses.find((s) => s.sId === p.statusId).hasOwnProperty("names") &&
                                statuses.find((s) => s.sId === p.statusId).names.find((l) => l.id === lang) &&
                                statuses
                                  .find((s) => s.sId === p.statusId)
                                  .names.find((l) => l.id === lang)
                                  .hasOwnProperty("name")
                                  ? statuses.find((s) => s.sId === p.statusId).names.find((l) => l.id === lang).name
                                  : ""}
                              </td>
                              <td>{p.hasOwnProperty("tentative") ? (p.tentative ? "Opciós" : "Fix") : ""}</td>
                              <td>{p.hasOwnProperty("deadline") && new Date(p.deadline * 1000).toLocaleDateString("hu-HU")}</td>
                              <td>{p.hasOwnProperty("billed") ? (p.billed ? <CheckSquare /> : "") : ""}</td>
                              <td>{p.hasOwnProperty("hostwareId") && p.hostwareId}</td>
                              <td>
                                {p.hasOwnProperty("createdBy") ? users?.find((x) => x.id === p.createdBy)?.name : ""}
                              </td>
                              <td>
                                <XSquareFill color="#0d6efd" onClick={(event) => handleDeleteProposal(event, p.id)} />
                              </td>
                            </tr>
                          );
              })}
          </tbody>
        </Table>
      </Container>
    </div>
  );
}

export default Proposals;
