import { db } from "./firebase";
import { useState, useEffect } from "react";
import {
  collection,
  onSnapshot,
  query,
  addDoc,
  doc,
  updateDoc,
  deleteDoc
} from "firebase/firestore";
import React from "react";
import { Container, Row, Col, Form, Button, Accordion } from "react-bootstrap";

function Clients() {
  const [clients, setClients] = useState([]);
  const [name, setName] = useState();
  const [clientEditable, setClientEditable] = useState({});
  const [contactName, setContactName] = useState();
  const [contactEmail, setContactEmail] = useState();
  const [contactEditable, setContactEditable] = useState({});
  const tempClients = [...clients];

  const q = query(collection(db, "clients"));
  function adatLetolt() {
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      setClients([...newData].sort((a, b) => a.name.localeCompare(b.name)));
    });
    return unsubscribe;
  }

  useEffect(() => adatLetolt(), []);

  function handleAddClient(event) {
    event.preventDefault();
    const dbRef = collection(db, "clients");
    addDoc(dbRef, {
      name: name
    })
      .then((docRef) => {
        console.log(docRef.id); //p4eZcO5QV43IYnigxALJ
      })
      .catch((error) => {
        console.log(error);
      });
    setName("");
  }

  function handleChangeClient(event, clientId) {
    if (clientId && tempClients) {
      const tempClient = tempClients.find((x) => x.id === clientId);
      tempClient.name = event.target.value;
      setClients(tempClients);
    }
  }

  function handleEditClient(event, clientId) {
    event.preventDefault();
    var myObj = {
      [clientId]: true
    };
    setClientEditable(myObj);
  }

  function handleUpdateClient(event, clientId) {
    event.preventDefault();
    const currentClient = clients.find((c) => c.id === clientId);
    const docRef = doc(db, "clients", clientId);
    updateDoc(docRef, { name: currentClient.name })
      .then((docRef) => {
        console.log("Document field updated");
      })
      .catch((error) => {
        console.log(error);
      });

    var myObj = {
      [clientId]: false
    };
    setClientEditable(myObj);
  }

  function handleDeleteClient(event, clientId) {
    event.preventDefault();
    if (window.confirm("Biztosan törlöd a teljes ügyfelet?")) {
      const docRef = doc(db, "clients", clientId);
      deleteDoc(docRef)
        .then((docRef) => {
          console.log("Document deleted");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function handleEditContact(event, clientId, contactId) {
    event.preventDefault();
    var myObj = {
      [clientId]: {}
    };
    myObj[clientId] = { ...myObj[clientId], [contactId]: true };
    setContactEditable(myObj);
  }

  function handleUpdateContact(event, clientId, contactId) {
    event.preventDefault();
    const currentClient = clients.find((c) => c.id === clientId);
    /*  const currentContact = currentClient.contacts.find(
      (e) => e.id === contactId
    );
    console.log("currentContact ", currentContact); */
    const docRef = doc(db, "clients", clientId);
    updateDoc(docRef, { contacts: currentClient.contacts })
      .then((docRef) => {
        console.log("Document field updated");
      })
      .catch((error) => {
        console.log(error);
      });

    var myObj = {
      [clientId]: {}
    };
    myObj[clientId] = { ...myObj[clientId], [contactId]: false };
    setContactEditable(myObj);
  }

  function handleDeleteContact(event, clientId, contactId) {
    event.preventDefault();
    const currentClient = clients.find((c) => c.id === clientId);
    if (window.confirm("Biztosan törlöd?")) {
      currentClient.contacts = currentClient.contacts.filter(
        (x) => x.id !== contactId
      );
      const docRef = doc(db, "clients", clientId);
      updateDoc(docRef, { contacts: currentClient.contacts })
        .then((docRef) => {
          console.log("Document field updated");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function handleChangeContact(event, clientId, contactId) {
    /* const tempClients = [...clients]; */
    if (clientId && contactId && tempClients) {
      const tempClient = tempClients.find((x) => x.id === clientId);
      const tempContact = tempClient.contacts.find((y) => y.id === contactId);
      if (event.target.id === "inputContactEmail") {
        tempContact.email = event.target.value;
      }
      if (event.target.id === "inputContactName") {
        tempContact.name = event.target.value;
      }
      setClients(tempClients);
    }
  }

  function handleAddContact(event) {
    event.preventDefault();
    const clientId = event.target.id;
    const currentClient = clients.find((c) => c.id === clientId);
    const contactIds = [];
    var newContactId = 1;
    if (currentClient.contacts && currentClient.contacts.length > 0) {
      currentClient.contacts.forEach((c) => contactIds.push(c.id));
      newContactId = Math.max(...contactIds) + 1;
    } else {
      currentClient.contacts = [];
    }

    currentClient.contacts.push({
      id: newContactId,
      name: contactName,
      email: contactEmail
    });
    const docRef = doc(db, "clients", clientId);

    updateDoc(docRef, { contacts: currentClient.contacts })
      .then((docRef) => {
        console.log(
          "A New Document Field has been added to an existing document"
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="Clients">
      <Container>
        <Form>
          <Row className="pb-4 pt-4">
            <Col>
              <Form.Label htmlFor="inputTitle">Név</Form.Label>
              <Form.Control
                id="inputTitle"
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col className="d-flex justify-content-center align-items-end">
              <Button onClick={handleAddClient} variant="primary" type="submit">
                Hozzáad
              </Button>
            </Col>
          </Row>
        </Form>
        <Accordion>
          {clients?.map((c, i) => (
            <Accordion.Item eventKey={i}>
              <Accordion.Header>{c.name}</Accordion.Header>
              <Accordion.Body>
                <Container>
                  <Form>
                    <Row className="pb-4 border-bottom">
                      <Col>
                        <Form.Label htmlFor="inputClientName">
                          Cég neve
                        </Form.Label>
                        <Form.Control
                          id="inputClientName"
                          value={c.name}
                          onChange={(event) => handleChangeClient(event, c.id)}
                          {...(clientEditable && clientEditable[c.id] === true
                            ? { disabled: false }
                            : { disabled: true })}
                        />
                      </Col>
                      <Col></Col>
                      <Col className="d-flex justify-content-center align-items-end">
                        {clientEditable && clientEditable[c.id] === true ? (
                          <Button
                            onClick={(event) => handleUpdateClient(event, c.id)}
                            variant="primary"
                            type="submit"
                          >
                            Mentés
                          </Button>
                        ) : (
                          <>
                            <Button
                              className="me-2"
                              onClick={(event) => handleEditClient(event, c.id)}
                              variant="primary"
                              type="submit"
                            >
                              Szerkesztés
                            </Button>
                            <Button
                              onClick={(event) =>
                                handleDeleteClient(event, c.id)
                              }
                              variant="primary"
                              type="submit"
                            >
                              Törlés
                            </Button>
                          </>
                        )}
                      </Col>
                    </Row>
                    <Row className="pt-4 pb-4">
                      <Col>
                        <Form.Label htmlFor="inputContactName">
                          Ügyintéző neve
                        </Form.Label>
                        <Form.Control
                          id="inputContactName"
                          onChange={(e) => setContactName(e.target.value)}
                        />
                      </Col>
                      <Col>
                        <Form.Label htmlFor="inputContactEmail">
                          Ügyintéző e-mail címe
                        </Form.Label>
                        <Form.Control
                          id="inputContactEmail"
                          onChange={(e) => setContactEmail(e.target.value)}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-end">
                        <Button
                          id={c.id}
                          onClick={handleAddContact}
                          variant="primary"
                          type="submit"
                        >
                          Hozzáad
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  {c.contacts?.map((e, j) => (
                    <Form>
                      <Row key={j} className="pb-4">
                        <Col>
                          <Form.Control
                            value={e.name}
                            id="inputContactName"
                            onChange={(event) =>
                              handleChangeContact(event, c.id, e.id)
                            }
                            {...(contactEditable &&
                            contactEditable[c.id] &&
                            contactEditable[c.id][e.id] === true
                              ? { disabled: false }
                              : { disabled: true })}
                          />
                        </Col>
                        <Col>
                          <Form.Control
                            type="email"
                            value={e.email}
                            id="inputContactEmail"
                            onChange={(event) =>
                              handleChangeContact(event, c.id, e.id)
                            }
                            {...(contactEditable &&
                            contactEditable[c.id] &&
                            contactEditable[c.id][e.id] === true
                              ? { disabled: false }
                              : { disabled: true })}
                          />
                        </Col>
                        <Col className="d-flex justify-content-center align-items-end">
                          {contactEditable &&
                          contactEditable[c.id] &&
                          contactEditable[c.id][e.id] === true ? (
                            <Button
                              onClick={(event) =>
                                handleUpdateContact(event, c.id, e.id)
                              }
                              variant="primary"
                              type="submit"
                            >
                              Mentés
                            </Button>
                          ) : (
                            <>
                              <Button
                                className="me-2"
                                onClick={(event) =>
                                  handleEditContact(event, c.id, e.id)
                                }
                                variant="primary"
                                type="submit"
                              >
                                Szerkesztés
                              </Button>
                              <Button
                                onClick={(event) =>
                                  handleDeleteContact(event, c.id, e.id)
                                }
                                variant="primary"
                                type="submit"
                              >
                                Törlés
                              </Button>
                            </>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  ))}
                </Container>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Container>
    </div>
  );
}

export default Clients;
