import React from "react";
import { useLocation } from "react-router-dom";
import { db } from "./firebase";
import { useState, useEffect } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { XSquareFill } from "react-bootstrap-icons";
import { vatTypes, langs } from "./Common";
import ImageSelect from "./ImageSelect";
/* import { PureComponent } from "react";
import { Editor } from "react-bootstrap-editor"; */

import {
  collection,
  query,
  addDoc,
  doc,
  updateDoc,
  onSnapshot,
  getDoc
} from "firebase/firestore";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Accordion,
  Modal,
  Table
} from "react-bootstrap";

function EditActivity(props) {

  const [act, setAct] = useState();
  const [units, setUnits] = useState([]);
  const [lang, setLang] = useState(langs[0].id);
  const [images, setImages] = useState([]);
  const [showAddImage, setShowAddImage] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  function actData() {
    const docRef = doc(db, "activities", aId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      setAct(doc.data());
      doc.data() &&
        doc.data().hasOwnProperty("images") &&
        setSelectedImages(doc.data().images);
    });
    return unsubscribe;
  }
/*   useEffect(() => actData(), []); */



  var isCustom = false;
  var aId = "";
  if (props.aId) {
    aId = props.aId
    if (aId === "newItem") {
      isCustom = true;
      //mentéskor majd ne felejts el generateOrderId-vel csinálni egy orderId-t, és úgy hozzáadni!
    /*   setAct({
      names: [
        {
          id: lang,
          name: ""
        }
      ],
      vat: [],
      basedOnCount: true
    }) */
    }

  } else {
    let location = useLocation();
     // dev mode-ban berak egy alap activity-t, hogy működjön
    aId = location.state ? location.state.aId : "uNbJvtswyvZRnkzXEt2B";
    useEffect(() => actData(), []);
  }
  

  

  function unitData() {
    const docRef = doc(db, "other", "units");
    getDoc(docRef).then((doc) => {
      setUnits(Object.values(doc.data()));
    });
  }
  useEffect(() => unitData(), []);

   function imagesData() {
    const q = query(collection(db, "images"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      setImages(newData);
    });
    return unsubscribe;
  }

  useEffect(() => imagesData(), []);

  function handleUpdateAct(event) {
    event.preventDefault();
    const docRef = doc(db, "activities", aId);
    updateDoc(docRef, {
      ...act
    })
      .then((docRef) => {
        console.log("Document field updated");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleChangeField(event, eId) {
    var newValue = event.target.value;
    var targetId = event.target.id;

    if (targetId === "anyranges" | targetId === "basedOnCount") {
      newValue = event.target.checked;
      setAct({ ...act, [targetId]: newValue });
    }

    if ((targetId === "min") | (targetId === "max")) {
      newValue = +newValue;
      var tempPaxArray = [...act.priceranges];
      var tempPaxIndex = tempPaxArray.findIndex((t) => t.id === eId);
      if (tempPaxIndex !== -1) {
        tempPaxArray[tempPaxIndex][targetId] = newValue;
      }
      setAct({ ...act, priceranges: tempPaxArray });
    }

    if (targetId === "vat") {
      newValue = +newValue;
      var tempArray = [...act.vat];
      var tempIndex = tempArray.findIndex((t) => {
        return t.id === eId;
      });
      if (tempIndex !== -1) {
        tempArray[tempIndex].share = newValue;
      } else {
        tempArray.push({ id: eId, share: newValue });
      }
      setAct({ ...act, vat: tempArray });
    }

    if ((targetId === "unitId") | (targetId === "duration")) {
      newValue = +newValue;
      setAct({ ...act, [targetId]: newValue });
    }

    if (targetId === "name") {
      var tempNameArray = [...act.names];
      var tempNameIndex = tempNameArray.findIndex((t) => {
        return t.id === eId;
      });
      if (tempNameIndex !== -1) {
        tempNameArray[tempNameIndex].name = newValue;
        tempNameArray[tempNameIndex].id = eId;
      } else {
        tempNameArray.push({ id: eId, name: newValue });
      }
      setAct({ ...act, names: tempNameArray });
    }

    if (targetId === "desc") {
      var tempDescArray = act.hasOwnProperty("descs") ? [...act.descs] : [];
      var tempDescIndex = tempDescArray.findIndex((t) => {
        return t.id === eId;
      });
      if (tempDescIndex !== -1) {
        tempDescArray[tempDescIndex].desc = newValue;
        tempDescArray[tempDescIndex].id = eId;
      } else {
        tempDescArray.push({ id: eId, desc: newValue });
      }
      setAct({ ...act, descs: tempDescArray });
    }
  }

  function handleDeletePricerange(event, id) {
    event.preventDefault();
    if (window.confirm("Biztosan törlöd?")) {
      var tempArray = act.priceranges;
      tempArray = tempArray.filter((x) => x.id !== id);
      setAct({ ...act, priceranges: tempArray });
    }
  }

  function handleAddPricerange(event) {
    event.preventDefault();
    const pricerangesIds = [];
    var newPricerangeId = 1;
    var tempArray = [];
    tempArray = act.priceranges ? (tempArray = [...act.priceranges]) : [];
    tempArray.map((l) => {
      if (tempArray && tempArray.length > 0) {
        tempArray.forEach((c) => pricerangesIds.push(c.id));
        newPricerangeId = Math.max(...pricerangesIds) + 1;
      }
    });
    tempArray.push({
      id: newPricerangeId,
      min: 0,
      max: 0
    });
    setAct({ ...act, priceranges: tempArray });
  }

  function handleShowAddImage(event) {
    event.preventDefault();
    setShowAddImage(true);
  }

  return (
    <div className="EditActivity">
      {act && units && units.length > 0 && (
        <>
          <Container>
            <Form>
              <Form.Group as={Row} className="pb-4 pt-4">
                <Form.Label column sm={2} className="text-start">
                  Program neve
                </Form.Label>
                <Col sm={4}>
                  {langs.map((l) => (
                    <>
                      {l.name}
                      <Form.Control
                        key={l.id}
                        id="name"
                        value={
                          act.hasOwnProperty("names") &&
                          act.names.find((b) => b.id === l.id) &&
                          act.names
                            .find((b) => b.id === l.id)
                            .hasOwnProperty("name") &&
                          act.names.find((b) => b.id === l.id).name
                        }
                        onChange={(event) => handleChangeField(event, l.id)}
                      />
                    </>
                  ))}
                </Col>
                <Col sm={4}>
                  <Button
                    id="saveButton"
                    onClick={handleUpdateAct}
                    variant="primary"
                    type="submit"
                  >
                    Mentés
                  </Button>
                </Col>
                <Col sm={2}>
                    <Button
                      id="addSelectedImage"
                      onClick={handleShowAddImage}
                      variant="primary"
                      type="submit"
                    >
                      Képek
                    </Button>
                    <ImageSelect
                      images={images}
                      setImages={setImages}
                      target={act}
                      setTarget={setAct}
                      selectedImages={selectedImages}
                      setSelectedImages={setSelectedImages}
                      showAddImage={showAddImage}
                      setShowAddImage={setShowAddImage}
                    />
                  </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-4" controlId="name">
                <Form.Label column sm={2} className="text-start">
                  Számlázási egység
                </Form.Label>
                <Col
                  sm={4}
                  className="d-flex justify-content-start align-items-center"
                >
                  <Form.Select
                    id="unitId"
                    aria-label="Unit"
                    onChange={handleChangeField}
                    value={act.hasOwnProperty("unitId") ? act.unitId : "label"}
                  >
                    <option value="label" disabled>
                      Válassz...
                    </option>
                    {units.map((u, j) => (
                      <option key={j} value={u.uId}>
                        {u.names[lang]}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-4">
                <Form.Label column sm={2} className="text-start">
                  Időtartam (perc)
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="number"
                    id="duration"
                    value={act.hasOwnProperty("duration") && act.duration}
                    onChange={handleChangeField}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-4">
                <Form.Label column sm={2} className="text-start">
                  Létszámtól függ
                </Form.Label>
                <Col sm={4}>
                  <Form.Check type="checkbox" id="basedOnCount" onChange={handleChangeField} checked={act?.hasOwnProperty("basedOnCount") ? act?.basedOnCount : false} />
                </Col>
              </Form.Group>
                <Form.Group as={Row} className="mb-4">
                <Form.Label column sm={2} className="text-start">
                  Áfa arányok (%-ban megadva)
                </Form.Label>
                <Col sm={4}>
                  {act.hasOwnProperty("vat") &&
                    vatTypes.map((v, k) => (
                      <>
                        {v.amount + "%"}
                        <Form.Control
                          key={k}
                          type="number"
                          id="vat"
                          value={
                            act.vat.find((w) => w.id === v.id) &&
                            act.vat
                              .find((w) => w.id === v.id)
                              .hasOwnProperty("share") &&
                            act.vat.find((w) => w.id === v.id).share
                          }
                          onChange={(event) => handleChangeField(event, v.id)}
                        ></Form.Control>
                      </>
                    ))}
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-4" controlId="desc">
                <Form.Label column sm={2}>
                  Leírás
                </Form.Label>
                <Col sm={4}>
                  {langs.map((l) => (
                    <>
                      {l.name}
                      <Form.Control
                        key={l.id}
                        as="textarea"
                        rows={3}
                        value={
                          act.hasOwnProperty("descs") &&
                          act.descs.find((b) => b.id === l.id) &&
                          act.descs
                            .find((b) => b.id === l.id)
                            .hasOwnProperty("desc")
                            ? act.descs.find((b) => b.id === l.id).desc
                            : ""
                        }
                        onChange={(event) => handleChangeField(event, l.id)}
                      />
                    </>
                  ))}
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-4">
                <Form.Label column sm={2}>
                  Ársávok
                </Form.Label>
                <Col
                  sm={6}
                  className="d-flex justify-content-start align-items-center"
                >
                  <Form.Check
                    onChange={handleChangeField}
                    type="checkbox"
                    id="anyranges"
                    checked={act.hasOwnProperty("anyranges") && act.anyranges}
                  />
                </Col>
              </Form.Group>
            </Form>
          </Container>
          {act.hasOwnProperty("anyranges") && act.anyranges && (
            <Container>
              <Row>
                <Col className="text-start">Ársávok</Col>
              </Row>
              <Row>
                <Col sm={10}>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Minimum</th>
                        <th>Maximum</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {act &&
                        act.hasOwnProperty("priceranges") &&
                        act.priceranges.map((r, i) => (
                          <tr key={i}>
                            <td>{i + 1}.</td>
                            <td>
                              <Form.Control
                                type="number"
                                id="min"
                                value={r.min}
                                onChange={(event) =>
                                  handleChangeField(event, r.id)
                                }
                              ></Form.Control>
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                id="max"
                                value={r.max}
                                onChange={(event) =>
                                  handleChangeField(event, r.id)
                                }
                              ></Form.Control>
                            </td>
                            <td>
                              <XSquareFill
                                color="#0d6efd"
                                onClick={(event) =>
                                  handleDeletePricerange(event, r.id)
                                }
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Col>
                <Col>
                  <Button
                    id="addPriceRange"
                    onClick={handleAddPricerange}
                    variant="primary"
                    type="submit"
                  >
                    Hozzáad
                  </Button>
                </Col>
              </Row>
            </Container>
          )}
        </>
      )}
    </div>
  );
}


export default EditActivity;
