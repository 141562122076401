import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  /*  <StrictMode> */
  /* Így kell csinálni, ha subfolderbe kerül, és package.json-ba bele kell rakni, hogy homepage:"/hvs/"
  /*   <BrowserRouter basename="/hvs"> */
   <BrowserRouter>
      <App />
  </BrowserRouter>
 /*  </StrictMode> */
);
